import { hotelService } from "../../api/hotelService";
import { hotelConstants } from "../Constants";
import { commonActions } from "./commonActions";
import {openInNewTab} from "../../Util/util"

export const hotelActions = {
  searchHotelAction,
  searchHotelMap,
  validateZip,
  getHotelDetails,
  resetHotelDetails,
  getHotelRooms,
  resetHotelRooms,
  getBookingDetails,
  downloadPdf,
  emailBooking,
  cancelledBooking,
  checkHotelAvability,
  recheckHotelPriceAction,
  getStripeIntentRequest,
  bookHotelAction,
  getUpcomingBookings,
  getCompletedBookings,
  getCancelledBookings,
  getFailedBookings,
  resetEmailBooking,
  resetCancelledBooking,
  hotelPrebook,
  getAvailableBalance,
};

function checkHotelAvability(payload, id) {
  return async (dispatch) => {
    try {
      const res = await hotelService.checkHotelAvailability(payload, id);
      dispatch(success(hotelConstants.HOTEL_GET_AVABILITY_SUCCESS, res));
      return res
    } catch (error) {
      dispatch(failure(hotelConstants.HOTEL_GET_AVABILITY_FAILURE, error));
      return error.response
    }
  };
}

function recheckHotelPriceAction(payload, id , reward_credit_applied) {
  return (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_RECHECK_PRICE_REQUEST));
    dispatch(commonActions.showLoader());
    return hotelService
      .recheckHotelPrice(payload, id)
      .then((res) => {
        dispatch(success(hotelConstants.HOTEL_RECHECK_PRICE_SUCCESS, res));
        dispatch(getStripeIntentRequest(reward_credit_applied));
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.HOTEL_RECHECK_PRICE_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getStripeIntentRequest(reward_credit_applied) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_STRIPE_INTENT_REQUEST));
    dispatch(commonActions.showLoader());
    return hotelService
      .getStripeIntent(reward_credit_applied)
      .then((res) => {
        dispatch(success(hotelConstants.GET_STRIPE_INTENT_SUCCESS, res));
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_STRIPE_INTENT_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function bookHotelAction(payload) {
  return (dispatch) => {
    dispatch(request(hotelConstants.BOOK_HOTEL_REQUEST));
    dispatch(commonActions.showLoader());
    hotelService.bookHotelService(payload).then(
      (response) => {
        dispatch(success(hotelConstants.BOOK_HOTEL_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(failure(hotelConstants.BOOK_HOTEL_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function searchHotelAction(payload, navigateTo) {
  return (dispatch) => {
    dispatch(request(hotelConstants.SEARCH_HOTEL_REQUEST));
    dispatch(commonActions.loaderText("loading"));
    hotelService.searchHotelService(payload).then(
      (response) => {
        dispatch(commonActions.loaderText());
        if (response?.data?.search_key && !window.localStorage.getItem("hotel_search_key")){
          window.localStorage.setItem("hotel_search_key", response?.data?.search_key);
        }
        if(localStorage.getItem("preserveNeighbourhood")){
          // response.data.filters_available.filter(ele => ele.name === "neighbourhoods")[0].options = JSON.parse(localStorage.getItem("preserveNeighbourhood"));
          response.data.filters_available.filter(ele => ele.name === "neighbourhoods")[0].options;
        }
        dispatch(success(hotelConstants.SEARCH_HOTEL_SUCCESS, response));
        if (navigateTo) navigateTo("/hotel-search-result");
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.loaderText());
        dispatch(failure(hotelConstants.SEARCH_HOTEL_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function searchHotelMap(payload) {
  return (dispatch) => {
    dispatch(request(hotelConstants.SEARCH_HOTEL_CORD_REQUEST));
    dispatch(commonActions.loaderText("loading"));
    hotelService.searchHotelCordService(payload).then(
      (response) => {
        dispatch(commonActions.loaderText());
        dispatch(success(hotelConstants.SEARCH_HOTEL_CORD_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.loaderText());
        dispatch(failure(hotelConstants.SEARCH_HOTEL_CORD_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function getBookingDetails(id) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_USER_BOOKING_DETAILS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getBookingDetails(id)
      .then((res) => {
        dispatch(success(hotelConstants.GET_USER_BOOKING_DETAILS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(
          failure(hotelConstants.GET_USER_BOOKING_DETAILS_FAILURE, error)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function downloadPdf(data) {
  return (dispatch) => {
    dispatch(request(hotelConstants.DOWNLOAD_INVOICE));
    dispatch(commonActions.showLoader());
    return hotelService
      .downloadPdf(data)
      .then((res) => {
        dispatch(success(hotelConstants.DOWNLOAD_INVOICE_SUCCESS, res));
        openInNewTab(res.data.url)
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.DOWNLOAD_INVOICE_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function emailBooking(payload) {
  return (dispatch) => {
    dispatch(request(hotelConstants.EMAIL_BOOKING));
    dispatch(commonActions.showLoader());
    return hotelService.emailBooking(payload)
      .then((res) => {
        dispatch(success(hotelConstants.EMAIL_BOOKING_SUCCESS, res));
        dispatch(commonActions.hideLoader());
        return res
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.EMAIL_BOOKING_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error
      });
  };
}

function resetEmailBooking() {
  return (dispatch) => {
    dispatch(request(hotelConstants.EMAIL_BOOKING));
  };
}

function cancelledBooking(id) {
  return (dispatch) => {
    dispatch(request(hotelConstants.CANCEL_BOOKING));
    dispatch(commonActions.showLoader());
    return hotelService
      .cancelledBooking(id)
      .then((res) => {
        dispatch(success(hotelConstants.CANCEL_BOOKING_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.CANCEL_BOOKING_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function resetCancelledBooking() {
  return (dispatch) => {
    dispatch(request(hotelConstants.CANCEL_BOOKING));
  };
}

function validateZip(zip) {
  return (dispatch) => {
    dispatch(request(hotelConstants.VALIDATE_ZIP));
    dispatch(commonActions.loaderText("fetching..."));
    return hotelService
      .validateZip(zip)
      .then((res) => {
        dispatch(success(hotelConstants.VALIDATE_ZIP_SUCCESS, res));
        dispatch(commonActions.hideLoader());
        return res;
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.VALIDATE_ZIP_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getHotelDetails(payload, id) {
  return (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_DETAILS_REQUEST));
    dispatch(commonActions.loaderText("loading"));
    hotelService.getHotelDetails(payload, id).then(
      (response) => {
        dispatch(commonActions.loaderText());
        dispatch(success(hotelConstants.HOTEL_DETAILS_SUCCESS, response));
      },
      (error) => {
        dispatch(commonActions.loaderText());
        dispatch(failure(hotelConstants.HOTEL_DETAILS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function resetHotelDetails() {
  return (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_DETAILS_REQUEST));
  };
}

function getHotelRooms(payload, id) {
  return (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_ROOMS_REQUEST));
    dispatch(commonActions.loaderText("loading"));
    hotelService.getHotelRooms(payload, id).then(
      (response) => {
        dispatch(commonActions.loaderText());
        dispatch(success(hotelConstants.HOTEL_ROOMS_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.loaderText());
        dispatch(failure(hotelConstants.HOTEL_ROOMS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function resetHotelRooms() {
  return (dispatch) => {
    dispatch(request(hotelConstants.HOTEL_ROOMS_REQUEST));
  };
}

function getUpcomingBookings(status) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_USER_BOOKINGS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getUpcomingBookings(status)
      .then((res) => {
        dispatch(success(hotelConstants.GET_USER_BOOKINGS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_USER_BOOKINGS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getCompletedBookings(status) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_COMPLETED_BOOKINGS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getCompletedBookings(status)
      .then((res) => {
        dispatch(success(hotelConstants.GET_COMPLETED_BOOKINGS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_COMPLETED_BOOKINGS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getCancelledBookings(status) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_CANCELLED_BOOKINGS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getCancelledBookings(status)
      .then((res) => {
        dispatch(success(hotelConstants.GET_CANCELLED_BOOKINGS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_CANCELLED_BOOKINGS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getFailedBookings(status) {
  return (dispatch) => {
    dispatch(request(hotelConstants.GET_FAILED_BOOKINGS));
    dispatch(commonActions.showLoader());
    return hotelService
      .getFailedBookings(status)
      .then((res) => {
        dispatch(success(hotelConstants.GET_FAILED_BOOKINGS_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(hotelConstants.GET_FAILED_BOOKINGS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

// hotel pre booking action 

function hotelPrebook(payload) {
  return (dispatch) => {
    dispatch(request(hotelConstants.PREBOOK_HOTEL_BOOKING_REQUEST));
    dispatch(commonActions.showLoader());
    return hotelService.hotelPrebook(payload)
      .then(res => {
        dispatch(success(hotelConstants.PREBOOK_HOTEL_BOOKING_SUCCESS, res));
        dispatch(commonActions.hideLoader());
        return res;
      })
      .catch(error => {
        dispatch(failure(hotelConstants.PREBOOK_HOTEL_BOOKING_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      }) 
  }
}

// Get available balance 
function getAvailableBalance(){
  return dispatch => {
      hotelService.getAvailableBalance().then(response =>{
        dispatch(success(hotelConstants.GET_AVAILABLE_BALANCE, response));
      },
      error => {
          
      })
  }
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}

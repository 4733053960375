import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import AcademyTab from "../Tabs/AcademyTab";
import PdfIcon from "../../Assets/img/icons/pdf-icon.png";
import checklistPDF1 from "../../Assets/pdf/5-Ways-To-Promote-The-Tripello-Network-Using-Social-Media.pdf";

function TipsAndTricks() {
  return (
    <div className="siteBg academy-wrapper FaqPageWrap Terms-wrapper">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true}/>
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <AcademyTab name={"tips"} />
              <div className="col rightContent">
              <div className="faqWrapper card2">
                  <div className="pdf-content-wrapper">
                    <div className="row no-gutters">
                      <div className="col content-col">
                        <h3>5 Ways To Promote The Tripello Network Using Social Media (PDF) </h3>
                        <p>Short Guide with Tips and Tricks for using Social Media to Support your marketing campaign.</p>
                      </div>
                      <div className="col image-col">
                        <div className="image-wrap">
                        <a  href={checklistPDF1} target="_blank">
                           <span >DOWNLOAD</span>
                          <img src={PdfIcon} alt="icon" />
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default TipsAndTricks;

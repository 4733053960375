import { Fragment, React, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"; 
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import moment from "moment";
import BaggageTooltip from "./BaggageTooltip";
import Scrollbars from "react-custom-scrollbars";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import EmailModal from "./EmailModal";
import CancellationModal from "./CancellationModal";
import { flightActions } from "../../Stores/Actions";


const FlightConfirmation = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.commonReducer.loader);

  const flight = useSelector(
    (state) => state.flightReducer.userBookingDetails
  );

  const currencyAndLanguage =
    (window.localStorage.getItem("currencyAndLanguage") &&
      JSON.parse(window.localStorage.getItem("currencyAndLanguage"))) ||
    {};

  const currency_symbol =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency?.symbol || "$";

  const {
    bookingStatusReadable,
    confirmed_at,
    bookingStatus
  } = {
    ...flight,
  };

  useEffect(() => {
    let id = params.bookingID;
    dispatch(flightActions.getFlightBookingDetails(id));
  }, []);


  const timeConvert = (num) => {
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + "h " + rminutes + "m";
  };


  const downloadInvoice = () => {
    let id = params.bookingID;
    dispatch(flightActions.downloadPdf(id));
  };

  // Email Receipt
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  //Cancel Itinerary
  const [isCancel, setIsCancel] = useState(false);

  function toggleCancelModal() {
    setIsCancel(!isCancel);
  }

  //**************Error handle for cancel booking*******************//
  const cancelBookingError = useSelector(
    (state) => state.hotelReducer.errorCancelBooking
  );

  let openNotifyCancelBookingError = (type) => {
    notification[type]({
      // message: "Notification Title",
      description: cancelBookingError?.message,
    });
    setIsCancel((prev) => !prev);
  };

  // ***********Succes Msg handle for cancel booking**********//
  const cancelBookingData = useSelector(
    (state) => state.hotelReducer.cancelledBooking
  );

  let openNotifyCancelBookingSuccess = (type) => {
    notification[type]({
      // message: "Notification Title",
      // description: cancelBookingError?.message,
      description: "Cancelled Successfully",
    });
    setIsCancel((prev) => !prev);
  };
  useEffect(() => {
    let id = params.bookingID;
    if (cancelBookingError !== null) {
      dispatch(flightActions.getFlightBookingDetails(id));
      openNotifyCancelBookingError("error");
      dispatch(flightActions.resetCancelledBooking());
    }
    if (cancelBookingData !== null) {
      dispatch(flightActions.getFlightBookingDetails(id));
      openNotifyCancelBookingSuccess("success");
    }
  }, [cancelBookingError, cancelBookingData]);

  // ***********Error handle for Email****************//
  let errorMsg = useSelector((state) => state.hotelReducer.error);

  let openNotifyEmailError = (type) => {
    notification[type]({
      // message: "Notification Title",
      description: errorMsg?.message,
    });
    setIsOpen((prev) => !prev);
  };

  // ***************Succsess msg handle for Email**************//
  const emailReservationData = useSelector(
    (state) => state.hotelReducer.cancelledBooking
  );

  let openNotifyEmailSuccess = (type) => {
    notification[type]({
      // message: "Notification Title",
      description: "Sent Successfully",
    });
    setIsCancel((prev) => !prev);
  };

  useEffect(() => {
    let id = params.bookingID;
    if (errorMsg !== null) {
      // dispatch(flightActions.getBookingDetails(id));
      openNotifyEmailError("error");
    }
  }, [errorMsg]);

  useEffect(() => {
    let id = params.bookingID;
    if (emailReservationData !== null) {
      // dispatch(flightActions.getBookingDetails(id));
      openNotifyEmailSuccess("success");
    }
  }, [emailReservationData]);


  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const isUS = currentGeoLocation === "US";
  const dateFormat = isUS ? "MM/DD/YYYY" : "DD/MM/YYYY";
  const timeFormat = isUS ? "hh:mm a" : "HH:MM";
  return (
    !loading && (
      <div className="siteBg confirmationPage">
        <div className="pageWrap">
          <div className="fixedHeader">
            <HeaderNavbar useBlueIcon={true}/>
          </div>

          <section className="mainContent">
            <Scrollbars
              autoHide
              autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container dflex pageInternalScrollTopPadding">
                <div className="mainWrap">
                  <div className="card2">
                    <div className="messageBox centerAlign">
                      <h3 className="boxTitle">
                        Thank You! <br className="mobileShow" />
                        Your Booking is {bookingStatusReadable}.
                      </h3>
                      <p className="boxCopy">
                        Your confirmation will be emailed to you. You can also
                        access it under your Bookings in the Menu.{" "}
                        {bookingStatusReadable === "Upcoming" ||
                          (bookingStatusReadable === "Completed" &&
                            `Enjoy Your
                        Trip.`)}
                      </p>
                    {bookingStatus == "FAILED" &&
                      <div className="boxBtnWrap">
                        <a className="btn" onClick={downloadInvoice} >
                          <span className="icon-printer"></span>Print Receipt
                        </a>
                        <a className="btn" onClick={toggleModal}  >
                          <span className="icon-mail"></span>Email Receipt
                        </a>
                        <a className="btnBorder" onClick={toggleCancelModal}>
                          Cancel Itinerary
                        </a>
                      </div>
                    } 
                    </div>

                    <div className="travelDetails">
                      {flight?.itinerary?.flightSegments?.length > 0 &&
                        flight?.itinerary?.flightSegments?.map((leg, legNumber) => (
                          <Fragment key={legNumber}>
                            {legNumber === 1 ? (
                              <h4 className="sectionTitle">
                                Returning —{" "}
                                {moment(leg.flightLegs[0]?.departureDateTime).format(
                                  dateFormat
                                )}
                              </h4>
                            ) : (
                              <h4 className="sectionTitle">
                                Departure —{" "}
                                {moment(leg.flightLegs[0]?.departureDateTime).format(
                                  dateFormat
                                )}
                              </h4>
                            )}

                            {leg?.flightLegs?.map((singleLeg, index) => (
                              <div
                                className="dflex flexStart borderBtm"
                                key={index}
                              >
                                <div className="flightInfoLeft">
                                  <div className="flightInfoInWrap" key={index}>
                                    <div className="dflex">
                                      <div className="flightDetails">
                                        <div className="flightId">AY712</div>
                                        <div className="airLinesName">
                                          {singleLeg?.marketingAirlineName || ""}
                                        </div>
                                        <div className="planeName">
                                          {/* Airbus A330 */}
                                        </div>
                                      </div>

                                      <div className="flightStartEnd">
                                        <div className="flightStartInfo">
                                          <div className="flgithDate">
                                            {singleLeg?.departureAirportCode || ""}
                                          </div>
                                          <div className="flightTime">
                                            {moment(
                                              singleLeg?.departureDateTime
                                            ).format(timeFormat)}
                                          </div>
                                          <div className="flightLocation">
                                            {singleLeg?.departureAirportCity}
                                          </div>
                                        </div>

                                        <div className="flightDurNstop">
                                          <div className="flightBar">
                                            <span className="icon-flight">
                                              <span className="path1"></span>
                                            </span>
                                          </div>
                                        </div>

                                        <div className="flightEndInfo">
                                          <div className="flgithDate">
                                            {singleLeg?.arrivalAirportCode}
                                          </div>
                                          <div className="flightTime">
                                            {moment(
                                              singleLeg?.arrivalDateTime
                                            ).format(timeFormat)}
                                          </div>
                                          <div className="flightLocation">
                                            {singleLeg?.arrivalAirportCity}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {leg?.flightLegs.length - 1 !== index && (
                                      <div className="layOverInfo row3">
                                        <div className="innerRow dflex">
                                          <div className="colLeft">
                                            <div className="flightDuration2">
                                              <span className="icon-timer"></span>{" "}
                                              {timeConvert(singleLeg?.stops[index]?.time || 0)}
                                            </div>
                                          </div>

                                          <div className="colRight">
                                            <span className="blueText">
                                              Layover  {singleLeg?.arrivalAirportCode} {singleLeg?.arrivalAirportName}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="rightShadowBox">
                                  <div className="dflex">
                                    <div className="lightText">
                                      Total Journey Duration:
                                    </div>
                                    <div className="darkTex">
                                      {timeConvert(singleLeg?.duration)}
                                    </div>
                                  </div>

                                  <div className="dflex">
                                    <div className="lightText">
                                      Cabin Class:
                                    </div>
                                    <div className="darkTex">
                                      {flight?.requestObj?.cabinClass}
                                    </div>
                                  </div>

                                  <div className="dflex">
                                    <BaggageTooltip
                                      baggageInfo={flight?.baggage_info}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Fragment>
                        ))}

                      <h4 className="sectionTitle">Traveler Information</h4>
                      {
                        flight?.requestObj?.travellers?.map((traveler, index) => (
                          <div
                            className="dflex flexStart travelInfo"
                            key={index}
                          >
                            <div className="col">
                              <div className="lightText">
                                Traveler {index + 1}
                              </div>
                              <div className="darkText">
                                <span className="icon-person">
                                  <span className="path1"></span>
                                </span>
                                {`${traveler?.firstName} ${traveler?.lastName}`}
                              </div>
                            </div>

                            <div className="col">
                              <div className="lightText">Passport Number</div>
                              <div className="darkText">
                                {traveler?.passportNumber}
                              </div>
                            </div>

                            <div className="col">
                              <div className="lightText">Date of Birth</div>
                              <div className="darkText">
                                {moment(traveler?.dateOfBirth).format(dateFormat)}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="sideBar">
                  <div className="card2">
                    <div className="sideCardInPad">
                      <h5 className="cardTille">Summary of Charges</h5>

                      <div className="btmSpace">
                        <span className="sideLightLabel">Booking Date: </span>
                        <span className="sideDarkValue">
                          {moment(confirmed_at).format(dateFormat)}
                        </span>
                      </div>
                    </div>

                    <div className="shadowBox">
                      <div className="dflex">
                        <label className="labelStyle">Base Fare:</label>
                        <span className="rightValue">
                          {currency_symbol}{(flight?.itinerary?.price[flight?.requestObj?.cabinClass]?.price?.basePrice || 0)}
                        </span>
                      </div>

                      <div className="dflex">
                        <label className="labelStyle">Fees and Taxes:</label>
                        <span className="rightValue">{currency_symbol}{(flight?.itinerary?.price[flight?.requestObj?.cabinClass]?.price?.tax || 0)}</span>
                      </div>

                      <div className="dflex">
                        <label className="labelStyle">
                          Payment Gateway Fees:
                        </label>
                        <span className="rightValue"> {currency_symbol}{(flight?.itinerary?.price[flight?.requestObj?.cabinClass]?.price?.paymentGatewayFee || 0)}</span>
                      </div>

                      <div className="dflex totalValueBox">
                        <label className="labelStyle">Total with Tax:</label>
                        <span className="rightValue">{currency_symbol}{(flight?.itinerary?.price[flight?.requestObj?.cabinClass]?.price?.totalPrice || 0)}</span>
                      </div>
                    </div>
                  </div>

                  <div className="card2 helpBox">
                    <h5 className="cardTille">Need Help?</h5>
                    <Link to="/flight-faq" className="faqLink">
                      <span className="icon-help"></span> See all FAQ’s{" "}
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </section>

          {isOpen && (
            <EmailModal
              openModal={isOpen}
              closeModal={toggleModal}
              setIsOpen={setIsOpen}
            />
          )}
          {isCancel && (
            <CancellationModal
              openModal={isCancel}
              closeModal={toggleCancelModal}
              setIsCancel={setIsCancel}
            />
          )}

          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />
        </div>
      </div>
    )
  );
};

export default FlightConfirmation;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { hotelActions } from "../../Stores/Actions";
import DateNTime from "./DateNTime";
import InfoBox from "./InfoBox";
import PriceDetails from "./PriceDetails";

function HotelDetails({
  hotel_name,
  bed_type,
  check_in_date_format4,
  check_out_date_format4,
  check_in_time,
  check_out_time,
  address,
  phone,
  booking_reference_id,
  guest_count,
  room_count,
  night_count,
  price,
  id,
  status,
  currency,
  refundable_status
}) {
  const dispatch = useDispatch();

  const downloadInvoice = () => {
    dispatch(hotelActions.downloadPdf(id));
  };

  return (
    <div className="card2">
      <div className="dflex alignFlexStart">
        <div className="iconWrapMain">
          <div className="iconWrap">
            <span className="icon-apartments">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>
          <span className="booking-status">{status}</span>
        </div>
        <div className="cardContent">
          <h3 className="cartTitle">{hotel_name}</h3>
          <div className="dflex stretch">
            <div className="innerCnt">
              <div className="topSection dflex justifyStart copyText hotelCopy">
                {bed_type} 
                {refundable_status && <span className="refundable"> Refundable</span> }
                {!refundable_status && <span className="non-refundable"> Non Refundable</span> }
              </div>
              <div className="cardCol no-gutters">
                <DateNTime
                  iconName={"icon-invitation"}
                  label="Check-in:"
                  date={check_in_date_format4}
                  time={check_in_time}
                />
                <DateNTime
                  iconName={"icon-invitation"}
                  label="Check-out:"
                  date={check_out_date_format4}
                  time={check_out_time}
                />
              </div>
              <div className="smlText">
                <i className="icon-location"></i>
                {address}
              </div>
              <div className="smlText">
                <i className="icon-phone"></i>
                {phone}
              </div>
              <div className="cardLinkStyle">
                <button onClick={downloadInvoice}>
                  <i className="fa fa-download" aria-hidden="true"></i> Download
                  Invoice
                </button>
              </div>
            </div>
            <div className="infoBox">
              <div className="dflex mb-10 bookingIdWrap">
                <span className="font14 lightText">Booking ID:</span>
                <span className="font14 darkText">{booking_reference_id}</span>
              </div>
              <div className="keyValueWrap">
                <div>
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-group font12"
                    detail1={"Guests:"}
                    detail2={`${guest_count} ${
                      guest_count > 1 ? "adults" : "adult"
                    }`}
                  />
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-room"
                    detail1={"Rooms:"}
                    detail2={`${room_count} room`}
                  />
                  <InfoBox
                    iconName={"icon-invitation font17"}
                    detail1="Nights:"
                    detail2={`${night_count} ${
                      night_count > 1 ? "nights" : "night"
                    }`}
                  />
                </div>
                <PriceDetails
                  details={"Total:"}
                  price={price}
                  currency={currency}
                  text="View & Manage"
                  nextPage={`/hotel-confirmation/${id}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotelDetails;

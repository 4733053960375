import React, { useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import IconInputField from "../../Component/FormComponent/IconInputField";
import LogoWhite from "../../Component/FormComponent/LogoWhite";
import Button from "../../Component/FormComponent/Button";
import Icon from "../../Component/FormComponent/Icon";
import { loginValidation } from "../../Component/Validation/loginValidation";
import Footer from "../../Component/Footer";
import logo from "../../Assets/img/logo.png";
import { authActions, commonActions } from "../../Stores/Actions";
import IcomoonReact from "icomoon-react";
import iconSet from "../../Assets/fonts/selection.json";
import BgImage from "../../Assets/img/Login-Background-screen.png"
import { warn } from "react-bootstrap-typeahead/types/utils";
function Login(props) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const serverErrors = useSelector((state) => state.authReducer.errors);
  const loginData = useSelector((state) => state.authReducer.loginData);
  const loginErrorMsg = useSelector((state) => state.authReducer.loginError);
  const initVal = {
    email: "",
    password: "",
  };
  const handleSubmit = (value) => {
    const payload = {
      email: value.email.trim().toLowerCase(),
      password: value.password.trim(),
      tenant_id: process.env.REACT_APP_TENANT_ID,
    };
    dispatch(authActions.loginRequest(payload, history))
    dispatch(commonActions.getGeoLocationBasedOnIpAddressAction());
  };

  const handleCustomChange = (field, value, formikProps) => {
    if ((serverErrors && Object.keys(serverErrors).length) || loginErrorMsg) {
      dispatch(authActions.resetServeError());
    }
    formikProps.setFieldValue(field, value);
  };

  useEffect(() => {
    if (window.localStorage.getItem("accessToken")) {
      dispatch(authActions.getUserDetail(history));
    }
    const newImage = new Image();
    newImage.src = BgImage;
    window[BgImage] = newImage;
  }, []);
  const renderForm = (formikProps) => {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, eyeIcon } =
      formikProps;
    return (
      <div className="pageBg loginPageWrap">
        <form onSubmit={handleSubmit}>
          <div className="pageWrap pageCenterCnt pageLoginWrap min-height-100vh">
            <Link to={"/"} className="logo">
              <LogoWhite className="logo-image" src={logo} />
            </Link>
            <div className="boxBg">
              <div className="input-text-wrapper">
                <IconInputField
                  // Label="Email"
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={(e) =>
                    handleCustomChange("email", e.target.value, formikProps)
                  }
                  value={values.email}
                  onBlur={handleBlur}
                  iconName="fa fa-envelope"
                  className="iconWrap"
                  errors={errors.email}
                  serverErrors={serverErrors}
                  touched={touched.email}
                  required
                />
              </div>
              <div className="input-text-wrapper">
                <IconInputField
                  // Label="Password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={(e) =>
                    handleCustomChange("password", e.target.value, formikProps)
                  }
                  value={values.password}
                  iconName="icon-lock"
                  className="iconWrap"
                  onBlur={handleBlur}
                  errors={errors.password}
                  ServerErrors={serverErrors}
                  touched={touched.password}
                  eyeIcon={true}
                  required
                />
                {!errors?.email && !errors?.password && loginErrorMsg && (
                  <p className="error-messages">{loginErrorMsg}</p>
                )}
              </div>
            </div>
            <div className="dflex">
              <Link className="linkStyle" to={"/forgot-password"}>
                Forgot Password?
              </Link>
              <Button text="Login" type="submit" className="btn" />
            </div>
            <div className="boxBtmLink">
              {/* <Link className="linkStyle" to={"/freemium-signup"}>
                <IcomoonReact
                  className="icomoon-icon"
                  iconSet={iconSet}
                  color="#ffffff"
                  size={17}
                  icon="account-circle"
                />
                Create Account
              </Link> */}
              <Link className="linkStyle" to={"/#home-faq"}>
                <IcomoonReact
                  className="icomoon-icon"
                  iconSet={iconSet}
                  color="#ffffff"
                  size={17}
                  icon="help"
                />
                Need Help
              </Link>
            </div>
            <Footer />
          </div>
        </form>
      </div>
    );
  };

  return (
    <Formik
      initialValues={initVal}
      onSubmit={handleSubmit}
      validationSchema={loginValidation()}
    >
      {renderForm}
    </Formik>
  );
}

export default Login;

import { serialize } from "../Util/util";
import http from "./http";

function checkHotelAvailability(payload, id) {
  return http
    .post(
      //  `${process.env.REACT_APP_BASE_URL_HOTEL}/v2/rooms/recheck-price/v2/${id}?sold_out=true`,
      //  `${process.env.REACT_APP_BASE_URL_HOTEL}/v2/rooms/recheck-price/v2/${id}?price_up=true`,
      //  `${process.env.REACT_APP_BASE_URL_HOTEL}/v2/rooms/recheck-price/v2/${id}?price_down=true`,
        `${process.env.REACT_APP_BASE_URL_HOTEL}/v2/rooms/recheck-price/v2/${id}`,
      {
        ...payload,
        params: { use_hotel_search_key: true }
      }
    )
    .then((response) => response);
}

function recheckHotelPrice(payload) {
  return http
    .post(`${process.env.REACT_APP_BASE_URL_HOTEL}/v2/rooms/recheck-price`, {
      ...payload,
      params: { use_hotel_search_key: true }
    })
    .then((response) => response);
}

function getStripeIntent(reward_credit_applied) {
  return http.get(
    `${reward_credit_applied ? process.env.REACT_APP_BASE_URL_HOTEL + "/v2/payment/initiate?reward_credit_applied=" + reward_credit_applied : process.env.REACT_APP_BASE_URL_HOTEL + "/v2/payment/initiate?"}`,
    {
      params: { use_hotel_search_key: true },
    }
  );
}

function paymentConfirmService({
  intentId,
  clientSecret,
  cvc,
  expMonth,
  expYear,
  cardNumber,
  cardType,
}) {
  return http
    .post(
      `https://api.stripe.com/v1/payment_intents/${intentId}/confirm?client_secret=${clientSecret}&payment_method_data[type]=${cardType}&payment_method_data[card][cvc]=${cvc}&payment_method_data[card][number]=${cardNumber}&payment_method_data[card][exp_month]=${expMonth}&payment_method_data[card][exp_year]=${expYear}`,
      {
        params: {
          use_stripe_api: true,
          use_form_data: true,
        },
      }
    )
    .then((response) => response);
}

function bookHotelService(payload) {
  return http
    .post(`${process.env.REACT_APP_BASE_URL_HOTEL}/v2/book-v3`, {
      ...payload,
      params: {
        use_hotel_search_key: true,
      },
    })
    .then((response) => response);
}

function searchHotelService(payload) {
  return http
    .post(`${process.env.REACT_APP_BASE_URL_HOTEL}/v2/search/v3`, {
      ...payload,
      params: { use_hotel_search_key: true },
    })
    .then((response) => response);
}

function searchHotelCordService(payload) {
  return http
    .post(`${process.env.REACT_APP_BASE_URL_HOTEL}/v2/search/v3`, {
      ...payload,
      params: { use_hotel_search_key: true },
    })
    .then((response) => response);
}

function getHotelDetails(payload, hotel_id) {
  const qs = serialize(payload);
  return http
    .get(
      `${process.env.REACT_APP_BASE_URL_HOTEL}/v2/hotel/${hotel_id}/static-content/v2?${qs}`,
      {
        params: {
          use_hotel_search_key: true,
        },
      }
    )
    .then((res) => res);
}

function getHotelRooms(payload, hotel_id) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_HOTEL}/v2/hotel/${hotel_id}/packages/v4`,
      {
        ...payload,
        params: { use_hotel_search_key: true },
      }
    )
    .then((res) => res);
}

function validateZip(zip) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_HOTEL}/zip-details?zip=${zip}`
  );
}

function getBookingDetails(id) {
  return http
    .get(`${process.env.REACT_APP_BASE_URL_HOTEL}/v2/booking-details/${id}`, {
      params: { use_base_hotel_url: true },
    })
    .then((res) => res);
}

function downloadPdf(id) {
  return http.get(`${process.env.REACT_APP_BASE_URL_HOTEL}/get-pdf/${id}`, {
    params: {
      use_base_hotel_url: true,
    },
  });
}

function getUpcomingBookings(status) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_USER}/api/bookings/${status}`
  );
}

function getCancelledBookings(status) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_USER}/api/bookings/${status}`
  );
}

function getCompletedBookings(status) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_USER}/api/bookings/${status}`
  );
}

function getFailedBookings(status) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_USER}/api/bookings/${status}`
  );
}
function emailBooking(payload) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL_HOTEL}/v2/send-mail/${payload.id}`,
    {
      ...payload,
      params: {
        use_base_hotel_url: true,
      },
    }
  );
}

function cancelledBooking(id) {
  return http.get(`${process.env.REACT_APP_BASE_URL_HOTEL}/v2/cancel/${id}`, {
    params: { use_base_hotel_url: true },
  });
}

// hotel pre booking
function hotelPrebook(payload, id) {
    return http.post(`${process.env.REACT_APP_BASE_URL_HOTEL}/v2/prebook-v2`, {
    ...payload,
   params: {use_hotel_search_key: true}
  })
}

function getAvailableBalance() {
  return http.get(`${process.env.REACT_APP_BASE_URL_TENANT}/v1/balance/?vertical=hotel&tenant_id=${process.env.REACT_APP_TENANT_ID}`, {
  });
}

export const hotelService = {
  searchHotelService,
  searchHotelCordService,
  validateZip,
  getCompletedBookings,
  getHotelDetails,
  getHotelRooms,
  getBookingDetails,
  downloadPdf,
  emailBooking,
  cancelledBooking,
  checkHotelAvailability,
  recheckHotelPrice,
  getStripeIntent,
  paymentConfirmService,
  bookHotelService,
  getUpcomingBookings,
  getCancelledBookings,
  getFailedBookings,
  hotelPrebook,
  getAvailableBalance,
};

import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import LandingPage from "./LandingPage";
import Password from "./Password/ForgotPassword";
import ResetPassword from "./Password/ResetPassword";
import Login from "./Login/Login";
import Home from "./MarketingWebsite/Home";
import OurStory from "./MarketingWebsite/OurStory";
import PremiumPlusSignUp from "./AffiliateWebsite/PremiumPlusSignUp";
import HotelSearchResult from "./HotelSearchResult/SearchResult";
import HomeSearchResult from "./HomeSearchResult/SearchResult";
import HotelDetail from "./HotelDetail";
import HomeDetail from "./HomeDetail";
import HotelCheckout from "./TravelWebappHotel/HotelCheckout";
import HomeCheckout from "./TravelWebappHome/HomeCheckout";
import FreemiumSignUp from "./MarketingWebsite/FreemiumSignUp";
import NewFreemiumSignUp from "./MarketingWebsite/NewFreemiumSignUp";
import MembershipPremiumCheckout from "./MarketingWebsite/MembershipPremiumCheckout";
import UpgradeMembership from "./MarketingWebsite/UpgradeMembership";
import MembershipPremiumPlusCheckout from "./MarketingWebsite/MembershipPremiumPlusCheckout";
import MembershipUpgrade from "./MarketingWebsite/MembershipUpgrade";
import FlightSearchResult from "./FlightSearchResult";
import FlightCheckout from "./FlightsCheckout/";
import CarsCheckout from "./TravelWebappCar/CarsCheckout";
import CarsBookingConfimation from "./TravelWebappCar/CarsBookingConfimation";
import CarsBookingConfirmationNew from "./TravelWebappCar/CarsBookingConfirmationNew";
import AffiliateLandingPage from "./AffiliateWebsite/AffiliateLandingPage";
import AffiliatePremiumMembership from "./AffiliateWebsite/AffiliatePremiumMembership";
import AffiliateUpgradeMembership from "./AffiliateWebsite/AffiliateUpgradeMembership";
import CarsListing from "./Cars/CarsListing";
import AffiliateDashboard from "./Dashboard";
import HotelFaqs from "./Faqs/HotelFaqs";
import CarFaqs from "./Faqs/CarFaqs";
import FlightFaqs from "./Faqs/FlightFaqs";
import PrivacyPolicy from "./TermsAndConditions/PrivacyPolicy";
import CookiesPolicy from "./TermsAndConditions/CookiesPolicy";
import TermAndCondition from "./TermsAndConditions/TermAndCondition";
import AffiliateInvites from "../Views/AffiliateDashbord/AffiliateInvites";
import MembersInvites from "../Views/AffiliateDashbord/MembersInvites";
import Marketing from "../Views/AffiliateDashbord/Marketing";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Component/Loader/Loader";
import Wallet from "./Wallet/Wallet";
import MemberRewards from "./MarketingWebsite/memberReward/MemberRewards";
import HotelConfirmation from "./TravelWebappHotel/HotelConfirmation";
import HomeConfirmation from "./TravelWebappHome/HomeConfirmation";
import { Thankyou } from "./ThankyouPage/Thankyou";
import FlightConfirmation from "./FlightConfirmation";
import { commonConstants } from "../Stores/Constants/commonConstants";
import TripelloAcademy from "./Academy/TripelloAcademy";
import BestPractices from "./Academy/BestPractices";
import Education from "./Academy/Education";
import TipsAndTricks from "./Academy/TipsAndTricks";
import Tutorial from "./Academy/Tutorial";
import Webinars from "./Academy/Webinars";
import SupportPage from "./SupportPage/SupportPage";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";

// import { Stripe } from "./MarketingWebsite/Stripe";
import BookingDetails from "./MyBookingSection/BookingDetails";
import Profile from "./Profile";

function RequireAuth() {
  const dt2 = new Date();
  var diff =
    (dt2.getTime() - new Date(window.localStorage.getItem("lt"))?.getTime()) /
    1000;
  const toMin = diff / 60;
  if (toMin > 240) {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("lt");
    window.localStorage.clear();
  }
  let location = useLocation();
  const isLogIn =
    window.localStorage.accessToken != null && window.localStorage.user != null;
  return isLogIn ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}
export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  }, [pathname]);
  return null;
}
function Root() {
  const showLoader = useSelector((state) => state.commonReducer.loader);
  const dispatch = useDispatch();
  const [showCookiesModal, setShowCookiesModal] = useState(true);
  useEffect(() => {
    dispatch({
      type: commonConstants.IS_MOBILE_DEVICE,
      isMobile: window.innerWidth < 768 ? true : false,
    });
  }, []);

  const updateSize = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    if (window.innerWidth < 768) {
      dispatch({ type: commonConstants.IS_MOBILE_DEVICE, isMobile: true });
    } else {
      dispatch({ type: commonConstants.IS_MOBILE_DEVICE, isMobile: false });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [window.innerWidth]);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* protected routes */}
          <Route element={<RequireAuth />}>
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/membersupport" element={<SupportPage />} />
            <Route path="/hotel-detail/:hotel_id" element={<HotelDetail />} />
            <Route path="/home-detail/:hotel_id" element={<HomeDetail />} />

            <Route
              path="/flight-search-result"
              element={<FlightSearchResult />}
            />

            <Route path="/flight-checkout" element={<FlightCheckout />} />

            <Route
              path="/flight-confirmation/:bookingID"
              element={<FlightConfirmation />}
            />

            <Route path="/cars-checkout" element={<CarsCheckout />} />

            {/* <Route
              path="/cars-booking-confirmation"
              element={<CarsBookingConfimation />}
            /> */}
            <Route
              path="/cars-booking-confirmation/:bookingID"
              element={<CarsBookingConfirmationNew />}
            />

            <Route
              path="/hotel-checkout/:hotelID/:roomId/:roomNo"
              element={<HotelCheckout />}
            />
            <Route
              path="/home-checkout/:hotelID/:roomId/:roomNo"
              element={<HomeCheckout />}
            />

            <Route
              path="/hotel-confirmation/:bookingID"
              element={<HotelConfirmation />}
            />

            <Route
              path="/home-confirmation/:bookingID"
              element={<HomeConfirmation />}
            />

            <Route
              path="/hotel-search-result"
              element={<HotelSearchResult />}
            />

            <Route path="/home-search-result" element={<HomeSearchResult />} />

            <Route path="/cars-listing" element={<CarsListing />} />

            <Route path="/profile" element={<Profile />} />

            <Route path="/wallet" element={<Wallet />} />

            <Route
              path="/affiliate-dashboard"
              element={<AffiliateDashboard />}
            />

            <Route path="/booking-details" element={<BookingDetails />} />

            <Route path="/affiliate-invites" element={<AffiliateInvites />} />

            <Route path="/member-invites" element={<MembersInvites />} />

            <Route path="/member-rewards" element={<MemberRewards />} />

            <Route path="/hotel-faq" element={<HotelFaqs />} />

            <Route path="/car-faq" element={<CarFaqs />} />

            <Route path="/flight-faq" element={<FlightFaqs />} />

            <Route path="/tripello-academy" element={<TripelloAcademy />} />
            <Route path="/best-practices" element={<BestPractices />} />
            <Route path="/tips-tricks" element={<TipsAndTricks />} />
            <Route path="/tutorials" element={<Tutorial />} />
            <Route path="/education" element={<Education />} />
            <Route path="/Webinars" element={<Webinars />} />

            <Route path="/membership-upgrade" element={<MembershipUpgrade />} />

            <Route
              path="/upgrade/membership/:user_type_id"
              element={<UpgradeMembership />}
            />
          </Route>

          {/* Public routes */}

          <Route path="/login" element={<Login />} />

          <Route path="/forgot-password" element={<Password />} />

          <Route path="/reset-password" element={<ResetPassword />} />

          <Route exact path="/" element={<Home />} />
          <Route exact path="/our-story" element={<OurStory />} />

          <Route path="/premium-plus-signup" element={<PremiumPlusSignUp />} />

          <Route path="/freemium-signup" element={<FreemiumSignUp />} />

          <Route path="/new-freemium-signup" element={<NewFreemiumSignUp />} />

          <Route
            path="/membership-premium-checkout"
            element={<MembershipPremiumCheckout />}
          />

          <Route
            path="/membership-premium-plus-checkout"
            element={<MembershipPremiumPlusCheckout />}
          />

          <Route
            path="/affiliate-landing-page"
            element={<AffiliateLandingPage />}
          />

          <Route
            path="/affiliate-premium-membership"
            element={<AffiliatePremiumMembership />}
          />

          <Route
            path="/affiliate-upgrade-membership"
            element={<AffiliateUpgradeMembership />}
          />

          <Route path="/marketing" element={<Marketing />} />

          <Route path="/thankyou" element={<Thankyou />} />

          <Route path="/thankyou/:pageId" element={<Thankyou />} />

          <Route path="/user/confirmation" element={<Thankyou />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/terms-conditions" element={<TermAndCondition />} />
        </Routes>
      </BrowserRouter>
      <Loader showLoader={showLoader} />
      {showCookiesModal && (
        <CookieConsent
          location="bottom"
          buttonText="Accept all"
          cookieName="trieplloCookies"
          style={{
            background: "linear-gradient(128deg, #006bbf 29%, #00ceda 110%)",
            padding: "10px",
          }}
          buttonStyle={{
            color: "#ffffff",
            fontSize: "14px",
            background: "linear-gradient(90deg,#1377c4,#00ceda)",
            borderRadius: "22.5px",
            padding: "8px 18px",
            boxShadow:
              "2px 2px 4px 2px rgb(0 0 0 / 25%), -1px -1px 4px 2px rgb(255 255 255 / 35%)",
          }}
          expires={150}
        >
          <div className="cookies-content-wrap">
            <span>
              This website uses cookies to enhance the user experience.{" "}
            </span>
            <button
              className="btn-accept"
              style={{
                background:
                  "linear-gradient(128deg, #006bbf 29%, #00ceda 110%)",
                padding: "10px",
                color: "#ffffff",
                fontSize: "14px",
                background: "linear-gradient(90deg,#1377c4,#00ceda)",
                borderRadius: "22.5px",
                padding: "8px 18px",
                boxShadow:
                  "2px 2px 4px 2px rgb(0 0 0 / 25%), -1px -1px 4px 2px rgb(255 255 255 / 35%)",
                border: "none",
              }}
              onClick={() => setShowCookiesModal(false)}
            >
              Accept only necessary
            </button>
          </div>
        </CookieConsent>
      )}
    </>
  );
}

export default Root;

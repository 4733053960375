import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../FormComponent/Button";
import { getCookie, commafy } from "../../Util/util";

import arrowImg from "../../Assets/img/profile/warning.svg";
function HotelPriceSection({
  public_price,
  final_price,
  savings_percentage,
  savings_amount,
  voucher_credit,
  per_night_public_price,
  save_more_percentage,
  per_night,
  per_night_savings_amount,
  save_more_final_price,
  no_of_nights,
  per_night_voucher_credit,
  per_night_save_more_final_price,
  per_night_save_more_savings,
  client_commission,
  isMember,
  isDetailPage,
  id,
  setPriceDetail,
  setShowUpdate,
  showUpdate,
  save_more,
  currencyCode,
}) {
  const [selectedTab, setselectedTab] = useState(1);
  const [toggalSeeMemberPrice, setToggalSeeMemberPrice] = useState(false);
  const [isPremium, setIsPremium] = useState(null);
  const isFreemium = process.env.REACT_APP_FREMIUM_USER_TYPE_ID == JSON.parse(localStorage?.user)?.type_id
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  });

  const [prices, setPrices] = useState({});

  const premiumPrices = {
    // total prices
    public_price: public_price,
    from_price: save_more_final_price,
    savings_percentage: savings_percentage,
    savings_amount: save_more,
    // night prices
    night_public_price: per_night_public_price,
    night_from_price: per_night_save_more_final_price,
    night_savings_percentage: savings_percentage,
    night_savings_amount: per_night_save_more_savings,
    no_of_nights: no_of_nights,
    save_more: save_more,
  };

  const fremiumPrices = {
    // total prices
    public_price: public_price,
    from_price: final_price,
    savings_percentage: savings_percentage,
    savings_amount: voucher_credit,
    // night prices
    night_public_price: per_night_public_price,
    night_from_price: per_night,
    night_savings_percentage: savings_percentage,
    night_savings_amount: per_night_voucher_credit,
    save_more: save_more,

    no_of_nights: no_of_nights,
  };

  const premiumForFremium = {
    // total pricesP
    public_price: public_price,
    from_price: save_more_final_price,
    savings_percentage: save_more_percentage,
    savings_amount: save_more,
    // night prices
    night_public_price: per_night_public_price,
    night_from_price: per_night_save_more_final_price,
    night_savings_percentage: save_more_percentage,
    night_savings_amount: per_night_save_more_savings,

    no_of_nights: no_of_nights,
    save_more: save_more,
  };

  useEffect(() => {
    setIsPremium(getCookie("is_premium") === "true");
    // setIsPremium(false);
  }, []);

  useEffect(() => {
    if (isPremium == true) {
      setPrices(premiumPrices);
    } else if (isPremium == false) {
      setPrices(fremiumPrices);
    }
    if (showUpdate) setPrices(premiumForFremium);
  }, [showUpdate, isPremium]);

  return (
    <>
      <div className="title d-flex">
        <Button
          className={`totalPriceTab ${selectedTab === 0 ? "active" : ""}`}
          onClick={() => {
            setselectedTab(0);
            setPriceDetail("night");
          }}
        >
          Nightly Price
        </Button>
        <Button
          className={`nightlyPriceTab ${selectedTab === 1 ? "active" : ""}`}
          onClick={() => {
            setselectedTab(1);
            setPriceDetail("total");
          }}
        >
          Total Price
        </Button>
      </div>
      <div className="infoBoxWrapper totalPriceBox">
        {selectedTab === 0 ? (
          <>
            {prices.night_savings_amount > 0 && (
              <div className="dflex publicPrice">
                <span className="lightText">Public Price:</span>
                <span className="font14">
                  {formatter.format(prices.night_public_price)}
                </span>
              </div>
            )}
            <div>
              <div
                className={`dflex btmSpace fromPrice ${prices.night_savings_amount > 0 ? "" : "singlePrice"
                  }`}
              >
                {isFreemium && <> <div className="fromPriceWrap">
                  <span className={`font17 lightText ${toggalSeeMemberPrice && "from-text-label"}`}>From:</span>
                  <p className="btmSpace lightText">
                    (1 night incl. taxes & fees)
                  </p>
                </div>

                  <span className={`font17 ${toggalSeeMemberPrice && "from-text-label"}`}>
                    {formatter.format(prices.night_from_price)}
                  </span>
                </>}
                {!isFreemium && <> <div className="fromPriceWrap">
                  <span className="font17 lightText">From:</span>
                  <p className="btmSpace lightText">
                    (1 night incl. taxes & fees)
                  </p>
                </div>

                  <span className="font17">
                    {formatter.format(prices.night_from_price)}
                  </span>
                </>}
              </div>
            </div>

            <div className="buttonAndTextGroup savings-group-wrap">
              <div className="dflex blueText btmSpace2 savings">
                {prices.night_savings_amount > 0 && (
                  <>
                    <span>Savings ({prices?.night_savings_percentage}%):</span>
                    <span className="font14">
                      {formatter.format(prices?.night_savings_amount)}
                    </span>
                  </>
                )}
              </div>
              {/* } */}
            </div>

            <div className="buttonGroups">
              {showUpdate && (
                <div className="centerAlign buttonSpace upgradeBtn">
                  <Link to="/membership-upgrade" className="btn">
                    <i className="icon-border-plain"></i>
                    Upgrade
                  </Link>
                  <button className="btn-cancel" onClick={() => {
                    setShowUpdate(!showUpdate);
                    setToggalSeeMemberPrice(false);
                  }
                  }
                  >
                    Cancel
                  </button>
                </div>
              )}

              {!isPremium && !showUpdate && prices.night_savings_amount > 0 && (
                <div className="centerAlign buttonSpace">
                  <button
                    className="btnBorder2 btn-see-member-price"
                    onClick={() => {
                      setShowUpdate(!showUpdate);
                      setToggalSeeMemberPrice(true);

                    }}
                  >
                    <i className="icon-border-plain"></i>
                    See Member Price
                  </button>
                </div>
              )}

              {!showUpdate && (
                <div className="centerAlign buttonSpace buttonSpace-select-room">
                  {/* <Link
                    to={`/hotel-detail/${id}`}
                    className="btn btn-select-room"
                  >
                    Select Room
                  </Link> */}
                  <button
                    to={`/hotel-detail/${id}`}
                    className="btn btn-select-room"
                    disabled={true}
                  >
                    Select Room
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          // Nightly price

          <>
            {prices.savings_amount > 0 && (
              <div className="dflex publicPrice">
                <span className="lightText">Public Price:</span>
                <span className="font14">
                  {formatter.format(prices.public_price)}
                </span>
              </div>
            )}
            <div>
              <div
                className={`dflex btmSpace fromPrice ${prices.savings_amount > 0 ? "" : "singlePrice"
                  }`}
              >
                {isFreemium && <>  <div className="fromPriceWrap">
                  <span className={`font17 lightText ${toggalSeeMemberPrice && "from-text-label"}`}>
                    {/* <span className="font17 lightText from-text-label"> */}
                    From{`${prices.savings_amount > 0 ? ":" : ""}`}
                  </span>
                  <p className="btmSpace lightText ">
                    ({no_of_nights} {no_of_nights > 1 ? "nights" : "night"}  incl. taxes &amp; fees)
                  </p>
                </div>
                  <span className={`font17 ${toggalSeeMemberPrice && "from-text-label"}`}>

                    {/* <span className="font17 from-text-label"> */}
                    {formatter.format(prices.from_price)}
                  </span>
                </>
                }
                {!isFreemium && <>
                  <div className="fromPriceWrap">
                    <span className="font17 lightText">
                      From{`${prices.savings_amount > 0 ? ":" : ""}`}
                    </span>
                    <p className="btmSpace lightText ">
                      ({no_of_nights} {no_of_nights > 1 ? "nights" : "night"}  incl. taxes &amp; fees)
                    </p>
                  </div>

                  <span className="font17">
                    {formatter.format(prices.from_price)}
                  </span>
                </>
                }
              </div>

            </div>

            <div className="buttonAndTextGroup savings-group-wrap">
              <div className="dflex blueText btmSpace2 savings">
                {prices.savings_amount > 0 && (
                  <>
                    <span>Savings ({prices?.savings_percentage}%):</span>
                    <span className="font14">
                      {formatter.format(prices?.savings_amount)}
                    </span>
                  </>
                )}
              </div>
            </div>

            <div className="buttonGroups">
              {showUpdate && (
                <div className="centerAlign buttonSpace upgradeBtn">
                  {/* <Link to="/membership-upgrade" className="btn">
                    <i className="icon-border-plain"></i>
                    Upgrade
                  </Link> */}
                  <button disabled={true} to="/membership-upgrade" className="btn">
                    <i className="icon-border-plain"></i>
                    Upgrade
                  </button>
                  <button className="btn-cancel" onClick={() => {
                    setShowUpdate(!showUpdate);
                    setToggalSeeMemberPrice(false);
                  }}
                  >
                    Cancel
                  </button>
                </div>
              )}

              {!isPremium && !showUpdate && prices.savings_amount > 0 && (
                <div className="centerAlign buttonSpace">
                  <button
                    className="btnBorder2 btn-see-member-price"
                    onClick={() => {
                      setShowUpdate(!showUpdate);
                      setToggalSeeMemberPrice(true);

                    }
                    }
                  >
                    <i className="icon-border-plain"></i>
                    See Member Price
                  </button>
                </div>
              )}

              {!showUpdate && (
                <div className="centerAlign buttonSpace buttonSpace-select-room">
                  {/* <Link
                    to={`/hotel-detail/${id}`}
                    className="btn btn-select-room"
                  >
                    Select Room
                  </Link> */}
                  <button
                    to={`/hotel-detail/${id}`}
                    className="btn btn-select-room"
                    disabled={true}
                  >
                    Select Room
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default HotelPriceSection;

import {supportPageService} from "../../api/supportPageService";
import { commonActions } from "./commonActions";
import {supportConstant} from "../Constants/supportConstant"
export const supportPageAction = {
  sendIssueToSupportTeam,
  getAutoCompletedData,
  getIssueListByCategory
}

 function sendIssueToSupportTeam(payload) {
  return async (dispatch) => {
      dispatch(request(supportConstant.SUPPORT_FROM_SUBMITTED_REQUESTED));
      dispatch(commonActions.loaderText("LOADING..."));
      dispatch(commonActions.showLoader());
    try {
      const response = await supportPageService.sendIssueToSupportTeamService(payload);
      dispatch(commonActions.hideLoader());
      if (response.status == 200)
        dispatch(success(supportConstant.SUPPORT_FROM_SUBMITTED_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(failure(supportConstant.SUPPORT_FROM_SUBMITTED_FAILURE, error));
      dispatch(commonActions.hideLoader());
      return error;
    }
  };
}

// get getIssueListByCategory
 function getIssueListByCategory(payload) {
  return async (dispatch) => {
    dispatch(request(supportConstant.GET_SUPPORT_ISSUES_LIST_REQUESTED));
    try {
      const response = await supportPageService.getIssueListByCategory(payload);
      if (response.status == 200)
        dispatch(success(supportConstant.GET_SUPPORT_ISSUES_LIST_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(failure(supportConstant.GET_SUPPORT_ISSUES_LIST_FAILURE, error));
      return error;
    }
  };
}

//
 function getAutoCompletedData(payload) {
  return async (dispatch) => {
      dispatch(request(supportConstant.GET_SUPPORT_AUTO_LIST_REQUESTED));
    try {
      const response = await supportPageService.getAutoCompletedData(payload);
      if (response.status == 200)
        // dispatch(success(supportConstant.GET_SUPPORT_AUTO_LIST_SUCCESS, response));
      return response;
    } catch (error) {
      // dispatch(failure(supportConstant.GET_SUPPORT_AUTO_LIST_FAILURE, error));
      return error;
    }
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}

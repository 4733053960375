import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import HeaderNavbar from "../../Component/HeaderNavbar";
import Button from "../../Component/FormComponent/Button";
import Card from "../../Component/Card";
import Video from "../../Component/Video";
import PlanInfo from "../../Component/PlanInfo";
import { faqs } from "../../main/CustomConstants/Home";
import AccordianTwo from "../../Component/AccordianTwo";
import playButton from "../../Assets/img/network/play-button.png";
import videoBg from "../../Assets/img/membership-upgrade/video-bg.png";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import plusIcon from "../../Assets/img/membership-upgrade/plus-icon.png";
import videoSrc from "../../Assets/video/membership-upgrade/Freemium-To-Premium-Upgrade.mp4";
import PortraitModeModel from "../../Component/PortraitModeModel";
import VideoModal from "../../Component/VideoModal";
import Scrollbars from "react-custom-scrollbars";

import {
  FEATURES,
  PLANS,
} from "../../Stores/Constants/siteConstants/membershipUpgradeConstants";
import UpgradeBanner from "../../Component/UpgradeBanner";

function MembershipUpgrade() {
  // const [showAllaccordion, setshowAll] = useState(false);

  // state for expanding accordian
  const [isExpandAccordian, setIsExpandAccoridan] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [portraitViewModal, setportraitViewModal] = useState(
    window.outerWidth > 768 && window.outerWidth < 1025 ? true : false
  );

  const LocalUser = JSON.parse(window.localStorage.getItem("user"));

  const scrollRef = useRef(null);


  const ScrollIntoCard = () => {
    const offsetTop = document.getElementById("scroll").offsetTop
    if (window.outerWidth > 991) {
      scrollRef.current.view.scroll({
        top: offsetTop,
        behavior: 'smooth',
      })
    } else {
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })
    } 
  }

  // state for open and close accordian list
  const [openedAccordian, setOpenedAccordian] = useState(0);

  //Open and close accordian list
  const handleOpenClose = (id) => {
    if (id === openedAccordian) {
      setOpenedAccordian(null);
    } else {
      setOpenedAccordian(id);
    }
  };

  const handleOpenCloseVideoModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handleOpenClosePortraitModal = () => {
    if (portraitViewModal) {
      setportraitViewModal(false);
    } else {
      setportraitViewModal(true);
    }
  };

  const getFaqs = (faqList) => {
    let accordinNodes = [];

    // Accordian list

    faqList.forEach((faq, index) => {
      //render the whole list if true
      if (isExpandAccordian) {
        accordinNodes.push(
          <AccordianTwo
            handleOpenClose={handleOpenClose}
            openedAccordian={openedAccordian}
            id={index}
            key={faq.title + index}
            title={faq.title}
            content={faq.description}
            className={`card-content`}
          />
        );
      } else {
        // render only limited list
        if (index < 6) {
          accordinNodes.push(
            <AccordianTwo
              handleOpenClose={handleOpenClose}
              openedAccordian={openedAccordian}
              id={index}
              key={faq.title + index}
              title={faq.title}
              content={faq.description}
              className={`card-content`}
            />
          );
        }
      }
    });

    return accordinNodes;
  };

  return (
    <>
      <div className="siteBg MembershipUpgradePageWrap">
        <div className="pageWrap pageWrapInner">
          <div className="fixedHeader">
            <HeaderNavbar useBlueIcon={true}/>
          </div>
          <section className="mainContent">
            <Scrollbars
              autoHeight={true}
              ref={scrollRef}
              autoHeightMin={175}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container pageInternalScrollTopPadding">
                <div className="upgrade-now-wrapper">
                  <UpgradeBanner ScrollIntoCard={ScrollIntoCard} />
                </div>
                <div className="grid-layout-wrap">
                  <div className="section-title">
                    <h2>Save up to 65% and more on…</h2>
                  </div>
                  <div className="grid-layout row">
                    {FEATURES.map((feature) => (
                      <Card
                        title={
                          <h6>
                            <Link to={feature.link}>{feature.title}</Link>
                          </h6>
                        }
                        colClassName="col grid-item"
                        styleClassName="infobox"
                        imgClassName="image-box"
                        textClassName="grid-content"
                        image={feature.imgUrl}
                        description={feature.desc}
                        backgroundImage={true}
                      />
                    ))}
                  </div>
                </div> 

                <div className="plan-wrapper">
                  <div className="section-title"  id="scroll">
                    <h2>You Choose Which One Is Right For You</h2>
                  </div>
                  <div className="choose-plan" >
                    <div className="row">
                      {PLANS.map((plan, index) => {
                        if(plan.planName ==="Premium Membership" && LocalUser?.type_id  === process.env.REACT_APP_PREMIUM_USER_TYPE_ID) return <></>
                        return <>
                          <div className="col plan-item">
                            <PlanInfo
                              planName={plan.planName}
                              months={plan.months}
                              arrayList={plan.arrayList}
                              image={
                                plan.months === "(12 Month)" ? (
                                  <>
                                    {" "}
                                    <img src={plusIcon} alt="icon" />
                                  </>
                                ) : null
                              }
                              heading2={
                                <>
                                  {plan.price} <span>/ per month</span>
                                </>
                              }
                              heading3={plan.heading}
                              para={plan.para}
                              link={plan.link}
                            />
                          </div>
                        </>
                      })}
                    </div>
                  </div>
                </div>
                <div className="faq-wrapper">
                  <div className="section-title">
                    <h2>Here are Some Answers to Common Questions</h2>
                  </div>
                  <div className="accordion">
                    <div className="toggle-more-accordions">
                      {getFaqs(faqs)}
                    </div>
                  </div>
                  {/* more and less button below accordian */}

                  {faqs.length > 5 && (
                    <div className="read-more-btn-wrap">
                      {!isExpandAccordian ? (
                        <button
                          onClick={() => {
                            setIsExpandAccoridan(true);
                          }}
                          className="read-more-btn"
                          type="button"
                        >
                          more...
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setIsExpandAccoridan(false);
                          }}
                          className="read-more-btn"
                          type="button"
                        >
                          less...
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <div className="upgrade-now-wrapper">
                  <UpgradeBanner ScrollIntoCard={ScrollIntoCard} />
                </div>
              </div>
            </Scrollbars>
          </section>
        </div>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
      {/* Modal open and close  */}

      {openModal && (
        <VideoModal
          openModal={openModal}
          closeModal={handleOpenCloseVideoModal}
          videoSrc={videoSrc}
        />
      )}
    </>
  );
}

export default MembershipUpgrade;

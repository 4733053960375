import { useState } from "react";

const BaggageTooltip = ({ baggageInfo }) => {
  const [baggageTooltip, setBaggageToolTip] = useState(false);

  return (
    <>
      <div className="lightText">Baggage info:</div>
      <div
        className="darkTex"
        onClick={() => setBaggageToolTip((oldState) => !oldState)}
      >
        <a href="#">Baggage Allowance</a>
      </div>

      {baggageTooltip && (
        <div>
          <table>
            <thead>
              <tr>
                <th>Baggage Wight</th>
                <th>Baggage Dimensions</th>
                <th>Baggage Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{baggageInfo?.description1}</td>
                <td>{baggageInfo?.description2}</td>
                <td>{baggageInfo?.piece_count}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default BaggageTooltip;

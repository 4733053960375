import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import Header from "../../Component/Header";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import creaditCardImage from "../../Assets/img/credit-card.png";
import lockImage from "../../Assets/img/lock.svg";
import image from "../../Assets/img/membership-premium-plan.png";
import LogoWhite from "../../Component/FormComponent/LogoWhite";
import logo from "../../Assets/img/logo.png";
import paypal from "../../Assets/img/checkout/paypal.png";
import applePay from "../../Assets/img/checkout/apple-pay.png";
import googlePay from "../../Assets/img/checkout/google-pay.png";
import wechatPay from "../../Assets/img/checkout/wechat-pay.png";
import bitcoin from "../../Assets/img/checkout/bitcoin.png";
import Icon from "../../Component/FormComponent/Icon";
import { PremiumCheckoutValidation } from "../../Component/Validation/PremiumCheckoiutValidaion";
import PrimaryContactInformation from "../../Component/PrimaryContactInformation";
import BillingDetails from "../../Component/BillingDetails";
import TermsCheckbox from "../../Component/TermsCheckbox";
let checkoutCountries = require("../../Util/static-service");
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../Stores/Actions/authActions";
import { useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { premiumCheckoutInitialValues } from "../../Data/InitialValues";
import { getLocationParams } from "../../Util/util";

function MembershipPremiumCheckout() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationSearch = getLocationParams();
  const isUserExist = useSelector((state) => state.authReducer.isUserExist);
  const user_id1 = useSelector((state) => state.authReducer.user_id);
  const [user_id, setUser_id] = useState(null);

  const premiumSignupData = useSelector(
    (state) => state.authReducer.premiumSignupData
  );
  const base_currency = useSelector(
    (state) => state.currencyLanguageReducer?.data?.currency?.isoCode
  );
  const [featureDropdown, setFeatureDropdown] = useState(false); // features open and hide in planInfo
  const [isPromoAppliedMsg, setPromoAppliedMsg] = useState(null);
  const [isCouponAppliedMsg, setCouponAppliedMsg] = useState(null);
  const [finalCheckoutPrice, setFinalCheckoutPrice] = useState("195.00");
  const handleDropdown = () => {
    // function for handling dropdown feature
    setFeatureDropdown(!featureDropdown);
  };

  const [isHeaderSticky, setHeaderSticky] = useState(false);

  // form intialValues
  const initialValues = {
    ...premiumCheckoutInitialValues,
    firstName: locationSearch?.first_name || "",
    lastName: locationSearch?.last_name || "",
    email: locationSearch?.email || "",
  };
  const onScrollFrame = (e) => {
    if (e.scrollTop > 10) {
      setHeaderSticky(true);
    } else {
      setHeaderSticky(false);
    }
  };

  useEffect(() => {
    if (user_id1) {
      setUser_id(user_id1);
      dispatch(authActions.resetUserData());
    }
  }, [user_id1]);

  useEffect(() => {
    if (locationSearch?.ref && !user_id) {
      dispatch(authActions.getUserByAffiliateId(locationSearch.ref));
    }

    // status code 200 : show error if email is already exist
    if (isUserExist === 200)
      return formikRef.current.setErrors({
        ...formikRef.current.errors,
        email:
          "You are already a member, please login or use a different email",
      });

    if (isUserExist === 422) {
      // status code 422 : email is not exist in database.
      const values = formikRef.current.values;
      const payload = {
        title: values.title,
        first_name: values.firstName,
        last_name: values.lastName,
        login_name: values.email,
        email: values.email,
        password: values.password,
        country: values.nationality,
        address: values.addressOne,
        phone: values.phoneNumber,
        showCouponCode: values.hasPromo,
        base_currency: base_currency || "USD",
        status: "1",
        accessible_modules: "WALLET,CARDS,USERS",
        timezone: values.timezone,
        user_type_id: process.env.REACT_APP_PREMIUM_USER_TYPE_ID, // 06 MONTHS PREMIUM
        tenant_id: process.env.REACT_APP_TENANT_ID,
        //card
        billing_address: `${values.addressOne} ${values.addressTwo}`,
        billing_city: values.city,
        billing_country: values.nationality,
        billing_state: values.state,
        billing_zip: values.zipCode,
        sponsor_id: user_id || process.env.REACT_APP_USER_ID,
      };
      if (!values.hasPromo) {
        payload.credit_number = values.credit_number || "";
        payload.exp_month = values.mmyy.split("/")[0];
        payload.exp_year = values.mmyy.split("/")[1];
        payload.security_code = values.security_code;
      }
      if (isPromoAppliedMsg && values.hasPromo) {
        payload.promotional_code = values.promoCode;
      }
      if (values.hasCoupon) {
        payload.coupon_code = values.couponCode;
      }

      return dispatch(authActions.premiumSignupRequest(payload)); // email is unique, call signup api
    }
    if (premiumSignupData == 200) {
      navigate("/thankyou/paiduser");
    }
  }, [isUserExist, premiumSignupData]);
  const handleSubmit = (value, formikProps) => {
    dispatch(authActions.isEmailExistRequest(value.email));
  };

  const ApplyPromo = (promoCode) => {
    const payload = {
      code: promoCode.trim(),
      user_type_id: process.env.REACT_APP_PREMIUM_USER_TYPE_ID,
    };

    dispatch(authActions.validatePromocodeRequest(payload)).then((res) => {
      if (res?.data?.success) {
        setPromoAppliedMsg(
          res?.data?.message
        );
        setFinalCheckoutPrice("0.00");
      } else {
        setFinalCheckoutPrice("195.00");
        setPromoAppliedMsg(null);
        formikRef.current.setErrors({
          ...formikRef.current.errors,
          promoCode: promoCode
            ? "Invalid Promo Code"
            : "Promo code can not be empty",
        });
        formikRef.current.setTouched({ promoCode: true });
      }
    });
  };
  const ApplyCoupon = (couponCode) => {
    const payload = {
      coupon_code: couponCode.trim(),
      user_type_id: process.env.REACT_APP_PREMIUM_USER_TYPE_ID,
      tenant_id: process.env.REACT_APP_TENANT_ID,
    };

    dispatch(authActions.validateCouponCodeRequest(payload)).then((res) => {
      if (res?.data?.success) {
        setCouponAppliedMsg(
          res?.data?.message
        );
        setFinalCheckoutPrice(res?.data?.package_price);

      } else {
        setFinalCheckoutPrice("195.00");
        setCouponAppliedMsg(null);
        formikRef.current.setErrors({
          ...formikRef.current.errors,
          couponCode: couponCode
            ? "Invalid Coupon Code"
            : "Coupon code can not be empty",
        });
        formikRef.current.setTouched({ couponCode: true });
      }
    });
  };

  // Title options list
  const optionsTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Neutral.", label: "Neutral." },
  ];

  const optionsNationality = checkoutCountries.map((curr) => {
    return { value: curr.alpha2, label: curr.name };
  });

  // Array list of planInfo
  const arrayList = [
    "Credit/Debit Card Required*",
    "Unlimited Platform Access",
    "Savings of up to 65% and more",
    "Access to 1.5 million Hotels and Resorts",
    "Access to 1.5 million Vacation Homes",
    "Access to Rental Cars and upcoming Chauffeur Service.",
    "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
  ];

  const backgroundImage = {
    contentImage: `url${image}`, // background image of planInfo
  };

  // handleCustomChange to restrict aplphabet enter
  const numberCustomChange = (formikProps, field, value) => {
    if (
      field === "phoneNumber" ||
      field === "credit_number" ||
      field === "security_code"
    ) {
      const temp = Number(value);
      if (field === "security_code" && value.length > 4) {
        return;
      }
      if (temp || value === "") {
        formikProps.setFieldValue(field, value);
      }
    }
  };

  const handleCustomChange = (formikProps, field, value) => {
    // handleCustomChange function for selectfieldoption input
    formikProps.setFieldValue(field, value);
  };

  const handleCheckboxChange = (formikProps, field, value) => {
    if (field === "hasPromo") {
      formikProps.setFieldValue("hasCoupon", false);
      formikProps.setFieldValue(field, value);
    } else {
      formikProps.setFieldValue("hasPromo", false);
      formikProps.setFieldValue(field, value);
    }
    setFinalCheckoutPrice("195.00");
    formikProps.setFieldValue("couponCode", "");
    formikProps.setFieldValue("promoCode", "");
    setCouponAppliedMsg(null);
    setPromoAppliedMsg(null);
    formikProps.setErrors({
      ...formikProps.errors,
      couponCode: null,
      promoCode: null,
    });
    formikProps.setTouched({ couponCode: false });
    formikProps.setTouched({ promoCode: false });

  }

  const renderForm = (formikProps) => {
    const childRef = useRef();
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
    } = formikProps;
    const optionsStateFilter = checkoutCountries.filter(
      (curr) => curr.alpha2 === values.nationality
    );
    const optionsState = optionsStateFilter[0]?.states.map((curr) => {
      return { value: curr.code, label: curr.name };
    });
    const [activeTab, setActiveTab] = useState(1); // billing details active tab

    return (
      <form onSubmit={handleSubmit}>
        <PrimaryContactInformation
          // onchange and onblur for common
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          numberCustomChange={numberCustomChange}
          // Title
          title="title"
          titleLabel="Title"
          titleOptions={optionsTitle}
          titlePlaceholder="Title"
          // First Name
          firstName="firstName"
          firstNameLabel="First Name"
          firstNamePlaceholder="Walter"
          // Last Name
          lastName="lastName"
          lastNameLabel="Last Name"
          lastNamePlaceholder="White"
          // Email
          email="email"
          emailLabel="Email"
          emailPlaceholder="Email"
          // Phone Number
          phoneNumber="phoneNumber"
          phoneNumberLabel="Phone Number"
          // Nationality
          nationality="nationality"
          nationalityLabel="Nationality"
          nationalityPlaceholder="Select Country"
          nationalityOptions={optionsNationality}
          // Addressone
          addressOne="addressOne"
          addressOneLabel="Address Line 1"
          addressOnePlaceholder="Address Line 1"
          // AddressTwo
          addressTwo="addressTwo"
          addressTwoLabel="Address Line 2"
          addressTwoPlaceholder="Address Line 2"
          // city
          city="city"
          cityLabel="City"
          cityPlaceholder="City"
          // state
          state="state"
          stateLabel="State/Province/Region"
          statePlaceholder="Select State"
          stateOptions={optionsState}
          stateDisable={values?.nationality?.length <= 0}
          // zipcode
          zipCode="zipCode"
          zipCodeLabel="ZIP/Postal Code"
          zipCodePlaceholder="ZIP/Postal"
          // checkbox
          hasPromo={values.hasPromo}
          onChange={handleCheckboxChange}
          showPromo={true}
          // promoCode
          promoCode="promoCode"
          promoCodeLabel="Promo / Giftcard Code"
          promoCodePlaceholder="Enter Code"
          //password
          passwordLabel="Password"
          passwordPlaceholder="Enter Password"
          password="password"
          // confirmPassword
          confirmPasswordLabel="Confirm Password"
          confirmPasswordPlaceholder="Confirm Password"
          confirmPassword="confirmPassword"
          terms="terms"
          ApplyPromo={ApplyPromo}
          isPromoAppliedMsg={isPromoAppliedMsg}
          // couponCode
          hasCoupon={values.hasCoupon}
          couponCode="couponCode"
          couponCodeLabel="Coupon Code"
          couponCodePlaceholder="Enter Code"
          ApplyCoupon={ApplyCoupon}
          onCouponChange={handleCheckboxChange}
          isCouponAppliedMsg={isCouponAppliedMsg}
          showCoupon={true}
        />
        {/* Billing Details */}

        {!values.hasPromo ? <div className="card2 billingInfo GlobalPaymentIntegrationWrap">
          <div className="billingDetailsToggle">
            <ul>
              <li onClick={() => setActiveTab(1)}>
                <a className={activeTab == 1 && "active"}>
                  Pay Using Debit / Credit Cards
                </a>
              </li>
              <li onClick={() => setActiveTab(2)}>
                <a className={activeTab == 2 && "active"}>
                  Pay Using Other Options
                </a>
              </li>
            </ul>
          </div>

          <div
            id="cardPaymentOption"
            className={`cardPaymentOption tabContent ${activeTab === 1 ? "activeTab" : ""
              }`}
          >
            {/* <Elements stripe={stripe}>
              <CheckoutForm ref={childRef} setFieldValue={setFieldValue} />
            </Elements> */}
            <div className="wallet-options-same-height">
              <BillingDetails
                formikProps={formikProps}
                numberCustomChange={numberCustomChange}
                billingDetails={
                  <div className="sectionTitle">
                    <h2>Billing Details</h2>
                  </div>
                }
                // Card Holder Name
                cardHolderName="cardHolderName"
                cardHolderNameLabel="Card Holder Name"
                cardHolderNamePlaceholder="Card Holder Name"
                // Card Number
                cardHeadingLabel="Enter Card Details"
                credit_number="credit_number"
                cardImage={creaditCardImage}
                cardNumberPlaceholder="Card Number"
                // mmyy
                mmyy="mmyy"
                mmyyPlaceholder="MM/YY"
                // cvv
                security_code="security_code"
                cvvPlaceholder="CVV"
              />
            </div>
            <div className="d-flex checkBoxWrap">
              <TermsCheckbox
                name="terms"
                id="terms"
                value={values.terms}
                onChange={(e) => {
                  handleCustomChange(formikProps, "terms", e.target.checked);
                }}
                error={errors.terms}
                touched={touched.terms}
                onBlur={handleBlur}
                MembershipNonrefundContent={true}

              />
              {/* <div className="buttonWrap">
                <div className="desktopViewDisplayNone">
                  <h4>
                    <strong>$32.50</strong>/ per month
                  </h4>
                  <h4>($195.00 total at checkout)</h4>
                  
                </div>
                <button className="btn" type="submit" id="sbumit-button">
                  <img src={lockImage} /> Pay Now
                </button>
              </div> */}

              <div className="buttonWrap">
                <div className="desktopViewDisplayNone">
                  <h4>
                    Amount Due Today:{" "}
                    <strong>&nbsp;${finalCheckoutPrice}</strong>
                  </h4>
                </div>

                <button type="submit" className="walletPayNow btn d-block">
                  <img src={lockImage} />
                  Pay Now
                </button>
              </div>
            </div>
          </div>

          <div
            id="walletPaymentOption"
            className={`tabContent WalletPaymentOption  ${activeTab === 2 ? "activeTab" : ""
              }`}
          >
            <div className="walletOptionWrap ">
              <div className="wallet-options-same-height walletWrapper">
                <div>
                  <ul>
                    {/* <li>
                      <div className="checkBoxContent">
                        <label
                          for="paypal-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="paypal-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={paypal} alt="wallet icon" />
                            </div>
                            <h6>PayPal</h6>
                          </div>
                        </label>
                      </div>
                    </li> */}
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="apple-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="apple-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={applePay} alt="wallet icon" />
                            </div>
                            <h6>Apple Pay</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="Google-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="Google-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={googlePay} alt="wallet icon" />
                            </div>
                            <h6>Google Pay</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dflex checkBoxWrap">
                <TermsCheckbox
                  name="terms"
                  id="terms"
                  value={values.terms}
                  onChange={(e) => {
                    handleCustomChange(formikProps, "terms", e.target.checked);
                  }}
                  onBlur={handleBlur}
                  MembershipNonrefundContent={true}

                />

                <div className="buttonWrap">
                  <div className="desktopViewDisplayNone">
                    <h4>
                      Amount Due Today:{" "}
                      <strong>&nbsp;${finalCheckoutPrice}</strong>
                    </h4>
                  </div>

                  <button type="submit" className="walletPayNow btn d-block">
                    <img src={lockImage} />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> : <div className="card2 billingInfo GlobalPaymentIntegrationWrap">
          <div
            id="cardPaymentOption"
            className={`cardPaymentOption tabContent ${activeTab === 1 ? "activeTab" : ""
              }`}
          >
            {/* <Elements stripe={stripe}>
              <CheckoutForm ref={childRef} setFieldValue={setFieldValue} />
            </Elements> */}
            <div className="d-flex checkBoxWrap">
              <TermsCheckbox
                name="terms"
                id="terms"
                value={values.terms}
                onChange={(e) => {
                  handleCustomChange(formikProps, "terms", e.target.checked);
                }}
                error={errors.terms}
                touched={touched.terms}
                onBlur={handleBlur}
              />
              {/* <div className="buttonWrap">
                <div className="desktopViewDisplayNone">
                  <h4>
                    <strong>$32.50</strong>/ per month
                  </h4>
                  <h4>($195.00 total at checkout)</h4>
                  
                </div>
                <button className="btn" type="submit" id="sbumit-button">
                  <img src={lockImage} /> Pay Now
                </button>
              </div> */}

              <div className="buttonWrap">
                <div className="desktopViewDisplayNone">
                  <h4>
                    Amount Due Today:{" "}
                    <strong>&nbsp;${finalCheckoutPrice}</strong>
                  </h4>
                </div>

                <button type="submit" className="walletPayNow btn d-block">
                  <img src={lockImage} />
                  Pay Now
                </button>
              </div>
            </div>
          </div>

          <div
            id="walletPaymentOption"
            className={`tabContent WalletPaymentOption  ${activeTab === 2 ? "activeTab" : ""
              }`}
          >
            <div className="walletOptionWrap ">
              <div className="dflex checkBoxWrap">
                <TermsCheckbox
                  name="terms"
                  id="terms"
                  value={values.terms}
                  onChange={(e) => {
                    handleCustomChange(formikProps, "terms", e.target.checked);
                  }}
                  onBlur={handleBlur}
                />

                <div className="buttonWrap">
                  <div className="desktopViewDisplayNone">
                    <h4>
                      Amount Due Today:{" "}
                      <strong>&nbsp;${finalCheckoutPrice}</strong>
                    </h4>
                  </div>

                  <button type="submit" className="walletPayNow btn d-block">
                    <img src={lockImage} />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </form>
    );
  };

  return (
    <>
      <div
        className={
          isHeaderSticky
            ? "siteBg checkoutPageWrap  MembershipCheckoutPageWrap header-sticky"
            : "siteBg checkoutPageWrap MembershipCheckoutPageWrap"
        }
      >
        <div className="pageWrap pageWrapInner ">
          <div className="" style={{ minHeight: "auto" }}>
            <div className="fixedHeader">
              <Header headerName={true} />
            </div>
          </div>

          <section className="mainContent">
            <Scrollbars
              onScrollFrame={onScrollFrame}
              autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll changeHeightPageInternalScroll"
            >
              <div className="container  pageInternalScrollTopBottomPadding pageInternalScrollTopPadding">
                <div className="checkoutWrapper">
                  <div className="row">
                    <div className="col leftContent">
                      <Formik
                        initialValues={initialValues}
                        innerRef={formikRef}
                        onSubmit={handleSubmit}
                        handleSubmit={handleSubmit}
                        validationSchema={PremiumCheckoutValidation}
                        validateOnBlur={false}
                      >
                        {renderForm}
                      </Formik>
                    </div>
                    <div className="col rightContent">
                      <div className="infoBox">
                        <div
                          className="membership-content"
                          style={backgroundImage}
                        >
                          <div className="logo-image">
                            <LogoWhite src={logo} />
                          </div>
                          <div className="planName">Premium Membership</div>
                          <p>(6 Month)</p>
                        </div>
                      </div>
                      <div
                        className={
                          !featureDropdown ? "features" : "features open"
                        }
                      >
                        {/* Plan Info List */}
                        <ul>
                          {arrayList.map((list) => {
                            return <li key={list}>{list}</li>;
                          })}
                        </ul>

                        <p className="mobileViewDisplayNone">
                          * Required for Sign Up and Bookings
                        </p>
                        <div className="priceInfo">
                          <h6>
                            Amount Due Today:{" "}
                            <strong>${finalCheckoutPrice}</strong>
                          </h6>
                        </div>
                      </div>
                      <div
                        className={
                          !featureDropdown
                            ? "desktopViewDisplayNone toggleButtons"
                            : "desktopViewDisplayNone toggleButtons open"
                        }
                        onClick={handleDropdown}
                      >
                        <h6>
                          <span className="show-features">Show Features</span>
                          <span className="hide-features">Hide Features</span>
                          <Icon iconName="fa fa-angle-down" />
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </section>
          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />
        </div>
      </div>
    </>
  );
}

export default MembershipPremiumCheckout;

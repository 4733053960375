import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import reducers from "./Reducers/index";
import { throttle } from "lodash";

const storeLocalStorageKey = "currencyAndLanguage";

const loadStore = () => {
  try {
    const serializedStore = window.localStorage.getItem(storeLocalStorageKey);
    if (serializedStore === null) {
      return undefined;
    }
    return JSON.parse(serializedStore);
  } catch (err) {
    return undefined;
  }
};

const saveStore = (store) => {
  const serializedStore = JSON.stringify(store);

  window.localStorage.setItem(storeLocalStorageKey, serializedStore);
};

export const clearPersistedStore = () => {
  window.localStorage.removeItem(storeLocalStorageKey);
};

const persistedStore = loadStore();


const store = createStore(
  reducers,
  persistedStore,
  composeWithDevTools(applyMiddleware(thunk))
);

export const savePersistedStore = () => {
  saveStore({
    currencyLanguageReducer: store.getState().currencyLanguageReducer,
  });
};

// throttle saving of persisted store to avoid doing it too often
store.subscribe(
  throttle(() => {
    savePersistedStore();
  }, 1000)
);

export default store;
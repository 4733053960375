import React, {useRef } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { StyledDateRangePickerWrapper } from "../StyledComponents/StyledComponent";

function DateRangePickerComponent(props) {
  // Styling wrapper of date range picker component
  const pickerRef = useRef(null);
  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  const {
    startDatePlaceholderText,
    endDatePlaceholderText,
    startDateId,
    endDateId,
    startDate,
    endDate,
    focused2,
    setFocused2,
    isOutsideRange,
    onDatesChange,
    orientation,
    isDayBlocked,
    openDirection,
    numberOfMonths
  } = props;

  // const [focused, setfocused] = useState(isDateResponsive || null);
  return (
    <StyledDateRangePickerWrapper>
      <DateRangePicker
        ref={pickerRef}
        startDate={startDate}
        startDateId={startDateId}
        endDate={endDate}
        endDateId={endDateId}
        onDatesChange={onDatesChange}
        focusedInput={focused2}
        onFocusChange={(focusedInput) => {
          if (focused2 == null) {
            setFocused2("startDate")
          } else {
            setFocused2(focusedInput)
          }
        }}
        customInputIcon={
          <span className="startDate">
            <i className="icon-invitation" />
          </span>
        }
        customArrowIcon={
          <>
            <div className="icon-arrow" />
            <span className="endDate">
              <i className="icon-invitation" />
            </span>
          </>
        }
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        displayFormat={dateDisplayFormat}
        orientation={orientation}
        readOnly={true}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        numberOfMonths={numberOfMonths || 2}
        openDirection={openDirection ? openDirection : "down"}
      />
    </StyledDateRangePickerWrapper>
  );
}

export default DateRangePickerComponent;

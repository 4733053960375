import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hotelActions } from "../../Stores/Actions";
import CarDetails from "./CarDetails";
import FllightDetails from "./FllightDetails";
import HotelDetails from "./HotelDetails";

function Upcoming() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(hotelActions.getUpcomingBookings("upcoming"));
  // }, []);

  const upComingBookingData = useSelector(
    (state) => state.hotelReducer.userBookings
  );

  return upComingBookingData?.length > 0 ? (
    <div className="mainWrap booking">
      {upComingBookingData &&
        upComingBookingData.map((item, index) => {
          if (item.booking_type === "hotel" || item.booking_type === "weeks") {
            return <HotelDetails {...item} status={"UPCOMING"} key={`${index}hotel`} />;
          } else if (item.booking_type === "flight") {
            return (
              <FllightDetails
                {...item}
                status={"UPCOMING"}
                key={`${index}flight`}
              />
            );
          } if (item.booking_type === "car") {
            return <CarDetails item={item} status={"UPCOMING"} key={`${index}car`} />;
          }
        })
        
        }
    </div>
  ) : (
    <div className="not-found-booking">
      <h2>No Bookings</h2>
      <p>You have no upcoming bookings.</p>
      <p>Start your amazing journey with a new booking.</p>
      <Link to={"/landing"} className="btn">Back To Home</Link>
    </div>
  );
}

export default Upcoming;

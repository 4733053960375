import React from "react";
import Amenities from "../../../Component/Hotel/Amenities";
import ReviewBar from "../../../Component/Hotel/ReviewBar";

function Overview({ ref,amenities, reviews,  scrollToView }) {
  return (
    <div  ref={ref} id="overview" className="amenitiesBox">
      <div className="row">
        <div className="col leftContent">
          <h3>Top Amenities</h3>
          <Amenities  amenities={amenities}  scrollToView={scrollToView}/>
        </div>
        {reviews?.review_count!=0  && <div className="col rightContent">
          <ReviewBar reviews={reviews}  scrollToView={scrollToView}/>
        </div>
        }
      </div>
    </div>
  );
}

export default Overview;

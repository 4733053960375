import React from "react";
import Select from "react-select";

//Select option input Field

function SelectFieldOption(props) {
  const {
    label2,
    label,
    options,
    onChange,
    name,
    id,
    value,
    placeholder,
    formikProps,
    className,
    disabled,
    touched,
    error,
    countrySelect,
    formName,
    labelClassName,
    isSearchable,
    onBlur,
    onCustomChange,
  } = props;

// console.log("omkar",name,disabled);
  const defaultValue = (options, value) => {
    if (onChange) {
      return options ? options.filter((option) => option.value === value) : "";
    } else {
      return value;
    }
  };
  return (
    <div className={className ? className : "col cars-col dropdown"}>
      {label && <h5 className={labelClassName}>{label}</h5>}
      {label2 && (
        <label className="labelStyle select-user-label" htmlFor="">
          {label2}
        </label>
      )}
      <div className="selectWrap">
        <Select
          id={id}
          name={name}
          options={options}
          onChange={(val) => {
            if (onChange) {
              onChange(formikProps, name, val.value);
            }
            if (onCustomChange) {
              onCustomChange(formikProps, name, val);
            }
            if (name == "nationality" && formName !== "car-checkout") {
              countrySelect(val);
            }
          }}
          value={defaultValue(options, value)}
          placeholder={placeholder}
          isDisabled={disabled}
          onBlur={onBlur}
          isSearchable={isSearchable}
          //  menuShouldScrollIntoView={false}
        />
      </div>
      {error && touched && (
        <div className="inputStyle error-messages">{error}</div>
      )}
    </div>
  );
}

export default SelectFieldOption;

import React, { useState,useEffect } from "react";
import { isEmpty } from "lodash";
import PersonLogo from "../../Assets/img/person.png";
import sqftIcon from "../../Assets/img/maximize.svg";
import NoSmokeImg from "../../Assets/img/no-smoking.svg";
import SmokeImg from "../../Assets/img/smoking.svg";
import BedImg from "../../Assets/img/bed.png";
import RoomPriceInfo from "./RoomPriceInfo"; 
import RoomDetaisModal from "../../Views/HotelDetail/Rooms/RoomDetaisModal";
import Extras from "./Extras";
import Button from "../FormComponent/Button";
import RoomDetails from "./RoomDetails";

function RoomCard({
  images,
  group_name,
  packages,
  hotel_id,
  currencySymbol,
  currencyCode,
  isRefundable,
  containerIndex,
  setShowUpgradeModal
}) {
  const [openModal, setOpenModal] = useState(false);

  const [selectedFlagPrice, setSelectedFlagPrice] = useState(packages[0]?.boards?.length > 0 ? packages[0]?.boards[0] : []);
  // console.log(packages[0]);
  const [selectedAdditionalPrice, setSelectedAdditionalPrice] = useState(packages[0]?.additional_info?.length > 0 ? packages[0]?.additional_info : []);

  const static_data = !isEmpty(packages) ? packages[0].static_data?.area?.square_feet : null;

  const isSmokingAllowed = !isEmpty(packages)
    ? packages[0].smoking_flag
    : false; 
  const occupancy =
    !isEmpty(packages) && !isEmpty(packages[0].occupancy)
      ? packages[0].occupancy.adult
      : 0;
  const bedType = !isEmpty(packages) ? packages[0].bed_type : "";
 

  const [currentSavings ,setCurrentSavings] = useState(null)
 
  const updatePrice = (flag) => {
    const filteredBoard = packages[0]?.boards?.filter((res, id) => {
      return res.key == flag
    })

    setSelectedFlagPrice(filteredBoard.length > 0 ? filteredBoard[0] : {})
  };

  return (
    <div id={`roomIndex_${containerIndex}`} className="col leftContent">
      <div className="imageContent">
        <div className="mobileViewDisplayNone">
          <div className="row no-gutters heading-wrap">
            <div className="col heading-group">
              <div className=" heading">
                <h3>{group_name}</h3>
                <Button
                  className="room-details-show-popup"
                  type="button"
                  onClick={() => setOpenModal(!openModal)}
                >
                  Room Photos
                </Button> 
              </div>
            </div>
            <div className="col room-status-type">
              <div className="smoking-status">
                <img src={isSmokingAllowed ? SmokeImg : NoSmokeImg} />{" "}
                <span>Non Smoking</span>
              </div>
              <div className="room-types">
                <h5 className={isRefundable ? "btn-refundable" : "btn-non-refundable"}>
                  {isRefundable ? "Refundable" : "Non Refundable"}
                </h5>
              </div>
            </div>
            <div className="col heading-group2">
              {selectedFlagPrice?.available_rooms && selectedFlagPrice?.available_rooms !== "" && <div className="availableRoomsWrap mobileViewDisplayNone">
                <h4 className="availableRooms">
                  We have {selectedFlagPrice?.available_rooms} left
                </h4>
              </div>}
              <div className="savings-heading">
                {(currentSavings > 0 && currentSavings) && <p>{currentSavings}% Savings</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <RoomDetails
            images={packages?.[0]?.thumbnails_large?.length ? packages?.[0]?.thumbnails_large : packages?.[0]?.thumbnails_small?.length ? packages?.[0]?.thumbnails_small : images}
            setOpenModal={setOpenModal}
            openModal={openModal}
            occupancy={occupancy}
            bedType={bedType}
            isSmokingAllowed={isSmokingAllowed}
            availableRooms={selectedFlagPrice?.available_rooms}
            isRefundable={isRefundable}
            savingPercent={currentSavings}
            group_name={group_name}
            static_data={static_data}
          />

          <div className="col content">
            {packages[0]?.boards?.length > 0 &&
              <Extras
              containerIndex={containerIndex}
                boards={packages[0]?.boards}
                updatePrice={updatePrice}
                currencySymbol={currencySymbol}
              />
            }
          </div>
          {selectedFlagPrice?.price_detail &&
            <RoomPriceInfo
              {...packages[0]}
              roomId={selectedFlagPrice?.room_no}
              hotel_id={hotel_id}
              roomNo={packages[0].id}
              price={selectedFlagPrice?.price_detail}
              currencySymbol={currencySymbol}
              currencyCode={currencyCode}
              isRefundable={isRefundable}
              setCurrentSavings={setCurrentSavings}
              board_cancellation_policy = {selectedFlagPrice?.cancellation_policy}
              additional_info = {selectedAdditionalPrice}

              setShowUpgradeModal={setShowUpgradeModal}
            />
          }

        </div>
        <div className="mobileViewDisplayNone">
          <div className="hotelFacilities">
            <div className="sq-ft-wrap">
              {static_data &&  <h4 className="sq-ft-content"> <img src={sqftIcon} alt="icons" /> {static_data} sq ft</h4> }
              <h4>
                <img src={PersonLogo} alt="icons" />
                <span>Sleeps {occupancy}</span>
              </h4>
            </div>
            {bedType && (
              <h4>
                <img src={BedImg} alt="icons" />
                <span>{bedType}</span>
              </h4>
            )}
          </div>
        </div>
      </div>
       {openModal && (
        <RoomDetaisModal
          isOpen={openModal}
          closeModal={setOpenModal}
          hotelImages={packages?.[0]?.thumbnails_large?.length ? packages?.[0]?.thumbnails_large : packages?.[0]?.thumbnails_small?.length ? packages?.[0]?.thumbnails_small : images}
          group_name={group_name}
          facilities={packages && packages[0]?.amenities}
        />
      )} 
    </div>
  );
}

export default RoomCard;

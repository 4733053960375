import React, { useState, useEffect, useRef } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import TabMenu from "./TabMenu";
import PremiumMemberInvites from "./PremiumMemberInvites";
import CommissionContentInfo from "./CommissionContentInfo";
import CommissionContentVideo from "./CommissionContentVideo";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import DetailsInfoModal from "./DetailsInfoModal";
import ModalAffiliateHome from "../../Component/ModalAffiliateHome";
import memberInviteImg from "../../Assets/img/member-invite-heading.png";
import infoGreykIcon from "../../Assets/img/info-grey-icon.png";
import bookIcon from "../../Assets/img/icon-book.png";
import folderIcon from "../../Assets/img/icon-folder.png";
import greyLogo from "../../Assets/img/grey-logo.png";
import facebookImg from "../../Assets/img/facebook-bg.png";
import twitterImg from "../../Assets/img/twitter-bg.png";
import instagramImg from "../../Assets/img/instagram-bg.png";
import videoImg from "../../Assets/img/video.png";
import bannerImg from "../../Assets/img/Banner-ads.png";
import facebookAdd1Img from "../../Assets/img/facebook-add1.jpg";
import downloadImg from "../../Assets/img/download-grey.png";
import facebookAdd2Img from "../../Assets/img/facebook-add2.jpg";
import facebookAdd3Img from "../../Assets/img/facebook-add3.png";
import facebookAdd4Img from "../../Assets/img/facebook-add4.png";
import facebookAdd5Img from "../../Assets/img/facebook-add5.png";
import facebookAdd6Img from "../../Assets/img/facebook-add6.png";
import infoIcon from "../../Assets/img/info-icon-white.png";
import Scrollbars from "react-custom-scrollbars";
import marketingGuidelinePdf from "../../Assets/affiliate-home/Tripello Brand Guidelines v1.0 2022.pdf";
import TripelloLogosZip from "../../Assets/TripelloLogos.zip";

function Marketing() {
  const scrollPage = useRef(null);
  const [className, setClassName] = useState(0);
  // State for Modal
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  // state for scroll button
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

  // getting scroll button while scrolling down
  //   useEffect(() => {
  //     scrollPage.current.addEventListener("scroll", handleScroll);
  //   }, []);

  //handle Scroll
  const handleScroll = () => {
    if (scrollPage.current.scrollTop >= 300 && !showScrollTopBtn) {
      setShowScrollTopBtn(true);
    }
    if (scrollPage.current.scrollTop < 300) {
      setShowScrollTopBtn(false);
    }
  };
  //jumping to top of the page on button click
  const scrollElementToTop = () => {
    scrollPage.current.scrollTop = 0;
  };
  const handleClass = (id) => {
    setClassName(id);
  };
  // For opening modal
  const handleOpenCloseModal = () => {
    if (!openModal) {
      setOpenModal(!openModal);
    } else {
      setOpenModal(false);
    }
  };

  // For opening modal
  const handleOpenCloseModal2 = () => {
    if (!openModal2) {
      setOpenModal2(!openModal2);
    } else {
      setOpenModal2(false);
    }
  };

  // // For closing modal
  // const handleCloseModal2 = () => {
  // 	setOpenModal2(false);
  // };

  //overview content list
  const oveviewContentList = [
    {
      title: "Tripello Academy",
      image: folderIcon,
    }
  ];

  // commission tab array list
  const commissionTabList = [
    {
      id: 0,
      heading: "Facebook",
      href: "#facebook",
      image: facebookImg,
    },
    {
      id: 1,
      heading: "Twitter",
      href: "#twitter",
      image: twitterImg,
    },
    {
      id: 2,
      heading: "Instagram",
      href: "#instagram",
      image: instagramImg,
    },
    {
      id: 3,
      heading: "Videos",
      href: "#video",
      image: videoImg,
    },
    {
      id: 4,
      heading: "Banners",
      href: "#bannerAds",
      image: bannerImg,
      className: "anchor-tab",
    },
  ];

  //comission array list
  const comissionArrayList = [
    {
      id: 0,
      idName: "facebook",
      heading1: "Facebook — Ads",
      heading2: "Facebook Video Ads",
    },
    {
      id: 1,
      idName: "twitter",
      heading1: "Twitter — Ads",
      heading2: "Twitter Video Ads",
    },
    {
      id: 2,
      idName: "instagram",
      heading1: "Instagram — Ads",
      heading2: "Instagram Video Ads",
    },
    {
      id: 3,
      idName: "videos",
      heading1: "Videos",
      heading2: "Videos",
    },
    {
      id: 4,
      idName: "bannerAds",
      heading1: "Banner — Ads",
    },
  ];

  return (
    <div className="siteBg membersRewardsPageWrap marketingDashboard dashboard-pages">
      <div className="pageWrap pageWrapInner">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true}/>
        </div>
        <section className="mainContent">
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container marketing-page-wrapper common-wrapper">
              <TabMenu infoIcon={infoIcon} modalOpen={handleOpenCloseModal} />
              <div className="overview-content-wrapper">
                <PremiumMemberInvites
                  heading="Marketing Resources"
                  memberInviteImg={memberInviteImg}
                  para={
                    <>
                      Here you will find some tools and marketing collateral to
                      support your marketing efforts. We have compiled <br />
                      Social Media optimized imagery that you can utilize in
                      combination with your personalized invitation links.
                      <br /> In addition, we offer an Overview of Tripello,
                      Guidelines, and the Gateway to the Tripello Academy.
                    </>
                  }
                />
                <div className="overview-content">
                  <ul>
                    <li>
                      <a
                        className="tripello-magic-link"
                        onClick={handleOpenCloseModal2}
                      >
                        <img
                          src={infoGreykIcon} // Change button to anchor tag for improving css
                          alt="icon"
                        />
                        Tripello Overview
                      </a>
                    </li>

                   <li>
                      <a
                        className="tripello-magic-link"
                        href={marketingGuidelinePdf}
                        target="_blank"
                      >
                        <img src={bookIcon} alt="icon" />
                        Branding Guidelines
                      </a>
                    </li>  
                   <li>
                      <a
                        className="tripello-magic-link"
                        href={TripelloLogosZip}
                        target="_blank"
                      >
                        <img src={greyLogo} alt="icon" />
                        Tripello Logos
                      </a>
                    </li>  

                    {oveviewContentList.map((list, index) => (
                      <li>
                        <a href="/tripello-academy" key={index}>
                          <img src={list.image} alt="icon" key={index} />
                          {list.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="commission-tabs">
                <div className="commission-content-tabs">
                  <div className="row">
                    {commissionTabList.map((item, index) => (
                      <div className="col" key={index}>
                        <div className="content">
                          <a
                            className={
                              item.id === className
                                ? "anchor-tab active-tab"
                                : "anchor-tab"
                            }
                            onClick={() => handleClass(item.id)}
                          ></a>
                          <div className="heading">
                            <div className="image">
                              <img src={item.image} alt="icon" />
                            </div>
                            <h6>{item.heading}</h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {comissionArrayList.map((list, index) => (
                  <div
                    id={list.idName}
                    className="commission-content-info active-content"
                    key={index}
                    style={
                      list.id === className
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    {!(list.heading1 == "Videos") && (
                      <CommissionContentInfo
                        heading={list.heading1}
                        name={list.idName}
                        type={"images"}
                        downloadImg={downloadImg}
                      />
                    )}  

                    {list.heading2 && (
                      <CommissionContentInfo
                        name={list.idName}
                        type={"videos"}
                        heading={list.heading2}
                        downloadImg={downloadImg}
                      />
                    )} 
                  </div>
                ))}
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
        {showScrollTopBtn ? (
          <div
            id="back-to-top"
            className="back-to-top"
            onClick={scrollElementToTop}
          >
            <button className="btn btn-primary" title="Back to Top">
              <svg
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 31.479 31.479"
                style={{ enableBackground: "new 0 0 31.479 31.479" }}
                width="18px"
                height="18px"
                className="svg lazyloading replaced-svg"
              >
                <path
                  style={{ fill: "#004185" }}
                  d="M26.477,10.274c0.444,0.444,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-8.047-8.047  v26.555c0,0.619-0.492,1.111-1.111,1.111c-0.619,0-1.127-0.492-1.127-1.111V3.813l-8.031,8.047c-0.444,0.429-1.159,0.429-1.587,0  c-0.444-0.444-0.444-1.143,0-1.587l9.952-9.952c0.429-0.429,1.143-0.429,1.571,0L26.477,10.274z"
                ></path>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>
          </div>
        ) : null}
      </div>
      {openModal2 && (
        <ModalAffiliateHome
          openModal={openModal2}
          closeModal={handleOpenCloseModal2}
          className="magic-home-section-popup"
        />
      )}
      {openModal && (
        <DetailsInfoModal
          closeModal={handleOpenCloseModal}
          openModal={openModal}
        />
      )}
    </div>
  );
}

export default Marketing;
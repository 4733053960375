import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import CarInputField from "./CarInputField";
import Checkbox from "../../Component/FormComponent/Checkbox";
import SelectFieldOption from "../../Component/FormComponent/SelectFieldOption";
import CustomPhoneInput from "../../Component/PhoneInput";
import { checkoutCountries, countries } from "../../Data/static.service";

function DriverInfo(props) {
  const {
    showModal,
    formikProps,
    handleCustomChange,
    numberCustomChange,
    title,
    titleOption,
    firstName,
    lastName,
    email,
    phoneNumber,
    nationality,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country,
    countryOption,
    checkBox1,
    checkBox2,
    nameOfStates,
    handleValidateZip,
  } = props;
  const { values, errors, touched, handleChange, handleBlur } = formikProps;

  const [stateOption, setStateOption] = useState([]);

  const countrySelect = (e) => {
    const stateSelect = checkoutCountries
      ?.find((ele) => ele["alpha-2"] === e?.value)
      ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
    if (stateSelect && !isEmpty(stateSelect)) {
      setStateOption(stateSelect);
      formikProps.setFieldValue(
        "state",
        stateSelect.length > 0 && stateSelect[0].value
      );
    } else {
      setStateOption([]);
    }
  };

  useEffect(() => {
    countrySelect({ value: values.nationality });
  }, [values.nationality]);

  const localUser = JSON.parse(window.localStorage.getItem("user"));
  useEffect(() => {
    formikProps.setFieldValue("nationality", localUser?.country);
    formikProps.setFieldValue("country", localUser?.country);
    const stateSelect = checkoutCountries
      ?.find((ele) => ele["alpha-2"] == localUser?.country || "")
      ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
    if (stateSelect && !isEmpty(stateSelect)) {
      setStateOption(stateSelect);
      if (localUser?.user_address?.state) {
        formikProps.setFieldValue("state", localUser?.user_address?.state);
      } else {
        formikProps.setFieldValue("state", stateSelect[0].value);
      }
    } else {
      setStateOption([]);
    }
  }, []);

  return (
    <div className="card2 primaryInfo">
      <h4 className="boxTitle">Driver Information</h4>

      <p className="copyStyle">
        The driver may need to provide a credit card at the rental counter.
        Please review the{" "}
        <button type="button" onClick={showModal}>
          acceptable forms of payment
        </button>{" "}
        for this car rental to determine the specific requirement.
      </p>

      <div className="halfCol mdTitleNname">
        <SelectFieldOption
          name={title}
          label="Title"
          label2="Title"
          placeholder="Title"
          options={titleOption}
          value={values.title}
          error={errors.title}
          touched={touched.title}
          onChange={handleCustomChange}
          onBlur={handleBlur}
          formikProps={formikProps}
          className="col selectStyle profileTitle"
          isSearchable={false}
        />

        <CarInputField
          name={firstName}
          label="First Name"
          placeholder="First Name"
          type="text"
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          onChange={handleChange}
          className="firstNamecol"
          onBlur={handleBlur}
        />

        <CarInputField
          name={lastName}
          label="Last Name"
          placeholder="Last Name"
          type="text"
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          onChange={handleChange}
          className="lastNameCol"
        />
      </div>

      <div className="col3 flexStart mdFullWidth">
        <CarInputField
          name={email}
          type="email"
          label="Email"
          placeholder="Email"
          value={values.email}
          touched={touched.email}
          error={errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        {/* <CarInputField
          name={phoneNumber}
          type="text"
          label="Phone Number"
          placeholder="Phone Number"
          value={values.phoneNumber}
          error={errors.phoneNumber}
          touched={touched.phoneNumber}
          onChange={(val) => {
            numberCustomChange(formikProps, phoneNumber, val.target.value);
          }}
          maxLength={10}
          onBlur={handleBlur}
        /> */}

        <div className={`col inputStyle inputStyle2 `}>
          <CustomPhoneInput
            inputProps={{
              name: "phoneNumber",
              required: true,
            }}
            value={values.phoneNumber}
            onChange={(phoneNumber) => {
              numberCustomChange(formikProps, "phoneNumber", phoneNumber);
            }}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            onBlur={handleBlur}
            label={"Phone Number"}
          />
        </div>
        <SelectFieldOption
          name={nationality}
          label="Nationality"
          label2="Nationality"
          placeholder="Nationality"
          options={countryOption}
          value={values.nationality}
          error={errors.nationality}
          touched={touched.nationality}
          onChange={handleCustomChange}
          onBlur={handleBlur}
          formikProps={formikProps}
          className="col selectStyle"
          formName="car-checkout"
        />
      </div>

      <div className="BSrow gutter15">
        <CarInputField
          name={addressLine1}
          type="text"
          label="Address Line 1"
          placeholder="Address Line 1"
          value={values.addressLine1}
          error={errors.addressLine1}
          touched={touched.addressLine1}
          onChange={handleChange}
          onBlur={handleBlur}
          colClassName="col-6 inputStyle inputStyle2"
        />

        <CarInputField
          name={addressLine2}
          type="text"
          label="Address Line 2"
          placeholder="Address Line 2"
          value={values.addressLine2}
          error={errors.addressLine2}
          touched={touched.addressLine2}
          onChange={handleChange}
          onBlur={handleBlur}
          colClassName="col-6 inputStyle inputStyle2"
        />
      </div>

      <div className="BSrow gutter15">
        <CarInputField
          name={city}
          type="text"
          label="City"
          placeholder="City"
          value={values.city}
          touched={touched.city}
          error={errors.city}
          onChange={handleChange}
          onBlur={handleBlur}
          colClassName="col-6 inputStyle inputStyle2"
        />

        <SelectFieldOption
          name={state}
          type="text"
          label="State/Province/Region"
          placeholder="State/Province/Region"
          value={values.state}
          error={errors.state}
          touched={touched.state}
          onChange={(e) => {
            handleCustomChange;
          }}
          onBlur={handleBlur}
          colClassName="col-6 selectStyle"
          options={stateOption}
          countrySelect={values.state}
          formikProps={formikProps}
        />
      </div>

      <div className="BSrow gutter15">
        <CarInputField
          name={zipCode}
          type="text"
          label="ZIP/Postal Code"
          placeholder="ZIP/Postal Code"
          value={values.zipCode}
          error={errors.zipCode}
          touched={touched.zipCode}
          // onChange={(e) => handleValidateZip(e, formikProps)}
          // onBlur={handleBlur}
          onChange={(e) => {
            if (e.target.value.length > 10) return;
            formikProps.setFieldValue("zipCode", e.target.value);
            handleBlur(e);
          }}
          onBlur={(e) => {
            // handleValidateZip(e, formikProps);
            handleBlur(e);
          }}
          colClassName="col-6 inputStyle inputStyle2"
        />

        <SelectFieldOption
          name={country}
          label="Country"
          label2="Country"
          placeholder="Country"
          options={countryOption}
          value={values.country}
          error={errors.country}
          touched={touched.country}
          onChange={handleCustomChange}
          onBlur={handleBlur}
          formikProps={formikProps}
          className="col-6 selectStyle"
        />
      </div>

      <div className="lightBgLabel">
        <Checkbox
          name={checkBox1}
          value={values.checkBox1}
          onChange={handleChange}
          onBlur={handleBlur}
          formikProps={formikProps}
          message="*Hassle-free pick up: Enter your cell number for a one-time SMS reminder of your confirmation number and counter details. Only applicable at airport locations."
        />
      </div>

      <div className="labelSpace">
        <Checkbox
          name={checkBox2}
          value={values.checkBox2}
          onChange={handleChange}
          formikProps={formikProps}
          message="Yes, I would like to receive exclusive promotions, coupons and discounts."
        />
      </div>
    </div>
  );
}

export default DriverInfo;

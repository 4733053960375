import React, { useState, useRef, useEffect } from "react";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import { has, pull, isEmpty } from "lodash"; 
import { useSelector, useDispatch } from "react-redux";

import { useFilters } from "../../Stores/ConstomHooks/useFilters"
// import { flightActions } from "../../Stores/Actions/flightActions";
 
export const FlightAirlinesFilter = ({
  filterOptions,
  alliancesOptions,
  // filters,
  // setFilters,
  searchFlight,
  disable
}) => {
  const [showOption, setshowOption] = useState(false);
  const filterRef = useRef(null);
  const dispatch = useDispatch()

  const [filters, setFilters, removeFilter, appliedFiltersList] = useFilters("flightFilters")

  const currentFilters = useSelector((state) => state.flightReducer?.flightFilters);

  // const flightSearchData = useSelector(
  //   (state) => state.flightReducer?.flightSearchData
  // ); 
  // const currentfilters = flightSearchData?.request?.filters;

  const toggleCloseFilter = () => {
    setshowOption(false);
  };
  useOutsideHandler(filterRef, showOption, toggleCloseFilter);
  const [isAllselected, setisAllselected] = useState(false);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (filters?.airlines?.length == filterOptions?.length) {
      setisAllselected(true);
    } else {
      setisAllselected(false);
    }
  }, [filters]);

  const SelectAllAirlines = (val) => {
    let temp = filters;
    delete temp.alliances;
    let allAirlines = filterOptions?.map((item) => {
      return item.value;
    });
    if (val) { 
      setFilters("airlines",allAirlines)
      // removeFilter("alliances")
    } else { 
      removeFilter("airlines")
    }
    setisAllselected(val);
  };

  useEffect(() => {
    if (filterOptions?.length > 0 && status) {
      // SelectAllAirlines(true);
      setisAllselected(true);
      setStatus(false);
      let allAirlines = filterOptions?.map((item) => {
        return item.value;
      });
      setFilters("airlines",allAirlines)
    }
  }, [filterOptions]);

  // useEffect(() => {
  //   if(currentfilters?.airlines?.length > 0){
  //     setFilters("airlines",currentfilters?.airlines)
  //   }
  //   if(currentfilters?.airlines?.length > 0){
  //     setFilters("airlines",currentfilters?.airlines)
  //   }
  //   if (currentfilters?.airlines?.length < 1 || !currentfilters?.airlines) {
  //     // SelectAllAirlines(true);
  //     // setisAllselected(true);
  //   }
    
  // }, [currentfilters]);

  const UnCheckAll = (type, value) => {
    if (has(filters, type)) {
      const newValues = filters[type].filter((item) => {
        return item == value;
      });
      setisAllselected(false);
      setFilters([type],newValues);
    }
  };

  const ClearFilter = () => {
    let newFilters = filters;
    const flightFilters = newFilters;
    delete flightFilters["airlines"];
    delete flightFilters["alliances"];
    setFilters({ ...flightFilters });
    searchFlight();
    setisAllselected(false);
    // setshowOption((prev) => !prev);
  };
 

  function handleFilterChange(e, value, type) {
    // -----------alliances-------
    if (e.target.checked && type == "alliances") {
      removeFilter("airlines")
      setFilters("alliances", [value],e.target.checked)  
    }
    if (!e.target.checked && type == "alliances") {
      setFilters("alliances", alliances, e.target.checked)
      if (filters?.alliances?.length < 1) {
        removeFilter("alliances")
      }
    }

    // -----------airline-------
    let airlinesFilter = filters?.airlines || [];

    if (e.target.checked && type == "airlines") { 
      airlinesFilter = [...airlinesFilter, value] 
      setFilters("airlines", airlinesFilter,e.target.checked)
      // // setFilters("alliances", airlinesFilter) 
      // const temp =  {...filters}
      // delete temp.alliances
      // temp.airline = 
      // dispatch(flightActions.updateFlightFilter(temp))
    }
    if (!e.target.checked && type == "airlines") {
      let temp = filters?.airlines?.filter(item => item !== value)
      setFilters("airlines", temp,e.target.checked)
      if (filters?.airlines?.length < 1) {
        removeFilter("airlines")
      }
    }
 
  }
  function handleApply() {
    searchFlight();
    setshowOption((prev) => !prev);
  }

  return (
    <>
      <div
        ref={filterRef}
        className={`filterDropdownWrap airlines-wrap ${showOption && "openedOption"
          }`}
      >
        <div className="flterOption" onClick={() => !disable && setshowOption(!showOption)}>
          <span>Airlines</span>
          <span className="fa fa-angle-down"></span>
        </div>
        <div className="filterDropdown">
          <div className="showMoreWrap">
            <div className="connecting-airport-content">
            <div className="custom-switcher">
                <h4>Select all airlines </h4>
                <div className="switch-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isAllselected}
                      onChange={(e) => SelectAllAirlines(!isAllselected)}
                    />
                    <span className="switcher round"></span>
                  </label>
                </div>
              </div> 
              <div className="checkboxes-wrapper">
                <h4>Alliances</h4>
                {alliancesOptions?.map((item, index) => {
                  return (
                    <>
                      <label className="customCheckbox">
                        <input
                          type="checkbox"
                          name={item.name}
                          value={item.value}
                          checked={
                            filters["alliances"] &&
                              filters["alliances"]?.find(
                                (opt) => opt === item.value
                              ) !== undefined
                              ? "checked"
                              : false
                          }
                          onChange={(e) =>
                            handleFilterChange(e, item.value, "alliances")
                          }
                        />
                        <span>{item.label}</span>
                        {/* {filters["alliances"]?.filter(
                          (opt) => opt === item.value
                        )?.length > 0 && (
                            <button
                              type="button"
                              onClick={() => UnCheckAll("alliances", item.value)}
                              className="btn-only"
                            >
                              Only
                            </button>
                          )} */}
                      </label>
                    </>
                  );
                })}
              </div>
              <div className="checkboxes-wrapper">
                <h4>Airlines</h4>

                {filterOptions?.map((item, index) => {
                  return (
                    <>
                      <label className="customCheckbox">
                        <input
                          type="checkbox"
                          name={item.name}
                          value={item.value}
                          checked={
                            filters["airlines"] &&
                              filters["airlines"]?.find(
                                (opt) => opt === item.value
                              ) !== undefined
                              ? "checked"
                              : false
                          }
                          onChange={(e) =>
                            handleFilterChange(e, item.value, "airlines")
                          }
                        />
                        <span>{item.label}</span>
                        {filters["airlines"]?.filter((opt) => opt === item.value)
                          ?.length > 0 && (
                            <button
                              type="button"
                              onClick={() => UnCheckAll("airlines", item.value)}
                              className="btn-only"
                            >
                              Only
                            </button>
                          )}
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="btnsCnt-wrap">
            <div className="btnsCnt">
              <button className="borderBtn" onClick={handleApply}>
                Apply
              </button>
            </div>
            <div className="btnsCnt">
              <button type="button" onClick={ClearFilter} className="btn-clear">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


import moment from "moment";
import React, { Fragment, useState } from "react";
import FlghtStartEndTime from "./FlghtStartEndTime";
import legroom from "../../Assets/img/icons/legroom.svg";
import wifi from "../../Assets/img/icons/wifi.svg";
import usbOutlets from "../../Assets/img/icons/usb-outlets.svg";
import videoIcon from "../../Assets/img/icons/video.svg";
import {Tooltip} from "antd";
import { getHoursAndMin } from "../../Util/util";

function FlightSubDetails({ flightLegs, totalJourneyDurationInHours, }) {
  const [baggage, setBaggage] = useState(false);

  const timeConvert = (num) => {
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + "h " + rminutes + "m";
  };

  let endTime =
    flightLegs?.flightLegs[flightLegs?.flightLegs?.length - 1]?.arrivalDateTime;

  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const timeFormat = currentGeoLocation === "US" ? "hh:mm a" : "HH:MM";

  return (
    <div className="flightSubDetails">
      <div className="flightDetailsRow">
        <>
          {flightLegs?.flightLegs?.map((item, index) => {
            return (
              <Fragment key={index}>
                <div className="flightrow row">
                  <div>
                    <div className="innerRow row1">
                      <div className="colLeft flightLogo2">
                        <Tooltip title={item?.marketingAirlineName} placement="bottom">
                          <img src={item?.marketingAirlineLogoUrl} alt="" />
                        </Tooltip>
                      </div>
                      <div className="colRight leftSpace">
                        <span className="flightID">{`${item?.marketingAirlineName} ${item?.marketingAirlineCode}${item?.marketingAirlineFightNumber}`} {item?.equipment && "| " + item?.equipment }</span>
                      </div>
                    </div>
                  </div>
                  <div className="flightRowWrap">
                    <div className="innerRow row2">
                      <div className="colLeft">
                        <div className="flightDuration2">
                          <span className="icon-timer"></span>
                          {timeConvert(item?.duration)}
                        </div>
                      </div>
                      <div className="colRight leftSpace">
                        <div className="flightStartEndPoints">
                          <FlghtStartEndTime
                            className="flightStartPoint"
                            // time={`${moment(item?.departureDateTime).format(
                            //   timeFormat
                            // )}`}
                            time={`${getHoursAndMin(item?.departureDateTime)}`}
                            airport={`${item?.departureAirportCode} ${item?.departureAirportName}`}
                          />

                          <FlghtStartEndTime
                            className="flightEndPoint"
                            // time={`${moment(item?.arrivalDateTime).format(
                            //   timeFormat
                            // )} `}
                            time={`${getHoursAndMin(item?.arrivalDateTime)} `}
                            airport={`${item?.arrivalAirportCode} ${item?.arrivalAirportName} `}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="flightFacilities col">
                      <ul>
                        <li>
                          <img src={legroom} alt="icon11" />{" "}
                          <span>
                            {item?.amenities?.legRoom?.map((ele) => ele)}
                          </span>
                        </li>
                        <li>
                          <img src={wifi} alt="icon1" />{" "}
                          <span>
                            {item?.amenities?.connectivity?.map((ele) => ele)}
                          </span>
                        </li>
                        <li>
                          <img src={usbOutlets} alt="icon" />{" "}
                          <span>
                            {item?.amenities?.powerOutlet?.map((ele) => ele)}{" "}
                          </span>
                        </li>
                        <li>
                          <img src={videoIcon} alt="icon" />{" "}
                          <span>
                            {item?.amenities?.entertainment?.map((ele) => ele)}
                          </span>
                        </li>
                      </ul>
                    </div> */}


                  </div>
                </div>
                {flightLegs?.flightLegs?.length - 1 !== index && (
                  <div className="layOverInfo row3">
                    <div className="innerRow">
                      <div className="colLeft">
                        <div className="flightDuration2">
                          <span className="icon-timer"></span>
                          {timeConvert(flightLegs?.stops[index]?.time)}      
                          </div>
                      </div>
                      <div className="colRight">
                        <span className="blueText">
                          Layover {item?.arrivalAirportCode}{" "}
                          {item?.arrivalAirportName}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </>

        <div className="flightKeyValue">
          <div className="keyValWrap">
            <span className="flightKey">Arrives:</span>
            <span className="flightVal">
              {moment(endTime).format("ddd,D MMM YYYY")}
            </span>
          </div>
          <div className="keyValWrap">
            <span className="flightKey">Total Journey Duration:</span>
            <span className="flightVal">{totalJourneyDurationInHours}</span>
          </div>
          <br />
          <div className="keyValWrap">
            <span className="flightKey">Return Policy:</span>
            <span className="flightVal">
              {" "}
              {flightLegs.isRefundable ? "Refundable" : "Non-refundable"}{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightSubDetails;

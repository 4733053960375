import React, { useState } from "react";
import { Link } from "react-router-dom";
import placeholderImage from "../../Assets/img/placeholder-image.png"
import { httpGetAsync } from "../../Util/util"
function HotelImageSection({ id, savings_percentage, image }) {

  const [isImgLoad, setImgLoad] = useState(false)
 

  httpGetAsync(`${image}`, (res) => { setImgLoad(res) })

  return (
    <div className="imgWrap col col-width">
      <Link className="detail-hotel-url" to={`/hotel-detail/${id}`}></Link>
      <div className="bg-image" style={{ backgroundImage: `url('${isImgLoad ? image : placeholderImage}')` }}>
        {savings_percentage == 0 ? (
          " "
        ) : (
          <div className="savings">{savings_percentage}% Savings</div>
        )}
      </div>
    </div>
  );
}

export default HotelImageSection;

import React, { useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import starImg from "../../../Assets/img/yellow-star.png";
import arrowImg from "../../../Assets/img/hotel/ic-arrow-back.png";
import { utilitiesImgForMapView } from "../../../Data/hotelMockData";
import _, { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import {numFormatter} from "../../../Util/util";
import { Link } from "react-router-dom";

const Marker = ({ children }) => {
  return children || null;
};

function MapView({ closeModal, hotelLocation, mobileMap }) {
  const mapRef = useRef();
  const [zoom, setZoom] = useState(5);
  const [bounds, setBounds] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const loading = useSelector((state) => state.commonReducer.loader);
  // Handle open modal

  const latAndLong = hotelLocation.map((ele) => ({
    lat: ele.latitude,
    lng: ele.longitude,
    pricePerNight: ele.origin_price.per_night,
    details: ele,
    id: ele.id,
    hotelImage: ele.thumbnail_image_url,
    hotelName: ele.original_name,
    price: ele.rates.packages[0].final_price,
    starRate: ele.rating,
  }));

  const points = latAndLong.map((data) => ({
    details: data.details,
    type: "Feature",
    properties: {
      cluster: false,
      clusterPrice: data.pricePerNight,
      id: data.id,
      image: data.hotelImage,
      hotel: data.hotelName,
      hotelPrice: data.price,
      starPrice: data.starRate,
    },
    geometry: {
      type: "Point",
      coordinates: [data.lng, data.lat],
    },
  }));

  // get clusters

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 60, maxZoom: 10 },
  });

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng));
    });
    return bounds;
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  const apiIsLoaded = (map, maps, places) => {
    mapRef.current = map;
    const bounds = getMapBounds(map, maps, places);
    map.fitBounds(bounds);
    bindResizeListener(map, maps, bounds);
  };

  // set infowindow of hotel on map,
  const handleActiveMarker = (id) => {
    if (id === activeMarker) {
      return;
    }
    setActiveMarker(id);
  };
  const currencyAndLanguage =
    (window.localStorage.getItem("currencyAndLanguage") &&
      JSON.parse(window.localStorage.getItem("currencyAndLanguage"))) ||
    {};

  const currencySymbol =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency?.symbol || "$";

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <div className="popup-content map-view-popup">
          <div className="box-content">
            <div className="map-model">
              {!mobileMap && (
                <button
                  className="back-to-view"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <span>
                    <img src={arrowImg} />
                    List View
                  </span>
                </button>
              )}
            </div>
            <div
              style={{
                height: "73vh",
                width: "100%",
                position: "relative",
                overflow: "hidden",
                borderRadius: "10px",
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
                defaultCenter={{ lat: 28.70406, lng: 77.102493 }}
                defaultZoom={10}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  // mapRef.current = map;
                  apiIsLoaded(map, maps, latAndLong);
                }}
                // onGoogleApiLoaded={({ map }) => {
                //   mapRef.current = map;
                // }}
                onChange={({ zoom, bounds }) => {
                  setZoom(zoom);
                  setBounds([
                    bounds.nw.lng,
                    bounds.se.lat,
                    bounds.se.lng,
                    bounds.nw.lat,
                  ]);
                }}
                // onClick={() => setActiveMarker(null)}
              >
                {clusters.map((cluster) => {
                  const [longitude, latitude] = cluster.geometry.coordinates;
                  const {
                    cluster: isCluster,
                    point_count: pointCount,
                    clusterPrice: price,
                    id: markerId,
                    image: hotelImg,
                    hotel: hotelName,
                    hotelPrice: hotelPrice,
                    starPrice: starItem,
                  } = cluster.properties;
                  const { details } = cluster;
                  if (isCluster) {
                    return (
                      <Marker lat={latitude} lng={longitude}>
                        <span
                          className="cluster-marker"
                          onClick={() => {
                            const expansionZoom = Math.min(
                              supercluster.getClusterExpansionZoom(cluster.id),
                              60
                            );
                            mapRef.current.setZoom(expansionZoom);
                            mapRef.current.panTo({
                              lat: latitude,
                              lng: longitude,
                            });
                          }}
                        >
                          {`${pointCount} hotels`}
                        </span>
                      </Marker>
                    );
                  }
                  return (
                    <Marker lat={latitude} lng={longitude}>
                      <span
                        // className="single-marker"
                        className={
                          activeMarker === markerId
                            ? "active-single-marker"
                            : "single-marker"
                        }
                        onClick={() => {
                          handleActiveMarker(markerId);
                          mapRef.current.panTo({
                            lat: latitude,
                            lng: longitude,
                          });
                        }}
                      >
                        {currencySymbol}
                        {numFormatter(hotelPrice)}
                      </span>
                      {activeMarker === markerId ? (
                        <div style={{ zIndex: 10000 }}>
                          <div className="map-popup">
                            <div className="d-flex">
                              <div
                                className="item-img"
                                style={{
                                  backgroundImage: `url('${hotelImg}')`,
                                }}
                              ></div>
                              <div className="innerCnt">
                                <div className="rating-info">
                                  <button
                                    className="cancel"
                                    onClick={() => {
                                      setActiveMarker(null);
                                    }}
                                  >
                                    <i className="icon-close" />
                                  </button>
                                  <Link to={`/hotel-detail/${markerId}`}>
                                    <h3 className="cartTitle" title={hotelName}>
                                      {hotelName}
                                    </h3>
                                  </Link>
                                  <div className="topSection dflex justifyStart">
                                    <div className="rating">
                                      {_.range(1, starItem + 1).map(
                                        (ele, index) => {
                                          return (
                                            <img
                                              key={`${index}_stars`}
                                              src={starImg}
                                              alt="yellow-star"
                                              className=""
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                    {/* <div className="ratingComment font14">
                                      <span className="commentCount">
                                        {starItem}
                                      </span>
                                      Very Good
                                    </div> */}
                                  </div>
                                </div>
                                <div className="mobileViewWrapper">
                                  <div className="facility-icon">
                                    {utilitiesImgForMapView &&
                                      utilitiesImgForMapView.map(
                                        (ele, index) => {
                                          return (
                                            <img
                                              src={ele}
                                              alt="icons"
                                              key={`${index}_stars_mob`}
                                            />
                                          );
                                        }
                                      )}
                                    <span className="more-icon">+4</span>
                                  </div>
                                  <div className="price">
                                    <p>From:</p>
                                    <span>{`${currencySymbol}${numFormatter(hotelPrice)}`}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Marker>
                  );
                })}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MapView;

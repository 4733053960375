import { carsConstants } from "../Constants";

const initialState = {
  carsSearchData: null,
  carsError: null,
  carsContractData: null,
  bookingData: null,
  zipData: null,
  zipError: null,
  createCardData:null,
  getBookingData:null,
};

const carsReducer = (state = initialState, action) => {
  switch (action.type) {
    case carsConstants.SEARCH_CARS_REQUEST:
      return { ...state, carsSearchData: null, carsError: null };

    case carsConstants.SEARCH_CARS_SUCCESS:
      return {
        ...state,
        carsSearchData:
          action.response?.data?.getCarResultsV3?.results?.result_list,
        carsError: null,
      };

    case carsConstants.SEARCH_CARS_FAILURE:
      return {
        ...state,
        carsSearchData: null,
        carsError: action.error,
      };

    case carsConstants.CARS_CONTRACT_REQUEST:
      return {
        ...state,
        carsContractData: null,
      };

    case carsConstants.CARS_CONTRACT_SUCCESS:
      return {
        ...state,
        carsContractData: action.response.data.getCarContractRequest.results,
      };

    case carsConstants.CARS_CONTRACT_FAILURE:
      return {
        ...state,
        carsError: action.error,
      };

    case carsConstants.BOOK_CAR_REQUEST:
      return {
        ...state,
        bookingData: null,
      };

    case carsConstants.BOOK_CAR_REQUEST:
      return {
        ...state,
        bookingData: action.response,
      };

    case carsConstants.BOOK_CAR_FAILURE:
      return {
        ...state,
        carsError: action.error,
      };

    case carsConstants.VALIDATE_ZIP:
      return {
        ...state,
      };

    case carsConstants.VALIDATE_ZIP_SUCCESS:
      return {
        ...state,
        zipData: action.response.data,
      };

    case carsConstants.VALIDATE_ZIP_FAILURE:
      return {
        ...state,
        zipError: action.error,
      };

    case carsConstants.RETRIVE_BOOKING_REQUEST:
      return {
        ...state,
        carsBookingData: null
      };

    case carsConstants.RETRIVE_BOOKING_SUCCESS:
      return {
        ...state,
        carsBookingData: action.response.data,
      };

    case carsConstants.RETRIVE_BOOKING_FAILURE:
      return {
        ...state,
        carsBookingDataError: action.error,
      };

    // carte car booking for backoffice 

    case carsConstants.CAR_BOOKING_REQUEST_BACKOFFICE:
      return {
        ...state,
        createCardData: null
      };

    case carsConstants.CAR_BOOKING_BOOKING_SUCCESS_BACKOFFICE:
      return {
        ...state,
        createCardData: action.response.data,
      };

    case carsConstants.CAR_BOOKING_BOOKING_FAILURE_BACKOFFICE:
      return {
        ...state,
        createCardData: action.error,
      };

    // carte car booking for backoffice 

    case carsConstants.GET_BOOKING_REQUEST_BACKOFFICE:
      return {
        ...state,
        getBookingData: null
      };

    case carsConstants.GET_BOOKING_BOOKING_SUCCESS_BACKOFFICE:
      return {
        ...state,
        getBookingData: action.response.data.data,
      };

    case carsConstants.GET_BOOKING_BOOKING_FAILURE_BACKOFFICE:
      return {
        ...state,
        getBookingData: action.error,
      };



    default:
      return state;
  }
};

export default carsReducer;

import React, { Fragment, useRef } from "react";
import { FieldArray } from "formik";
import SelectComponent from "../Select/SelectComponent";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import { childAge } from "../../Stores/Constants/siteConstants/dropdownConstants";
import Button from "../FormComponent/Button";
import IconButton from "../FormComponent/IconButton";
import { Scrollbars } from "react-custom-scrollbars";

export default function AddRoomForm(props) {
  const style1 = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),

    control: (base, state) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
    }),
  };
  const { isOpen, roomObject, handleClose, formikProps,setOpen, isListing } = props;
  const { values } = formikProps;
  const exportRef = useRef(null);
  useOutsideHandler(exportRef, isOpen, setOpen);
  // to add form
  const handleAddFields = (formikProps) => {
    formikProps.setFieldValue("rooms", [
      ...formikProps.values.rooms,
      { ...roomObject },
    ]);
  };
  // to remove form
  const handleRemoveFields = (roomIndex, formikProps) => {
    const newValues = formikProps.values.rooms.filter((obj, index) => {
      if (index === roomIndex) return null;
      return obj;
    });
    formikProps.setFieldValue("rooms", newValues);
  };
  // handle select child age
  const handleSelectChange = (value, fieldName, formikProps) => {
    formikProps.setFieldValue(fieldName, value);
  };

  return (
    // <form onSubmit={onSubmit}>
    <div ref={exportRef} className="form-row">
      {/* inputfields array mapping for re-rendering the room form */}
      <Scrollbars
        autoHeight
        autoHeightMin={120}
        autoHeightMax={234}
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
        className="add-room-scroll"
      >
        <Fragment>
          <FieldArray
            name="rooms"
            render={(arrayHelpers) => {
              return (
                <>
                  {values.rooms.map((room, index) => {
                    return (
                      <div className="dropDownSubWrap" key={`${index}_room`}>
                        <div className="dflex roomHead">
                          <span>Room {index + 1} </span>

                          {index !== 0 && (
                            <Button
                              type="button"
                              onClick={() =>
                                handleRemoveFields(index, formikProps)
                              }
                              icon={"icon-close"}
                            />
                          )}
                        </div>
                        <div className="dflex inWrap">
                          {/* Adult section */}
                          <div className="adultstitle">Adults</div>
                          <div className="dflex addMinusWrap">
                            <Button
                              type="button"
                              className={room.adults <= 1 ? "disable" : ""}
                              onClick={() => {
                                formikProps.setFieldValue(
                                  `rooms[${index}].adults`,
                                  values.rooms[index].adults - 1
                                );
                              }}
                              disabled={room.adults <= 1}
                              icon="minus"
                            />
                            <input
                              type="text"
                              value={values.rooms[index].adults}
                              name={`rooms[${index}].adults`}
                              onChange={() => {}}
                            />
                            <Button
                              className={
                                room.adults + room.child >= 8 ? "disable" : ""
                              }
                              type="button"
                              onClick={() => {
                                formikProps.setFieldValue(
                                  `rooms[${index}].adults`,
                                  values.rooms[index].adults + 1
                                );
                              }}
                              disabled={room.adults + room.child >= 8}
                              icon="icon-add"
                            />
                          </div>
                        </div>
                        <div className="dflex inWrap">
                          {/* Adult section */}
                          <div className="adultstitle">
                            Children
                            <span className="noteText"> (1-17 years)</span>
                          </div>
                          <div className="dflex addMinusWrap">
                            <Button
                              type="button"
                              className={room.child <= 0 ? "disable" : ""}
                              onClick={() => {
                                formikProps.setFieldValue(
                                  `rooms[${index}].child`,
                                  values.rooms[index].child - 1
                                );
                                values.rooms[index].children.pop();
                              }}
                              disabled={room.child <= 0}
                              icon="minus"
                            />
                            <input
                              type="text"
                              value={values.rooms[index].child}
                              name={`rooms[${index}].child`}
                              onChange={() => {}}
                            />
                            <Button
                              className={
                                room.adults + room.child >= 8 ? "disable" : ""
                              }
                              type="button"
                              onClick={() => {
                                formikProps.setFieldValue(
                                  `rooms[${index}].child`,
                                  values.rooms[index].child + 1
                                );
                                formikProps.setFieldValue(
                                  `rooms[${index}].children`,

                                  [
                                    ...values.rooms[index].children,
                                    {
                                      value: 0,
                                      label: "Under 1",
                                    },
                                  ]
                                );
                              }}
                              disabled={room.adults + room.child >= 8}
                              icon="icon-add"
                            />
                          </div>
                        </div>
                        {/* <FieldArray
                                  name={`rooms[${index}].childAge`}
                                  render={(arrayHelpers) => { */}
                        {/* return */}
                        {values.rooms[index].children.map((age, childIndex) => {
                          return <div
                            className="dflex inWrap"
                            key={`${childIndex}_child`}
                          >
                            <div className="adultstitle add-child-age">
                              <div className="add-age">
                                <SelectComponent
                                  menuPlacement={isListing ? "top" : "auto"}
                                  isComponents={false}
                                  styles={style1}
                                  label={"Child " + (childIndex + 1) + " Age"}
                                  name={`rooms[${index}].children[${childIndex}]`}
                                  placeholder={age}
                                  onMenuClose={() => {}}
                                  menuPortalTarget={true}
                                  isSearchable={false}
                                  options={childAge}
                                  onChange={(value) => {
                                    handleSelectChange(
                                      value,
                                      `rooms[${index}].children[${childIndex}]`,
                                      formikProps
                                    );
                                  }
                                }
                                  value={
                                    values.rooms[index].children[childIndex]
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        })}
                      </div>
                    );
                  })}
                </>
              );
            }}
          />
        </Fragment>
      </Scrollbars>

      {/* Add room button */}
      <div className="addRoom">
        <IconButton
          onClick={() => handleAddFields(formikProps)}
          type="button"
          text="Add Room"
          icon="icon-add"
          disabled={formikProps.values["rooms"].length > 7}
          className={formikProps.values["rooms"].length === 8 ? "disable" : ""}
        />
        {/* <i className="icon-add" />
            &nbsp; Add Room */}
      </div>
    </div>
  );
}

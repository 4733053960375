import React, { useEffect, useState, useRef, forwardRef } from "react";
import { hotelDetailMockData } from "../../Data/hotelMockData";
import HotelDetailsTabs from "./HotelDetailsTabs";
import HotelDetailCard from "./HotelDetailCard/HotelDetailCard";
import Overview from "./Overview/Overview";
import Rooms from "./Rooms/Rooms";
import Amenities from "./Amenities/Amenities";
import PropertiesDetails from "./PropertyDetails/PropertiesDetails";
import Reviews from "./Reviews/Reviews";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hotelActions } from "../../Stores/Actions";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HotelWidget from "../HotelSearchResult/HotelWidget";
import { Scrollbars } from "react-custom-scrollbars";
import { convertTime12To24 } from "../../Util/util";
import ExpireRate from "../../Component/ExpireRate/ExpireRate";
import ExpireIcon from "../../Assets/img/icons/Search Expired Icon.png";
import Modal from "react-modal";
import Button from "../../Component/FormComponent/Button";
import blueIcon from "../../Assets/img/close-blue-icon.png";
import {ReactComponent as Crown} from "../../Assets/img/icons/crown.svg";
import {ReactComponent as Arrow} from "../../Assets/img/icons/arrow-right.svg";

function HotelDetail() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(window.localStorage.getItem("user"));
  const storedPayload = window.localStorage.getItem("hotelPayload")
    ? JSON.parse(window.localStorage.getItem("hotelPayload"))
    : JSON.parse(window.localStorage.getItem("mapPayload"));

  const hotelDetails = useSelector((state) => state.hotelReducer.hotelDetails);
  const [galleryImages, setGalleryImages] = useState([]);
  const [hideFilter, setHideFilter] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [toggleFilterDrop, setToggleFilterDrop] = useState(true);
  const [activeTab, setActiveTab] = useState("overview");
  useEffect(() => {
    if(hotelDetails?.static_data?.images){
      var tempArray = []
      hotelDetails?.static_data?.images.map(image => {
        var img = new Image();
        img.onload = function() {
          tempArray.push(image);
        }
        img.src = image;
      });
      setGalleryImages(tempArray)
      
    }
  }, [hotelDetails])
  const scrollRef = useRef(null);

  const currencyAndLanguage =
    window.localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(window.localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const isUS = currentGeoLocation === "US";

  const scrollToView = (idName) => {
    const offsetTop = document.getElementById(idName).offsetTop
    if (window.innerWidth > 991) {
      scrollRef.current.view.scroll({
        top: offsetTop,
        behavior: 'smooth',
      })
    } else {
      if(scrollRef?.current?.view?.scroll){
        scrollRef.current.view.scroll({
          top: offsetTop,
          behavior: 'smooth',
        })
      }
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })
    }
    setActiveTab(idName);
  }


  const initialPayload = {
    rooms: storedPayload?.rooms.length,
    region_id: storedPayload?.region_id,
    check_in_date: storedPayload?.check_in_date,
    check_out_date: storedPayload?.check_out_date,
    children_count: 0,
    city_name: storedPayload?.city_name,
    currency: currency.isoCode,
    home: storedPayload?.home,
    hotel_id: params?.hotel_id,
    locale: storedPayload?.locale,
    onlyLocale: storedPayload?.onlyLocale,
    optional: "amenities,moreDetails,policy,moreRatings",
    preventCache: 0.9299991871126359,
    search_type: storedPayload?.search_type,
    source_market: storedPayload?.source_market,
    token: window.localStorage.getItem("accessToken"),
    type: storedPayload?.search_type,
    nationality: storedPayload?.nationality,
    use_wallets:
      user.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID ? 1 : 0,
  };

  useEffect(() => {

    if (window.localStorage.getItem("hotelPayload")) {
      dispatch(hotelActions.getHotelDetails(initialPayload, params?.hotel_id));
    } else {
      navigate("/landing");
    }

    return () => {
      dispatch(hotelActions.resetHotelDetails());
    };
  }, []);

  function hideFunc() {
    setHideFilter((prev) => !prev);
  }

  const [CountdownStatus, setCountDownStatus] = useState(null)

  const onComplete = () => {
    // setCountDownStatus(Date.now())
    const initialPayload = {
      rooms: storedPayload?.rooms,
      region_id: storedPayload?.region_id,
      check_in_date: storedPayload?.check_in_date,
      check_out_date: storedPayload?.check_out_date,
      children_count: 0,
      city_name: storedPayload?.city_name,
      currency: currency.isoCode,
      home: storedPayload?.home,
      hotel_id: params?.hotel_id,
      locale: storedPayload?.locale,
      onlyLocale: storedPayload?.onlyLocale,
      search_type: storedPayload?.search_type,
      source_market: storedPayload?.source_market,
      token: window.localStorage.getItem("accessToken"),
      type: storedPayload?.search_type,
      nationality: storedPayload?.nationality,
      use_wallets:
        user.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID ? 1 : 0,
    };
    dispatch(hotelActions.getHotelRooms(initialPayload, params?.hotel_id));
  }
  const closeModal = () => {
    setShowUpgradeModal(false);
  }
  const Countdown = () => {
    return <>
      <ExpireRate
        className="expireTimer mobileViewDisplayNone"
        showIcon={true}
        text="Rate will expire"
        time={1200000}
        onComplete={onComplete}
      />
    </>
  }
  const CountDownForMobile = () => {
    return <>
      <ExpireRate
        className="expireTimer desktopViewDisplayNone"
        showIcon={true}
        text="Rate will expire"
        time={1200000}
        onComplete={onComplete}
      />
    </>
  }

  const redirectToUpgrade = () => {
    navigate("/membership-upgrade");
  }
  return (
    <div className="siteBg listingPage  detailPageWrap">
      <div className="pageWrap ">
        <div className="fixedHeader">
          {!hideHeader && <HeaderNavbar hideFunc={hideFunc} useBlueIcon={true}/>}
        </div>
        <section className="mainContent">
          {!hideFilter && (
            <HotelWidget
              toggleFilterDrop={toggleFilterDrop}
              isFromDetailPage={true}
            />
          )}

          {hotelDetails && (
            <Scrollbars
              // autoHeight={`calc(100vh - 110px)`}
              // autoHeight={true}
              ref={scrollRef}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container">
                <HotelDetailCard
                  {...hotelDetailMockData}
                  scrollToView={scrollToView}
                  name={hotelDetails ? hotelDetails?.hotel_data?.name : ""}
                  images={
                    galleryImages.length > 0
                      ? galleryImages
                      : []
                  }
                  heroImage={
                    galleryImages.length > 0
                      ? galleryImages[0]
                      : ""
                  }
                  checkIn={
                    hotelDetails
                      ? 
                      isUS ? hotelDetails?.hotel_data?.check_in_time_format1 :(hotelDetails?.hotel_data?.check_in_time_format1 ? convertTime12To24(hotelDetails?.hotel_data?.check_in_time_format1) : "--")
                      : "" 
                  }
                  checkOut={
                    hotelDetails
                      ? isUS      
                        ? hotelDetails?.hotel_data?.check_out_time_format1 :(hotelDetails?.hotel_data?.check_out_time_format1 ? convertTime12To24(hotelDetails?.hotel_data?.check_out_time_format1) : "--")
                      : ""
                  }
                  address={hotelDetails ? hotelDetails?.hotel_data?.address : ""}
                  addressObj={
                    hotelDetails
                      ? hotelDetails?.hotel_data?.address_components
                      : {}
                  }
                  contactNo={hotelDetails ? hotelDetails?.hotel_data?.phone : ""}
                  themes={
                    hotelDetails && hotelDetails?.hotel_data?.themes
                      ? hotelDetails?.hotel_data?.themes
                      : []
                  }
                  rating={hotelDetails ? hotelDetails?.hotel_data?.rating : 0}
                  latitude={
                    hotelDetails && hotelDetails?.hotel_data?.latitude
                      ? hotelDetails?.hotel_data?.latitude
                      : 0
                  }
                  longitude={
                    hotelDetails && hotelDetails?.hotel_data?.longitude
                      ? hotelDetails?.hotel_data?.longitude
                      : 0
                  }
                  currencySymbol={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.symbol_native
                  }
                  currencyCode={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.code
                  }
                />
                <div className="overviewTitle sectionTitle desktopViewDisplayNone">
                  <h2>Overview</h2>
                </div>
                <div className="tabsWrapper mobileViewDisplayNone">
                  <HotelDetailsTabs
                    activeTab={activeTab}
                    scrollToView={scrollToView}
                    hasReviews={hotelDetails?.reviews?.review_count != 0}
                  />
                </div>
                <Overview
                  reviews={hotelDetails ? hotelDetails?.reviews : {}}
                  scrollToView={scrollToView}
                  amenities={
                    hotelDetails ? hotelDetails?.static_data.top_amenities : []
                  }
                />
                <Rooms
                  Countdown={<Countdown />}
                  CountDownForMobile={<CountDownForMobile />}
                  CountdownStatus={CountdownStatus}
                  hotel_id={params?.hotel_id}
                  showUpgradeModal={showUpgradeModal}
                  setShowUpgradeModal={setShowUpgradeModal}
                  currencySymbol={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.symbol_native
                  }
                  currencyCode={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.code
                  }
                />

                <Modal
                  isOpen={showUpgradeModal}
                  ariaHideApp={false}
                  className={`popup upgradePremiumModal ${true ? "popup-open" :""} `}
                >
                  <div className="popup-content">
                    <div className="popup-title">
                      <Button
                        onClick={closeModal}
                        className="btn close"
                        imageSrc={blueIcon}
                      />
                    </div>
                    <div className="content-box">
                      <Crown />
                      <p>Upgrade to <strong>premium</strong> to unlock this room rate</p>
                      <button type="button" className="premiumUpgradeBtn" onClick={redirectToUpgrade}>
                        <span className="wrapper">Upgrade to  <strong>Premium</strong> <span><Arrow /></span></span>
                      </button>
                      <button className="type-anchor" type="button" onClick={closeModal}>Skip for now</button>
                    </div>
                  </div>
                </Modal>
                <Amenities
                  amenities={
                    hotelDetails && hotelDetails?.static_data?.facilities
                      ? hotelDetails?.static_data?.facilities
                      : []
                  }
                />
                <PropertiesDetails
                  description={
                    hotelDetails && hotelDetails?.static_data?.description
                      ? hotelDetails?.static_data?.description
                      : "No Description..."
                  }
                  nearBy={
                    hotelDetails && hotelDetails?.static_data?.attractions
                      ? hotelDetails?.static_data?.attractions
                      : "No NearBy..."
                  }
                />

                {hotelDetails?.reviews?.review_count != 0 && (
                  <Reviews
                    {...hotelDetailMockData?.reviews}
                    reviews={hotelDetails ? hotelDetails?.reviews : {}}
                  />
                )}
              </div>
            </Scrollbars>
          )}
           {!hotelDetails && (
          <Scrollbars
              // autoHeight={`calc(100vh - 110px)`}
              // autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container not-found-container">
                <div className="not-found-content">
                  <div className="not-found-content-wrap text-center">
                    <div className="expire-icon">
                      <img src={ExpireIcon} alt="icon"/>
                    </div>
                    <h2>Your Search Expired.</h2>
                    <p>
                      The hotel you have selected is currently sold out, Please
                      try again!
                    </p>
                    <NavLink className="btn" to={`/hotel-search-result/`}>
                      Search Again
                    </NavLink>
                  </div>
                </div>
              </div>
            </Scrollbars>
            )}
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

export default HotelDetail;

import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import AcademyTab from "../Tabs/AcademyTab";
import checklistPdf from "../../Assets/pdf/How-to-Use-Google-Ads-eBook.pdf";
import checklistPdf2 from "../../Assets/pdf/Guide-to-the-Perfect-Google-Ads-Account-Structure.pdf";

import PdfIcon from "../../Assets/img/icons/pdf-icon.png";
function BestPractices() {
  return (
    <div className="siteBg academy-wrapper FaqPageWrap Terms-wrapper">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true}/>
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <AcademyTab name={"bestpractice"} />
              <div className="col rightContent">
                <div className="faqWrapper card2">
                  <div className="pdf-content-wrapper">
                    <div className="row no-gutters">
                      <div className="col content-col">
                        <h3>How to use Google Ads (PDF)</h3>
                        <p>Comprehensive Kit and Overview into the Google Ads World.</p>
                      </div>
                      <div className="col image-col">
                        <div className="image-wrap">
                        <a href={checklistPdf} target="_blank">
                           <span>DOWNLOAD</span>
                          <img src={PdfIcon} alt="icon" />
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="faqWrapper card2">
                  <div className="pdf-content-wrapper">
                    <div className="row no-gutters">
                      <div className="col content-col">
                        <h3>Guide to the Perfect Google Ads Account Structure (PDF)</h3>
                        <p>Optimizing your Google ads involves lots of things :ad copy, targeting, scheduling, and more.<br/>Here is a comprehensive Guide on how to structure your account</p>
                      </div>
                      <div className="col image-col">
                        <div className="image-wrap">
                        <a href={checklistPdf2} target="_blank">
                           <span>DOWNLOAD</span>
                          <img src={PdfIcon} alt="icon" />
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default BestPractices;

import React from 'react'


//hotel faq list getting from list of array
function HotelFaqWrapper({openList, handleOpenCloseList, faq, index}) {
  return (
   	
	<div className="faqContent" key={index}>
		<div className="faqQuestion">
			<button key={index} className={faq.id === openList ? 'active-btn' : ''} onClick={() => handleOpenCloseList(faq.id)}>
				{faq.title}
			</button>
		</div>
		<div className="faqAnswer" key={index} style={faq.id === openList ? null : {display: 'none'}}>
			<p>{faq.description}</p>
			{ faq.description2 && <p>{faq.description2}</p> }
			{ faq.description3 && <p>{faq.description3}</p> }
			{ faq.description4 && <p>{faq.description4}</p> }
			{ faq.description5 && <p>{faq.description5}</p> }
			{ faq.headingLine1 && faq.headingLine2 && 
			<h3>
				{faq.headingLine1} {" "}
				<span>{faq.headingLine2}</span>
			</h3> }
			{ faq.headingLine3 && faq.headingLine4 &&
			<h3>
				{faq.headingLine3}{" "}
				<span>{faq.headingLine4}</span>
			</h3> }
			{ faq.list1 && <ul><li>{faq.list1}</li></ul> }
			{ faq.list2 && <ul><li>{faq.list2}</li></ul> }
			{ faq.list3 && <ul><li>{faq.list3}</li></ul> }
		</div>
	</div>		

)
}

export default HotelFaqWrapper
import React, { useRef, useState, useEffect } from "react";
import Header from "../../Component/Header";
import Card from "../../Component/Card";
import Slider from "react-slick";
// import Videojs from "../../Component/VideoJs/homeAutoplayVideo"
import VideoPlayer from "react-background-video-player";
// import Videojs from "videojs-background";
import downArrow from "../../Assets/img/network/down-arrrow.svg";
import Background from "../../Assets/img/tripello-difference.png";
import DifferenceBackground from "../../Assets/img/home/tripello-diffrenece.png";
import flightImage from "../../Assets/img/flightImg.png";
import hotelImg from "../../Assets/img/cardImg2.png";
import carImg from "../../Assets/img/cardImg3.png";
import cardImage from "../../Assets/img/cardImg4.png";
import DeskSlideOne from "../../Assets/img/slide-dsk/slide-img-1.png";
import DeskSlideTwo from "../../Assets/img/slide-dsk/slide-img-2.png";
import DeskSlideThree from "../../Assets/img/slide-dsk/slide-img-3.png";
import DeskSlideFour from "../../Assets/img/slide-dsk/slide-img-4.png";
import DeskSlideFive from "../../Assets/img/slide-dsk/slide-img-5.png";
import DeskSlideSix from "../../Assets/img/slide-dsk/slide-img-6.png";
import DeskSlideSeven from "../../Assets/img/slide-dsk/slide-img-7.png";
import DeskSlideEight from "../../Assets/img/slide-dsk/slide-img-8.png";
import DeskSlideNine from "../../Assets/img/slide-dsk/slide-img-9.png";
import DeskSlideTen from "../../Assets/img/slide-dsk/slide-img-10.png";
import DeskSlideEleven from "../../Assets/img/slide-dsk/slide-img-11.png";
import DeskSlideTwelve from "../../Assets/img/slide-dsk/slide-img-12.png";
import DeskSlideThirteen from "../../Assets/img/slide-dsk/slide-img-13.png";
import DeskSlideFourteen from "../../Assets/img/slide-dsk/slide-img-14.png";
import DeskSlideFifteen from "../../Assets/img/slide-dsk/slide-img-15.png";
import MobSlideOne from "../../Assets/img/slide-mob/mob-img-1.png";
import MobSlideTwo from "../../Assets/img/slide-mob/mob-img-2.png";
import MobSlideThree from "../../Assets/img/slide-mob/mob-img-3.png";
import MobSlideFour from "../../Assets/img/slide-mob/mob-img-4.png";
import MobSlideFive from "../../Assets/img/slide-mob/mob-img-5.png";
import MobSlideSix from "../../Assets/img/slide-mob/mob-img-6.png";
import MobSlideSeven from "../../Assets/img/slide-mob/mob-img-7.png";
import MobSlideEight from "../../Assets/img/slide-mob/mob-img-8.png";
import MobSlideNine from "../../Assets/img/slide-mob/mob-img-9.png";
import MobSlideTen from "../../Assets/img/slide-mob/mob-img-10.png";
import MobSlideEleven from "../../Assets/img/slide-mob/mob-img-11.png";
import MobSlideTwelve from "../../Assets/img/slide-mob/mob-img-12.png";
import MobSlideThirteen from "../../Assets/img/slide-mob/mob-img-13.png";
import MobSlideFourteen from "../../Assets/img/slide-mob/mob-img-14.png";
import MobSlideFifteen from "../../Assets/img/slide-mob/mob-img-15.png";
import Video from "../../Component/Video";
import plusImg from "../../Assets/img/plus-img.png";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import posterBg from "../../Assets/img/home/Homepage-Banner-Video.jpg";
import homeVideoDesktop from "../../Assets/video/home/new-tripello-homepage-top-video.mp4";
import homeVideoipad from "../../Assets/video/home/new-tripello-homepage-top-video-ipad5.mp4";
import homeVideowebm from "../../Assets/video/home/new-tripello-homepage-top-video.webm";
import homeVideoMobile from "../../Assets/video/home/new-tripello-homepage-top-video.webm";
import playButton from "../../Assets/img/network/play-button.png";
import featuredOnImg from "../../Assets/img/home/featured-on.png";
import Button from "../../Component/FormComponent/Button";
import AccordianOne from "../../Component/AccordianOne";
import { faqs } from "../../main/CustomConstants/Home";
import ModalHome from "../../Component/ModalHome";
import PlanInfoTwo from "../../Component/PlanInfoTwo";
import { TriptekDifference } from "./TriptekDifference";
import { TripelloWayWrapper } from "./TripelloWayWrapper";
import HomepageSlider from "../../Component/HomepageSlider";
import MoreLessButton from "../../Component/MoreLessButton";
import MobileImgSlider from "../../Component/MobileImgSlider";
import VideoModal from "../../Component/VideoModal";
import PortraitModeModel from "../../Component/PortraitModeModel";
import WalletSavings from "../../Assets/img/icons/wallet-savings.png";
import Percent0 from "../../Assets/img/icons/percent-0.png";
import DynamicPricing from "../../Assets/img/icons/dynamic-pricing.png";
import { ReactComponent as RightArrowWhite } from "../../Assets/img/home/right-arrow-white.svg";
import { ReactComponent as DownArrowSvg } from "../../Assets/img/down-arrow-svg.svg";

import { ReactComponent as PerfectOpportunity } from "../../Assets/img/home/join-free.svg";
import { ReactComponent as Structure } from "../../Assets/img/home/no-time.svg";
import { ReactComponent as OnboardingProcess } from "../../Assets/img/home/upgrade.svg";
import { ReactComponent as NounSafe } from "../../Assets/img/down-arrow-svg.svg";
import ClarityCode from "../../Views/MarketingWebsite/ClarityCode";
import TikTokScript from "../../Views/MarketingWebsite/TikTokScript";
import TaboolaPixel from "../../Views/MarketingWebsite/TaboolaPixel";
import blueIconClose from "../../Assets/img/icons/cancel-close-icon-blue.png";

import Modal from "react-modal";

function Home() {
  const scrollPage = useRef(null);
  // const backgroundVideo = useRef(null);
  const [isFaqExpanded, setIsFaqExpanded] = useState(false);
  const [openedAccordian, setOpenedAccordian] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  //state for opening video modal
  const [openModal, setOpenModal] = useState(false);
  const [portraitViewModal, setportraitViewModal] = useState(
    window.outerWidth > 767 && window.outerWidth < 1025 ? true : false
  );
  //state for show top sticky header on scroll
  const [showHeader, setShowHeader] = useState(false);

  // state for scroll button
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

  // for video play and pause button
  // const [buttonPlay, setButtonPlay] = useState(false);
  // const desktopVideoRef = useRef(null);
  // const mobileVideoRef = useRef(null);

  // const playAndPauseButton = () => {
  //   setButtonPlay(!buttonPlay);
  //   desktopVideoRef.current.play();
  // };

  // const playAndPauseMobileButton = () => {
  //   setButtonPlay(!buttonPlay);
  //   mobileVideoRef.current.play();
  // };

  useEffect(() => {
    // console.log(window.location.href);
    if (window.location.href.includes("#home-faq")) {
      setTimeout(() => {
        const element = document.getElementById("home-faq");
        element.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, []);

  //getting scroll button while scrolling down
  useEffect(() => {
    scrollPage.current.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", function (event) {
      if (window.outerWidth > 767 && window.outerWidth < 1025) {
        setportraitViewModal(true);
      } else {
        setportraitViewModal(false);
      }
    });
    return () => {
      scrollPage?.current?.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", function (event) {
        if (window.outerWidth > 767 && window.outerWidth < 1025) {
          setportraitViewModal(true);
        } else {
          setportraitViewModal(false);
        }
      });
    };
  }, []);

  //setting length after which sticky header and scrollToTop button show
  const handleScroll = () => {
    if (
      (scrollPage.current.scrollTop >= 4 && !showHeader) ||
      scrollPage.current.scrollTop >= 150
    ) {
      setShowHeader(true);
      setShowScrollTopBtn(true);
    }
    if (
      scrollPage.current.scrollTop < 4 ||
      scrollPage.current.scrollTop < 150
    ) {
      setShowHeader(false);
      setShowScrollTopBtn(false);
    }
  };

  //jumping to top of the page on button click
  const scrollElementToTop = () => {
    scrollPage.current.scrollTop = 0;
  };

  //handle open and close accordian list
  const handleOpenClose = (id) => {
    if (id === openedAccordian) {
      setOpenedAccordian(null);
    } else {
      setOpenedAccordian(id);
    }
  };

  var sectionStyle = {
    backgroundImage: `url(${Background})`,
  };
  var sectionStyleTwo = {
    backgroundImage: `url(${DifferenceBackground})`,
  };

  //handle open and close video modal

  const handleOpenCloseVideoModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handleOpenClosePortraitModal = () => {
    if (portraitViewModal) {
      setportraitViewModal(false);
    } else {
      setportraitViewModal(true);
    }
  };
  //Modal open and close
  const handleOpenCloseModal = () => {
    if (!modalOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };

  // scroll view function

  const scrollView = (e) => {
    let triptekDifference = document.querySelector("#triptek-difference");
    e.preventDefault();
    triptekDifference &&
      triptekDifference.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  // scroll view function end

  // Accordian list
  const getFaqs = (faqList) => {
    let faqListNodes = [];

    // render the whole list
    faqList.forEach((faq, index) => {
      if (isFaqExpanded) {
        faqListNodes.push(
          <AccordianOne
            id={faq.id}
            key={faq.title + index}
            heading={faq.title}
            content={faq.description}
            openedAccordian={openedAccordian}
            handleOpenClose={handleOpenClose}
          />
        );
      } else {
        // render list with limit of 5 items
        if (index < 5) {
          faqListNodes.push(
            <AccordianOne
              id={faq.id}
              key={faq.title + index}
              heading={faq.title}
              content={faq.description}
              openedAccordian={openedAccordian}
              handleOpenClose={handleOpenClose}
            />
          );
        }
      }
    });

    return faqListNodes;
  };
  //Tripello Network List
  const tripelloNetworkList = [
    {
      icon: <PerfectOpportunity />,
      title: "Join Free",
      text: (
        <>
          <strong>JOIN for FREE</strong>, explore our platform and see{" "}
          <strong>ALL Products and Member Pricing.</strong>
        </>
      ),
      link: "/freemium-signup",
    },
    {
      icon: <Structure />,
      title: "No Time Limit",
      text: (
        <>
          <strong>Freemium Members</strong> can book{" "}
          <strong>Hotels only,</strong> with Great Savings of up to 25%.
        </>
      ),
      link: "",
    },
    {
      icon: <OnboardingProcess />,
      title: "Upgrade Anytime",
      text: (
        <>
          <strong>Premium Members</strong> have <strong>full Access</strong> and
          enjoy <strong>Savings up to 65%</strong> and more.
        </>
      ),
      link: "",
    },
  ];
  const affiliateCompensationList = [
    {
      icon: <NounSafe />,
      title: "Freemium Membership Commission",
      text: (
        <>
          <strong>50%</strong> of whatever a Freemium <br />
          Member saves on their Hotel Booking.
        </>
      ),
    },
    {
      icon: <NounSafe />,
      title: "Premium Membership Commission",
      text: (
        <>
          <strong>50%</strong> on all Premium and Premium Plus Memberships and
          on Member to
          <br /> Member referrals.
        </>
      ),
    },
    {
      icon: <NounSafe />,
      title: "Affiliate Membership Commission",
      text: (
        <>
          <strong>50%</strong> on all Affiliate Memberships.
        </>
      ),
    },
  ];
  // slick-slider setting
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    dots: true,
    centerMode: true,
    centerPadding: "250px",
    speed: 500,
    autoplaySpeed: 4000,
    variableWidth: true,
    //adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
          variableWidth: false,
          arrows: false,
          centerPadding: "0px",
          mobileFirst: true,
        },
      },
    ],
  };
  const videoJsOptions = {
    autoplay: false,
    volume: 0,
    controls: false,
    loop: true,
    playsInline: false,
    sources: [
      {
        src: homeVideoDesktop,
        type: "video/mp4",
      },
    ],
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // maxWidth: "600px"
    },
  };

  const [openCommingSoonModal, setOpenCommingSoonModal] = useState(true);

  return (
    <>
      <Modal
        isOpen={openCommingSoonModal}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        className="modal-wrap popup popup-open modal-back-soon"
        onAfterClose={() => {
          setOpenCommingSoonModal(false);
        }}
      >
        <div className="modal-content popup-content">
          <div className="popup-title">
            <h3 className="modal-title">We'll be back soon!</h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setOpenCommingSoonModal(false)}
            >
              <img src={blueIconClose} alt="icon" />
            </button>
          </div>

          <div className="content-box">
            <p>
              We are very sorry for the inconvenience but we are performing a
              major system upgrade which will take until Saturday July 20th.
              <br />
              During this period all Bookings, Sign-ups and Membership upgrades
              have been disabled. Existing customers can still access the
              platform and their profiles but won't be seeing the incredible
              savings and prices they are accustom to.
              <br />
              Please bear with us as these upgrades will include new features
              and even better prices.
              <br />
              If you have any question in the meantime, please feel free to
              contact us via email.
              <br />
              Thank you for your support.
            </p>
            <h4> Your Tripello Team </h4>
          </div>
        </div>
      </Modal>

      {/* Microsoft Clarity Code start  */}
      <ClarityCode />
      {/* Microsoft Clarity Code end*/}

      {/* TikTokScript  start*/}
      <TikTokScript />
      {/* TikTokScript  end */}

      {/* TaboolaPixel start  */}
      <TaboolaPixel />
      {/* TaboolaPixel end */}
      <div
        className={
          !showHeader
            ? `grayBg indexPageWrap`
            : "grayBg indexPageWrap header-sticky"
        }
      >
        <div ref={scrollPage}>
          <section className="bannerSec home-banner">
            <div className="home-iframe-wrapper home-banner-video">
              <VideoPlayer
                autoPlay={true}
                muted={true}
                loop={true}
                playsInline={true}
                className="bannerImg video-player"
                // poster={posterBg}
                repeat={true}
                resizeMode={"cover"}
                posterResizeMode={"cover"}
                pictureInPicture={true}
                src={homeVideoDesktop}
              />
              {/* <source src={homeVideoDesktop} type="video/mp4" />
                <source src={homeVideoipad} type="video/mp4" />
                <source src={homeVideowebm} type="video/webm" /> */}
              {/* <video {...videoJsOptions} ref={backgroundVideo}/> */}
            </div>
            <div className="fixedHeader">
              <Header />
            </div>
            <div className="bannerCont centerAlign">
              <div className="container">
                <div className="home-banner-content">
                  <h3>
                    We Are <strong>Here</strong> To Get You{" "}
                    <strong>There</strong>
                  </h3>
                  <p>Wholesale/Net Travel Prices For Everyone</p>
                  <div className="home-join-free-btn">
                    {/* <a href="/freemium-signup">Join Free</a> */}
                    <button href="/freemium-signup">Join Free</button>
                  </div>
                </div>
              </div>
              <div className="scrollBtm">
                <a
                  to="#scroll-section"
                  onClick={scrollView}
                  className="scroll-down"
                >
                  <div className="mousey-wrap">
                    <div className="mousey">
                      <div className="scroller"></div>
                    </div>
                    <DownArrowSvg />
                  </div>
                </a>
              </div>
            </div>
          </section>
          <section
            id="triptek-difference"
            className="tripello-difference-wrapper"
            style={sectionStyleTwo}
          >
            <div className="container sideSpace centerAlign">
              {/* <TriptekDifference /> */}
              <div className="container">
                <h3 className="sctionTitle">The Tripello Difference</h3>
                <p>
                  Wholesale/Net Travel Prices that are not publically available.
                  <br /> They can legally only be shown behind a login.
                </p>
                <div className="content-wrapper">
                  <div className="col">
                    <div className="heading">
                      <i>
                        <img src={DynamicPricing} />
                      </i>{" "}
                      Dynamic Pricing
                    </div>
                    <div className="content">
                      <strong>Dynamic, Wholesale / Net Rates</strong> in Real
                      Time, directly to you.
                    </div>
                  </div>
                  <div className="col">
                    <div className="heading">
                      <i>
                        <img src={Percent0} />
                      </i>{" "}
                      No Fees or Mark Up’s
                    </div>
                    <div className="content">
                      With our powerful Technology, book directly from thousands
                      of Wholesale Travel Providers,{" "}
                      <strong>YOU pay what WE pay!</strong>
                    </div>
                  </div>
                  <div className="col">
                    <div className="heading">
                      <i>
                        <img src={WalletSavings} />
                      </i>{" "}
                      Savings up to 65%
                    </div>
                    <div className="content">
                      More Savings, More Freedom, More Choices,{" "}
                      <strong>Better Experiences.</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container sectionSpace2 sideSpace triptek-inventory-wrapper">
            <h3 className="sctionTitle centerAlign">The Tripello Inventory</h3>
            <div className="colCard">
              <Card
                title={<h5>Global Flights</h5>}
                description="Big savings on Flights, especially in premium cabins, from all Global Carries and over 140 low-cost carriers from around the world."
                image={flightImage}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />

              <Card
                title={<h5>Hotels & Resorts</h5>}
                description="Huge savings on over 1.5 million Hotels and Resorts worldwide, Including the most popular destinations."
                image={hotelImg}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
              <Card
                title={<h5>Rental Cars & Services</h5>}
                description="Great Deals on Rental Cars by the best and most trusted brands from around the world."
                image={carImg}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
              <Card
                title={<h5>Vacation Homes</h5>}
                description=" More than 1.5 Million bookable Vacation rentals incl. Condos, Cabins, Villas, Bungalows, Beach Houses and more."
                image={cardImage}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
            </div>
          </div>

          {/* <div className="container sectionSpace2 sideSpace centerAlign tripello-way-wrapper way-wrapper">
            <TripelloWayWrapper />
          </div> */}
          <div className="tripello-way-wrapper">
            <div className="container">
              <div className="section-title centerAlign">
                <h2 className="sctionTitle">The Tripello Way</h2>
                <h3>
                  The <strong>first true</strong> subscription based Wholesale
                  Travel Service.
                </h3>
              </div>
              <div className="cardStyle">
                <div className="card-box-wrap row">
                  {tripelloNetworkList.map((list, index) => {
                    return (
                      <>
                        <div className="col card-col">
                          <div className="cardBox">
                            {list.link && (
                              <a className="link-url" href={list.link}></a>
                            )}
                            <div className="img-wrap">
                              {list.link && (
                                <a className="link-url" href={list.link}></a>
                              )}
                              {list.icon}
                            </div>
                            <h4>{list.title}</h4>
                            <p>{list.text}</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="slide-show-desktop " style={sectionStyle}>
            <div className="slider-wrapper">
              <Slider className="desk-slider" {...settings}>
                <HomepageSlider src={DeskSlideOne} />
                <HomepageSlider src={DeskSlideTwo} />
                <HomepageSlider src={DeskSlideThree} />
                <HomepageSlider src={DeskSlideFour} />
                <HomepageSlider src={DeskSlideFive} />
                <HomepageSlider src={DeskSlideSix} />
                <HomepageSlider src={DeskSlideSeven} />
                <HomepageSlider src={DeskSlideEight} />
                {/*<HomepageSlider src={DeskSlideNine} />
                <HomepageSlider src={DeskSlideTen} />
                <HomepageSlider src={DeskSlideEleven} />
                <HomepageSlider src={DeskSlideTwelve} />
                <HomepageSlider src={DeskSlideThirteen} />
                <HomepageSlider src={DeskSlideFourteen} />
                <HomepageSlider src={DeskSlideFifteen} /> */}
              </Slider>
            </div>
          </div>

          <div className="slide-show-mobile" style={sectionStyle}>
            <div className="mobile-slider-wrapper">
              <Slider className="mobile-slider" {...settings}>
                <MobileImgSlider src={MobSlideOne} />
                <MobileImgSlider src={MobSlideTwo} />
                <MobileImgSlider src={MobSlideThree} />
                <MobileImgSlider src={MobSlideFour} />
                <MobileImgSlider src={MobSlideFive} />
                <MobileImgSlider src={MobSlideSix} />
                <MobileImgSlider src={MobSlideSeven} />
                <MobileImgSlider src={MobSlideEight} />
                {/*<MobileImgSlider src={MobSlideNine} />
                <MobileImgSlider src={MobSlideTen} />
                <MobileImgSlider src={MobSlideEleven} />
                <MobileImgSlider src={MobSlideTwelve} />
                <MobileImgSlider src={MobSlideThirteen} />
                <MobileImgSlider src={MobSlideFourteen} />
                <MobileImgSlider src={MobSlideFifteen} /> */}
              </Slider>
            </div>
          </div>

          <div className="gradiantBg videoSpace sideSpace centerAlign tripello-magic-wrapper">
            <div className="sctionTitle">The Tripello Magic</div>
            <p>Give us a minute to show you how</p>
            <div className="cardStyle">
              <div className="videoBox">
                <div className="videoInBox">
                  <div className="desktop-video">
                    <Button
                      type="button"
                      className="video-play-btn"
                      imageSrc={playButton}
                      onClick={handleOpenCloseVideoModal}
                    />
                  </div>
                  <div className="mobile-video">
                    <Button
                      type="button"
                      className="video-play-btn"
                      imageSrc={playButton}
                      onClick={handleOpenCloseVideoModal}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="tripello-magic-content">
              <div className="video-section-content-wrap">
                <h6>
                  If you like to <strong>find out more</strong> about{" "}
                  <strong>WHAT</strong>, <strong>HOW</strong>, and{" "}
                  <strong>WHY</strong> we do what <strong>WE</strong> do,
                  <strong>
                    <button
                      onClick={handleOpenCloseModal}
                      className="tripello-magic-link"
                    >
                      CLICK HERE.
                    </button>
                  </strong>
                </h6>
              </div>
              <div className="featured-on-icons">
                <img src={featuredOnImg} alt="image" />
              </div>
            </div>
          </div>
          <div className="planCont">
            <div className="container sideSpace centerAlign">
              <h3 className="sctionTitle">
                You Choose Which One Is Right For You
              </h3>
              <div className="container">
                <div className="choosePlan">
                  <PlanInfoTwo
                    freePlan="free-plan"
                    planName="Freemium Membership"
                    arrayList={[
                      "No Credit/Debit Card Required*",
                      "Limited Platform Access – Hotels and Resorts Only",
                      "Savings of up to 25%",
                      "Access to 1.5 million Hotels and Resorts",
                    ]}
                    planNote="* Not required for Sign Up but will be required for Hotel
                    Bookings"
                    heading={
                      <>
                        Free
                        <span>&nbsp;(no time limit)</span>
                      </>
                    }
                    btnName="I'm In"
                    link="freemium-signup"
                    btnArrow={<RightArrowWhite />}
                  />
                  <PlanInfoTwo
                    planName="Premium Plus Membership"
                    months="(12 Month)"
                    imgPlus={
                      <>
                        <img src={plusImg} alt="image" />
                      </>
                    }
                    arrayList={[
                      "Credit/Debit Card Required*",
                      "Unlimited Platform Access",
                      "Savings of up to 65% and more",
                      "Access to 1.5 million Hotels and Resorts",
                      "Access to 1.5 million Vacation Homes",
                      "Access to Rental Cars and upcoming Chauffeur Service",
                      "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
                      <>
                        Access to Member Referral Rewards Program <br />
                        – $25 for each Premium Member <br />– $50 for each
                        Premium Plus Member{" "}
                      </>,
                    ]}
                    planNote="* Required for Sign Up and Bookings"
                    heading={
                      <>
                        $24<sub>.95</sub>
                        <span>/ per month</span>
                      </>
                    }
                    para="($299.40 total at checkout)"
                    btnName="Choose Plan"
                    link="membership-premium-plus-checkout"
                    btnArrow={<RightArrowWhite />}
                  />
                  <PlanInfoTwo
                    planName="Premium Membership"
                    months="(6 Month)"
                    arrayList={[
                      "Credit/Debit Card Required*",
                      "Unlimited Platform Access",
                      "Savings of up to 65% and more",
                      "Access to 1.5 million Hotels and Resorts",
                      "Access to 1.5 million Vacation Homes",
                      "Access to Rental Cars and upcoming Chauffeur Service",
                      "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
                    ]}
                    planNote="* Required for Sign Up and Bookings"
                    heading={
                      <>
                        $32<sub>.50</sub>
                        <span>/ per month</span>
                      </>
                    }
                    para="($195.00 total at checkout)"
                    btnName="Choose Plan"
                    link="membership-premium-checkout"
                    btnArrow={<RightArrowWhite />}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="home-faq"
            className="container sectionSpace2 sideSpace faqCnt home-faq"
          >
            <h3 className="centerAlign sctionTitle">
              Here are Some Answers to Common Questions
            </h3>
            <div className="accordion">
              <div className="cardStyle accordWrap">
                <div className="toggle-more-accordions">{getFaqs(faqs)}</div>
              </div>

              {/* more and less button below accordian */}

              {faqs.length > 5 && (
                <MoreLessButton
                  isExpandAccordian={isFaqExpanded}
                  setIsExpandAccoridan={setIsFaqExpanded}
                />
              )}
            </div>
          </div>
        </div>
        <FooterWithMediaIcon />
        {showScrollTopBtn ? (
          <div
            id="back-to-top"
            className="back-to-top"
            onClick={scrollElementToTop}
          >
            <button className="btn btn-primary" title="Back to Top">
              <svg
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 31.479 31.479"
                style={{ enableBackground: "new 0 0 31.479 31.479" }}
                width="18px"
                height="18px"
                className="svg lazyloading replaced-svg"
              >
                <path
                  style={{ fill: "#004185" }}
                  d="M26.477,10.274c0.444,0.444,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-8.047-8.047  v26.555c0,0.619-0.492,1.111-1.111,1.111c-0.619,0-1.127-0.492-1.127-1.111V3.813l-8.031,8.047c-0.444,0.429-1.159,0.429-1.587,0  c-0.444-0.444-0.444-1.143,0-1.587l9.952-9.952c0.429-0.429,1.143-0.429,1.571,0L26.477,10.274z"
                ></path>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>
          </div>
        ) : null}
      </div>

      {/* Modal open and close  */}

      {modalOpen && (
        <ModalHome openModal={modalOpen} closeModal={handleOpenCloseModal} />
      )}
      {openModal && (
        <VideoModal
          openModal={openModal}
          closeModal={handleOpenCloseVideoModal}
        />
      )}
    </>
  );
}

export default Home;

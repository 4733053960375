import React, { useState, useEffect } from "react";
import { flightActions } from "../../Stores/Actions/";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { cabinClassOptions } from "./Common/common";
import { commafy } from "../../Util/util";
import _ from "lodash";
import Slider from "react-slick";
import { BiTimeFive } from "react-icons/bi";
import { HiOutlineWifi } from "react-icons/hi";
import { Tooltip, Radio } from "antd";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
// import TradMark from "../TradMark";
// import {timeConvert} from "../../Util/util";
import { ReactComponent as Cross } from "../../Assets/img/icons/x.svg";
// import airlineLogo from "../../Assets/images/tempImg/airlineIcon.png";
// import { ReactComponent as CarryBaggageIcon } from "../../Assets/img/icons/feature-carry-baggage.svg";
// import { ReactComponent as CheckedBaggageIcon } from "../../Assets/img/icons/feature-checked-baggage.svg";
// import { ReactComponent as MealIcon } from "../../Assets/img/icons/feature-meal.svg";
// import { ReactComponent as DrinksIcon } from "../../Assets/img/icons/feature-drinks.svg";
// import { ReactComponent as SeatSelectionIcon } from "../../Assets/img/icons/feature-seat-selection.svg";
// import { ReactComponent as LegRoomIcon } from "../../Assets/img/icons/feature-leg-room.svg";
// import { ReactComponent as CheckedIcon } from "../../Assets/img/icons/feature-checked.svg";

// import { ReactComponent as NotAllowedIcon } from "../../Assets/img/icons/feature-not-allowed.svg";
import { ReactComponent as FlightWithBorderIcon } from "../../Assets/img/icons/flight-border.svg";
// import { ReactComponent as RefundableIcon } from "../../Assets/img/icons/refundable-icon.svg";
import { ReactComponent as FlightIcon } from "../../Assets/img/icons/icon-flight.svg";
import { ReactComponent as NonRefundableIcon } from "../../Assets/img/icons/non-refund.svg";
// import { ReactComponent as Reissue } from "../../Assets/img/icons/reissue.svg";
import Reissue from "../../Assets/img/icons/feature/reissue.png";
import CarryBaggageIcon from "../../Assets/img/icons/feature/feature-carry-baggage.png";
import CheckedBaggageIcon from "../../Assets/img/icons/feature/feature-checked-baggage.png";
import MealIcon from "../../Assets/img/icons/feature/feature-meal.png";
import DrinksIcon from "../../Assets/img/icons/feature/feature-drinks.png";
import SeatSelectionIcon from "../../Assets/img/icons/feature/feature-seat-selection.png";
import LegRoomIcon from "../../Assets/img/icons/feature/feature-leg-room.png";
import RefundableIcon from "../../Assets/img/icons/feature/refundable-icon.png";
import CheckedIcon from "../../Assets/img/icons/check.png";
import NotAllowedIcon from "../../Assets/img/icons/cancel.png";
const labelKeys = [
  "seatSelection",
  "cabinBaggage",
  "carryOnBaggage",
  // "meal", 
  // "drinks", 
  // "seatSelection", 
  // "legRoom", 
  "refund",
  "reIssue"];
const labelText = [
  "Seat Selection",
  "Carry-on baggage",
  "Checked baggage",
  // "Meal", 
  // "Drinks",  
  // "Legroom", 
  "Refundable",
  "Re-issue"
];
const iconsKeys = [
  <img src={SeatSelectionIcon} alt="Seat Selection" />,
  <img src={CarryBaggageIcon} alt="Carry Baggage" />,
  <img src={CheckedBaggageIcon} alt="Checked Baggage" />, <img src={MealIcon} alt="Meal Plan" />, <img src={DrinksIcon} alt="Drinks Plan" />, <img src={LegRoomIcon} alt="Leg Room" />, <img src={RefundableIcon} alt="Refundable" />, <img src={Reissue} alt="Reissue" />]
import { ErrorModel } from "../../Assets/scss/components/ErrorModal/ErrorModal";


export default function FlightDetailsRow({
  singleFlight,
  flightSegments,
  duration,
  stops,
  aircrafts,
  hideFeaturesModal,
  // handleValidateFlight,
  handleFlightSearch
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const [isMobile, setIsMobile] = useState(false);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
  const [mobileSelectedFlightLegIndex, setMobileSelectedFlightLegIndex] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);


  const singleFlightFeatures = useSelector(
    (state) => state.flightReducer?.singleFlightFeatures
  );
  const [selectedFlightLeg, setSelectedFlightLeg] = useState([]);
  const [selectedFlightLegIndex, setSelectedFlightLegIndex] = useState(0);
  const [popUpErrorMsg, setPopUpErrorMsg] = useState(null)

  const handleErrorModal = () => {
    setPopUpErrorMsg(null);
  };

  const flightsSearchData = useSelector(
    (state) => state.flightReducer?.flightSearchData
  );

  const className = cabinClassOptions?.filter((res) => res.value == JSON.parse(window.localStorage.getItem("validatePayload")).cabinClass)

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
    var payload = {
      sequenceId: singleFlight.sequenceId,
      cabinClass: JSON.parse(window.localStorage.getItem("validatePayload")).cabin_class_key
    }
    dispatch(flightActions.getFlightFeaturesAction(payload));
  }, [])
  const updateSize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [window.innerWidth]);

  useEffect(() => {
    if (singleFlightFeatures && selectedFlightLeg.length === 0) {
      setSelectedFlightLeg([singleFlightFeatures?.features?.[0]?.[0]])
    }
    var setgmentLeg = {};
    if (singleFlightFeatures && singleFlightFeatures.features.length > 0 && isMobile) {
      singleFlightFeatures?.flightSegments.map((segment, index) => {
        setgmentLeg = { ...setgmentLeg, ["segment_" + index]: 0 }

      })
      setMobileSelectedFlightLegIndex({
        ...setgmentLeg
      })

    }
  }, [singleFlightFeatures])

  useEffect(() => {
    if (singleFlightFeatures) {
      setSelectedFlightLeg([singleFlightFeatures?.features?.[currentIndex]?.[selectedFlightLegIndex]])
    }
  }, [selectedFlightLegIndex])


  const [isFlightSoldOut, setIsFlightSoldOut] = useState({
    message: null,
    soldedFlights: []
  })

  const savePayload = (sequenceId, cabinClassKey, cabinClassName, totalPrice, index) => {
    const payload = {
      "sequenceNo": sequenceId,
      "adults": flightsSearchData.request.adults,
      "children": flightsSearchData.request.children,
      "infants": flightsSearchData.request.infants,
      "locale": "en",
      "cabinClass": cabinClassKey,
      "cabinClassName": cabinClassName,
      "price": totalPrice,
      "currency": "USD",
      "flightSegments": JSON.parse(window.localStorage.getItem("selectedflightSegments"))
    }

    const RevalidatePayload = {
      sequenceId: sequenceId,
      cabinClass: cabinClassKey
    }

    dispatch(flightActions.validateFlightActions(RevalidatePayload)).then((validateData) => {
      if (validateData?.status != 200) {
        setIsFlightSoldOut({
          message: validateData?.data?.message,
          soldedFlights: [...isFlightSoldOut?.soldedFlights, index]
        });
      } else if (validateData?.status == 200) {
        window.localStorage.setItem("flightValidateRes", JSON.stringify(validateData?.data));
        window.localStorage.setItem("domesticFlight", JSON.stringify(!validateData?.data?.passportRequired));
        window.localStorage.setItem("selectedflightSegments", JSON.stringify(flightSegments));
        window.localStorage.setItem("validatePayload", JSON.stringify(payload));
        window.localStorage.setItem("singleSelectedFlight", JSON.stringify(singleFlight));
        navigate("/flight-checkout");
      }
    });
    // console.log("isFlightSoldOut", isFlightSoldOut);
    // handleValidateFlight(sequenceId, cabinClassKey)
    // hideFeaturesModal();

  }

  const reSearch = () => {
    window.scroll(0, 0);
    window.localStorage.removeItem("Search-Key");
    window.localStorage.removeItem("flightFilters");
    handleFlightSearch();
    hideFeaturesModal();
  }
  const settings = {
    slidesToShow: singleFlightFeatures?.labels.length > 3 ? 3 : singleFlightFeatures?.labels.length,
    swipeToSlide: true,
    infinite: false,
    center: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  const settings2 = {
    slidesToShow: singleFlightFeatures?.labels.length > 3 ? 3 : singleFlightFeatures?.labels.length,
    swipeToSlide: true,
    infinite: false,
    center: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return <>
    <div className="featuresGlobalDetails">
      <div className="modalOverlay"></div>
      <div className="flightFeaturesContent">
        <div className="flightFeatures-top">
          <button type="button" onClick={hideFeaturesModal} className="closeModal">
            <Cross />
          </button>
          <h3 className="modalTitle">{singleFlightFeatures ? "Cabin Features" : ""}</h3>
        </div>

        {/*  Desktop view */}
        {!isMobile && (
          <div className={`modalScroll ${"slides_" + singleFlightFeatures?.labels.length}`}>
            {singleFlightFeatures &&
              <div className="modalScrollInner" ><div className="wrapperRow">
                <div className="rowWrapper">
                  <div className="col classLabel"></div>
                  {singleFlightFeatures?.flightSegments[currentIndex].flightLegs.map((singleFlightLeg, labelIndex) => {
                    if (labelIndex === selectedFlightLegIndex) {
                      return <div className={`featureColWrapper first`}>
                        <div className="legFeatureWrapper">
                          {labelKeys.map((label, labelIndex) => {
                            return <div className="col">{iconsKeys[labelIndex]} {labelText[labelIndex]}</div>
                          })}
                        </div>
                      </div>
                    }
                  })}
                </div>
                <Slider
                  asNavFor={nav2}
                  ref={slider => (setNav1(slider))}
                  {...settings2}
                >
                  {selectedFlightLeg?.length > 0 && singleFlightFeatures?.labels.map((label, labelIndex) => {
                    return (
                      <div className={`featureColWrapper ${labelIndex === (singleFlightFeatures?.labels.length - 1) ? "last" : ""}`}>
                        <div className={`col classLabel ${labelIndex === (singleFlightFeatures?.labels.length - 1) && singleFlightFeatures?.labels.length > 1 ? "popular" : ""} ${singleFlightFeatures?.labels.length === 1 ? "seatsAvailable" : ""}`}>
                          <div className="classLabelInner" >
                            {singleFlightFeatures?.labels[labelIndex]} {labelIndex === (singleFlightFeatures?.labels.length - 1) ?
                              <span>
                                {labelIndex === (singleFlightFeatures?.labels.length - 1) && singleFlightFeatures?.labels.length > 1 ? "Popular" : ""}
                                {singleFlightFeatures?.labels.length === 1 ? `${singleFlightFeatures?.price[labelIndex].seatsAvailable} Seats left` : ""}
                              </span> : ""}
                          </div>
                        </div>

                        <div className="legFeatureWrapper">
                          {labelKeys?.map(staticKey => {
                            if (singleFlightFeatures?.features?.[labelIndex]?.[staticKey]) {
                              // singleFlightFeatures?.features?.[labelIndex]?.[staticKey].text
                              if (staticKey === "seatSelection") {
                                return <div className="col">{singleFlightFeatures?.features?.[labelIndex]?.[staticKey].allowed ? <>Allowed<Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex]?.[staticKey].text}>
                                  <BsFillInfoCircleFill />{" "}
                                </Tooltip></> : "With Airline or at Check-in"}</div>
                              } else if (staticKey === "cabinBaggage" || staticKey === "carryOnBaggage" || staticKey === "carryOnBaggage") {
                                return <div className="col">{singleFlightFeatures?.features?.[labelIndex]?.[staticKey].allowed ? <>Allowed<Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex]?.[staticKey].text}>
                                  <BsFillInfoCircleFill />{" "}
                                </Tooltip></> : "Restricted"}</div>
                              } else if (staticKey === "drinks" || staticKey === "meal") {
                                return <div className="col">{singleFlightFeatures?.features?.[labelIndex]?.[staticKey].allowed ? <><Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex]?.[staticKey].text}>
                                  <img src={CheckedIcon} className="allowed" alt="Allowed" />{" "}
                                </Tooltip></> : <img src={NotAllowedIcon} />}</div>
                              } else if (staticKey === "legRoom") {
                                return <div className="col">{singleFlightFeatures?.features?.[labelIndex]?.[staticKey].text ? <>{singleFlightFeatures?.features?.[labelIndex]?.[staticKey].name}<Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex]?.[staticKey].text}>
                                  <BsFillInfoCircleFill />{" "}
                                </Tooltip></> : <img src={NotAllowedIcon} />}</div>
                              } else if (staticKey === "refund" || staticKey === "reIssue") {
                                return <div className="col">
                                  {singleFlightFeatures?.features?.[labelIndex]?.[staticKey].allowed ?
                                    <>
                                      <img src={CheckedIcon} className="allowed" alt="Allowed" style={{ margin: 0 }} />
                                      <Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex]?.[staticKey].text}>
                                        <BsFillInfoCircleFill />{" "}
                                      </Tooltip>
                                    </> : <img src={NotAllowedIcon} />
                                  }
                                </div>
                              }
                            } else {
                              return "-"
                            }
                          })}
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              </div>
                <div className="wrapperRow footer">
                  <div className="rowWrapper">
                    <div className="featureColWrapper first">
                      <div className="footerLabel">
                        <h4>Total Price</h4>
                        <span>(includes taxes)</span>
                      </div>
                    </div>
                    <Slider
                      asNavFor={nav1}
                      ref={slider => (setNav2(slider))}
                      {...settings}
                    >
                      {singleFlightFeatures?.price.map((classPrice, index) => {

                        if (isFlightSoldOut?.message && (isFlightSoldOut?.soldedFlights)?.includes(index)) {
                          return <div className="flight-sold-out-wrap">
                            <h2> Sold out </h2>
                            <h4>You just missed it!</h4>
                            <p>{isFlightSoldOut?.message}</p>
                          </div>
                        }
                        return (
                          <div className="featureFooterWrapper">
                            <div className="col">
                              <span>{classPrice.currenncySymbol}{commafy(classPrice.totalPrice)}</span>
                              <button
                                type="button"
                                onClick={() => savePayload(classPrice.sequenceId, classPrice.cabinClassKey, classPrice.cabinClassKey, classPrice.totalPrice, index)}
                                className="priceBtn btn"
                              >
                                Select
                              </button>
                            </div>
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            }

            {!singleFlightFeatures &&
              <div className="noRoomsAvailable">
                <h3>Search Expired</h3>
                <h5>The search session has expired. Do you want to refresh your search?</h5>
                <button type="button" onClick={() => reSearch()} className='btn btn-primary'>Search</button>
              </div>
            }
          </div>
        )}

        {/*  is mobile device */}
        {isMobile &&
          <div className="modalScroll">
            {singleFlightFeatures &&
              <div className="wrapperRow">
                {singleFlightFeatures?.labels.map((labelName, labelIndex) => {
                  if (singleFlightFeatures?.features?.[labelIndex]) {
                    return <>
                      <div className={`featureColWrapper ${labelIndex === (singleFlightFeatures?.labels.length - 1) ? "last" : ""}`}>

                        <div className="col box-heading-col parent-col">
                          <div className="box-heading">
                            <h3 className="singleFlightFeaturesLabel">
                              {labelName}
                            </h3>
                          </div>
                        </div>

                        <div className="legFeatureWrapper">

                          {labelKeys.map((staticKey, labelTextIndex) => {
                            if (singleFlightFeatures?.features?.[labelIndex]?.[staticKey]) {
                              // singleFlightFeatures?.features?.[labelIndex]?.[staticKey].text
                              if (staticKey === "seatSelection") {
                                return <div className="col info-col">
                                  <h3> {iconsKeys[labelTextIndex]}</h3>
                                  <h4>{labelText[labelTextIndex]}</h4>
                                  <h5>{singleFlightFeatures?.features?.[labelIndex][staticKey].allowed ? <>Allowed<Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex][staticKey].text}>
                                    <BsFillInfoCircleFill />{" "}
                                  </Tooltip></> : "With Airline or at Check-in"}
                                  </h5>
                                </div>
                              } else if (staticKey === "cabinBaggage" || staticKey === "carryOnBaggage" || staticKey === "carryOnBaggage") {
                                return <div className="col info-col">
                                  <h3> {iconsKeys[labelTextIndex]}</h3>
                                  <h4>{labelText[labelTextIndex]}</h4>
                                  <h5>{singleFlightFeatures?.features?.[labelIndex][staticKey].allowed ? <>Allowed<Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex][staticKey].text}>
                                    <BsFillInfoCircleFill />{" "}
                                  </Tooltip></> : "Restricted"}
                                  </h5>
                                </div>

                              } else if (staticKey === "drinks" || staticKey === "meal") {
                                return <div className="col info-col">
                                  <h3> {iconsKeys[labelTextIndex]}</h3>
                                  <h4>{labelText[labelTextIndex]}</h4>
                                  <h5>{singleFlightFeatures?.features?.[labelIndex][staticKey].allowed ? <>Allowed<Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex][staticKey].text}>
                                    <BsFillInfoCircleFill />{" "}
                                  </Tooltip></> : "Restricted"}
                                  </h5>
                                </div>

                              }
                              else if (staticKey === "legRoom") {
                                return <div className="col info-col">
                                  <h3> {iconsKeys[labelTextIndex]}</h3>
                                  <h4>{labelText[labelTextIndex]}</h4>
                                  <h5>{singleFlightFeatures?.features?.[labelIndex][staticKey].allowed ? <>Allowed<Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex][staticKey].text}>
                                    <BsFillInfoCircleFill />{" "}
                                  </Tooltip></> : "Restricted"}
                                  </h5>
                                </div>

                              } else if (staticKey === "refund" || staticKey === "reIssue") {
                                return <div className="col info-col">
                                  <h3> {iconsKeys[labelTextIndex]}</h3>
                                  <h4>{labelText[labelTextIndex]}</h4>
                                  <h5>{singleFlightFeatures?.features?.[labelIndex][staticKey].allowed ? <>Allowed<Tooltip className="infoToolTip" placement="top" title={singleFlightFeatures?.features?.[labelIndex][staticKey].text}>
                                    <BsFillInfoCircleFill />{" "}
                                  </Tooltip></> : "Restricted"}
                                  </h5>
                                </div>

                              }

                            } else {
                              return "-"
                            }
                          })}

                          <div className="price-btn-wrapper">
                            {isFlightSoldOut?.message && ((isFlightSoldOut?.soldedFlights)?.includes(labelIndex)) ?
                              <div className="flight-sold-out-wrap">
                                <h2> Sold out </h2>
                                <h4>You just missed it!</h4>
                                <p>{isFlightSoldOut?.message}</p>
                              </div>
                              :
                              <>
                                <h4>Total <strong>{singleFlightFeatures?.price[labelIndex].currenncySymbol}{singleFlightFeatures?.price[labelIndex].totalPrice}</strong>/ Total</h4>
                                <button
                                  type="button"
                                  onClick={() => savePayload(singleFlightFeatures?.price[labelIndex].sequenceId, singleFlightFeatures?.price[labelIndex].cabinClassKey, singleFlightFeatures?.price[labelIndex].cabinClassKey, singleFlightFeatures?.price[labelIndex].totalPrice, labelIndex)}
                                  className="priceBtn btn"
                                >
                                  Select2
                                </button>
                              </>
                            }
                          </div>

                        </div>
                      </div>
                    </>
                  }

                })}
              </div>
            }
            {!singleFlightFeatures &&
              <div className="noRoomsAvailable">
                <h3>Search Expired</h3>
                <h5>The search session has expired. Do you want to refresh your search?</h5>
                <button type="button" onClick={() => reSearch()} className='btn btn-primary'>Search</button>
              </div>
            }
          </div>
        }
      </div>
    </div>



  </>
}
import React, { useState } from "react";
import Button from "../FormComponent/Button";
import FlightDetailsModal from "../Modals/FlightDetailsModal";
import SingleFlightDetails from "./SingleFlightDetails";

function FlightInfoBox({ legs, flights }) {
  const [sampleModal, setSampleModal] = useState(false);

  return (
    <div className="flightInfoBox card2">
      {flights?.flightSegments?.map((item, index) => {
        return (
          <SingleFlightDetails
            {...item}
            flightLegs={item}
            key={index}
            showFlightModal={sampleModal}
            setshowFlightModal={setSampleModal}
            flightSegments={item.flightLegs}
            segmentIndex={index}
          /> 
        );
      })}

      <div className="flightDetailsbtn">
        <Button
          type="button"
          className="detailsLink"
          onClick={() => setSampleModal(true)}
        >
          Flight Details <span className="fa fa-angle-down"></span>
        </Button>
      </div>
      {sampleModal && (
        <FlightDetailsModal
          isOpen={sampleModal}
          closeModal={setSampleModal}
          flights={flights}
          
        />
      )}
    </div>
  );
}

export default FlightInfoBox;

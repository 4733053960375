import React, { useState, useEffect, useRef } from "react";
import { Input } from "antd";
import SelectFieldOption from "../FormComponent/SelectFieldOption";
import FileUpload from "../../Assets/img/support/paper-clip.png";
import { Formik } from "formik";
import { supportHotelTabValidation } from "../Validation/supportHotelTabValidation.js";
import SupportPageModal from "../SupportPageModal";
import { useDispatch, useSelector } from "react-redux";
import { supportPageAction } from "../../Stores/Actions/supportPageActions";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import BasicModal from "../Modals/BasicModal";
import checkIcon from "../../Assets/img/icons/check.png";
import cancelIcon from "../../Assets/img/icons/cancel.png";

export default function SupportForm(props) {

  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    open: false,
    message: "",
    id: "",
  success: false,
  });
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const formikRef = useRef();

  const initValues = {
    booking_id: "",
    issue: "",
    description: "",
    attachments: null,
    type: props.type,
  };

  const [modalState, setModalState] = useState({
    open: false,
    isSuccessView: false,
    message: "success",
  });

  const issuesListData = useSelector(
    (state) => state.supportReducer?.issuesListData?.data
  );

  useEffect(() => {
    const payload = {
      type: props.type,
    };
    dispatch(supportPageAction.getIssueListByCategory(payload));
  }, []);
  const inputRef = useRef(null);

  const handleSubmit = async (formValues) => {
    const payload = new FormData();

    payload.append("issue", formValues.issue);

    if (formValues?.attachments) {
      payload.append("attachments", formValues?.attachments);
    }
    payload.append("description", formValues.description);

    if (props.type !== "membership") {
      payload.append("booking_id", formValues.booking_id);
    }

    payload.append("type", props.type);

    dispatch(supportPageAction.sendIssueToSupportTeam(payload))?.then((res) => {
      if (res?.status === 200 && res?.data?.success) {
        // alert(res?.data?.message);
        setModalData({
          open: true,
          message: res?.data?.message,
          id: res?.data?.id,
          success: true,
        });
        formikRef.current.setValues({
          booking_id: "",
          issue: "",
          description: "",
          attachments: null,
        });

        formikRef.current.setTouched({
          booking_id: false,
          issue: false,
          description: false,
          attachments: false,
        });
        inputRef.current._handleClear();
      } else {
        setModalData({
          open: true,
          message: res.response?.data?.message,
          id: res?.data?.id,
          success: false,
        });
      }
    });
  };

  const handleOnSearch = (query) => {
    const payload = {
      type: props.type,
      term: query,
    };
    dispatch(supportPageAction.getAutoCompletedData(payload)).then((res) => {
      if (res.status === 200) {
        setAutoCompleteOptions(res?.data?.data);
      }
    });
  };

  return (
    <>
      <BasicModal
        openModal={modalData.open}
        closeModal={() => {
          setModalData({
            open: false,
            message: null,
            id: "",
            success: false,
          });
        }}
        text={
          <>
            <h3>{modalData.message}</h3>
            {modalData?.id && (
              <p style={{ marginTop: "15px" }}>
                Your ticket number is #{modalData?.id}
              </p>
            )}
          </>
        }
        icon={modalData.success ? checkIcon : cancelIcon}
      />
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmit}
        validationSchema={supportHotelTabValidation()}
        innerRef={formikRef}
      >
        {(formikProps) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = formikProps;
          return (
            <div className="customer-portal-tab-ui">
              <form onSubmit={handleSubmit}>

              <div className="form-group">
                  <SelectFieldOption
                    placeholder={"Select your issue"}
                    options={issuesListData}
                    name="issue"
                    formikProps={formikProps}
                    error={errors.issue}
                    onBlur={() => {
                      formikProps.setTouched({
                        ...formikProps.touched,
                        issue: true,
                      });
                    }}
                    onChange={(formikProps, name, value) => {
                      formikProps.setFieldValue(name, value);
                    }}
                    touched={touched.issue}
                    value={values.issue}
                    isSearchable={true}
                    id="eee"
                  />
                </div>
                
                {props.type !== "membership" && (
                  <div className="form-group">
                    <AsyncTypeahead
                      ref={inputRef}
                      // autoFocus={autoFocus}
                      // inputProps={{ className: "inputDropdown widget-dropdown" }}
                      // onInputChange={onInputChange}
                      labelKey={(option) => {
                        if (props.type === "hotel") {
                          return option?.hotel_name;
                        } else if (props.type === "flight") {
                          return ` ${option?.departure_airport_code} - ${option?.arriaval_airport_Code} (${option?.booking_ref_id}) `;
                        }
                      }}
                      // isLoading={isLoading}
                      filterBy={() => true}
                      minLength={3}
                      onSearch={(query) => handleOnSearch(query)}
                      options={autoCompleteOptions}
                      placeholder={
                        "Enter your Itinerary / Booking / Reference ID (TRIP-...)" 
                      }
                      // cache={false}
                      onChange={(selected) => {
                        formikProps.setFieldValue(
                          "booking_id",
                          selected[0]?.id
                        );
                      }}
                      clearButton
                      searchText="Searching..."
                      name="booking_id"
                      id={"booking_id"}
                      // dropup={!isMobile}
                      // disabled={disabled}
                      emptyLabel="Type to search..."
                      // className="inputDropdown"
                      renderMenuItemChildren={(option) => (
                        <div className="dorpdown-content">
                          <div className="dorpdown-item">
                            {/* options UI for hotel tab */}
                            {(props.type === "hotel" || props.type === "home") && (
                              <>
                                <h3 className="dropdown-title">
                                  {option.hotel_name}
                                </h3>
                                <h3 className="dropdown-title">
                                  {option.address}
                                </h3>
                                <h4 className="booking-id-content">
                                  {option.booking_ref_id}
                                  <span className="dot"></span>
                                  {option.status} <span className="dot"></span>
                                  {option.check_in_date}
                                </h4>
                              </>
                            )}

                            {/* options UI for flight tab */}
                            {props.type === "flight" && (
                              <>
                                <h3 className="dropdown-title">
                                  {option.departure_city}-{option.arrival_city}
                                </h3>
                                <h4 className="booking-id-content">
                                  {option.booking_ref_id}
                                  <span className="dot"></span>
                                  {option.status} <span className="dot"></span>
                                  Departure {option.departureDate}
                                </h4>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    />
                    {errors.booking_id && touched.booking_id && (
                      <p className="error-messages">{errors.booking_id}</p>
                    )}
                  </div>
                )}
                
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Enter details or descriptions here"
                    onBlur={handleBlur}
                    name="description"
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                    value={values.description}
                  ></textarea>
                  {errors.description && touched.description && (
                    <p className="error-messages">{errors.description}</p>
                  )}
                </div>
                <div className="form-footer">
                {!values?.attachments && (
                  <div className="file-upload">
                    <img src={FileUpload} alt="" />
                    <label htmlFor="upload-photo">
                      Add attachment <small>(png, jpg, pdf)</small>
                    </label>
                    <input 
                    // className={`${values?.attachments} ? "disabled":""`}
                    //  disabled={values?.attachments}
                      type="file"
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "attachments",
                          e.target.files.item(0)
                        );
                      }}
                      name="photo"
                      id="upload-photo"
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    />
                  </div>
                 )} 
                  {values?.attachments && (
                    <span className="fileNameDisplay">
                      {values?.attachments.name || ""}
                      <button
                        type="button"
                        onClick={() => {
                          formikProps.setFieldValue("attachments", null);
                        }}
                        // className='btn btn-submit'
                      >
                        x
                      </button>
                    </span>

                  )}
                  <button
                    disabled={errors?.length > 0}
                    type="submit"
                    className="btn-submit btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          );
        }}
      </Formik>
      {/* <SupportPageModal
        open={modalState.open}
        closeModal={() => {
          setModalState({ ...modalState, open: false });
        }}
        isSuccessView={modalState.isSuccessView}
        message={modalState.message}
      /> */}
    </>
  );
}

import React, { useState,useEffect } from "react";
import { Input } from 'antd';
import SelectFieldOption from "./../FormComponent/SelectFieldOption";
import FileUpload from "../../Assets/img/support/paper-clip.png"
import { vHomesIssuesOptions } from "../../Util/staticData";
import { Formik } from "formik";
import { supportHotelTabValidation } from "../../Component/Validation/supportHotelTabValidation.js"
import axios from "../../api/http";
import SupportPageModal from "../SupportPageModal";
import { useDispatch, useSelector } from "react-redux";
import {supportPageAction} from "../../Stores/Actions/supportPageActions"

const initValues = {
  itineraryId: "",
  issue: "",
  discription: ""
}

export default function HotelTab() {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null)
  const [modalState, setModalState] = useState({
    open: false,
    isSuccessView: false,
    message: "success"
  })

  const issueListData = useSelector((state) => state.supportReducer?.issuesListData?.data);
  useEffect(()=>{
    const payload={
      type:"v_homes"
    }
    dispatch(supportPageAction.getIssueListByCategory(payload))
  },[])

  const handleSubmit = async (values) => {
    // console.log("submited values", values);
    try {
      const result = await axios.post(`/omkar/test/`,
        {
          ...values,
          params: {
          }
        }
      )
      // console.log("result", result);
      setModalState({ ...modalState, open: true, message: "success", isSuccessView: true })
    } catch (error) {
      // console.log("error", error.response);
      setModalState({ open: true, message: error.response.data, isSuccessView: true })
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files.item(0);
    setFile(file);
  }

  const clearFiles = () => {
    setFile({});
  }

  return (
    <>
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmit}
        validationSchema={supportHotelTabValidation()}
      >
        {(formikProps) => {
          const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formikProps;

          return <div className="customer-portal-tab-ui">
            {/* <form onSubmit={handleSubmit}>
              <div className="form-group">
                <Input
                  placeholder="Enter your Itinerary / Booking / Reference ID"
                  className="form-control"
                  name="itineraryId"
                  onChange={(e) => setFieldValue("itineraryId", e.target.value)}
                  onBlur={handleBlur}
                  value={values.itineraryId}
                />
                {errors.itineraryId && touched.itineraryId && <p className="error-messages">{errors.itineraryId}</p>}
              </div>
              <div className="form-group">
                <SelectFieldOption
                  placeholder={"Select your issue"}
                  options={issueListData}
                  name="issue"
                  formikProps={formikProps}
                  error={errors.issue}
                  onBlur={() => {
                    formikProps.setTouched({ ...formikProps.touched, "issue": true });
                  }}
                  onChange={(formikProps, name, value) => {
                    formikProps.setFieldValue(name, value)
                  }}
                  touched={touched.issue}
                  value={values.issue}
                  isSearchable={true}
                  id="eee"
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Enter details or descriptions here"
                  onBlur={handleBlur}
                  name="discription"
                  onChange={(e) => {
                    setFieldValue("discription", e.target.value)
                  }}
                  value={values.discription}
                >
                </textarea>
                {errors.discription && touched.discription && <p className="error-messages">{errors.discription}</p>}
              </div>
              <div className="form-footer">
                <div className="file-upload">
                  <img src={FileUpload} alt="" />
                  <label htmlFor="upload-photo">Add attachment <small>(png, jpg, pdf)</small></label>
                  <input type="file" onChange={handleFileChange} name="photo" id="upload-photo" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" />
                </div>
                {file?.name &&
                  <span>{file?.name || ""}<button type="button" onClick={clearFiles}>x</button></span>
                }
                <button disabled={errors?.length > 0} type="submit" className="btn-submit btn">Submit</button>
              </div>
            </form> */}
            <div class="coming-soon-wrap">Coming Soon...</div>
          </div>
        }}

      </Formik>
      <SupportPageModal
        open={modalState.open}
        closeModal={() => {
          setModalState({ ...modalState, open: false })
        }}
        isSuccessView={modalState.isSuccessView}
        message={modalState.message}
      />
    </>
  );
}

import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CloseBtn from "../../Assets/img/close-blue-icon.png";
import EditBtn from "../../Assets/img/profile/edit.svg"
import { countries, checkoutCountries } from "../../Data/static.service";
import { authActions } from "../../Stores/Actions";
import DateInput from "../DatePicker/DateInput";
import SelectFieldOption from "../FormComponent/SelectFieldOption";
import { addUserValidation } from "../Validation/ProfileValidation";
import CustomPhoneInput from "../../Component/PhoneInput";
import { handleMaskedInput } from "../../Util/util";

function AddGuestModal({ showAddUserFinalModal, setShowAddUserFinalModal, guest }) {

  const scrollToElementRef = useRef(null);
  const scrollToPassortRef = useRef(null);
  const dispatch = useDispatch();
  const addGuessError = useSelector((state) => state.authReducer.addGuessError);

  const [customExpiryDate, setCustomExpiryDate] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const countryOptions = countries.map((ele) => {
    return { value: ele.sortname, label: ele.name, id: ele.id };
  });
  // const defaultCountry = 

  const optionsTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    // { value: "Neutral", label: "Neutral." },
  ];

  const optionsGender = [
    { value: "M", label: "Male " },
    { value: "F", label: "Female" },
    { value: "C", label: "Other" },
  ];

  const addUserInitialValue = {
    title: guest ? guest.title : "",
    firstName: guest ? guest.fname : "",
    lastName: guest ? guest.lname : "",
    gender: guest ? guest.gender : "",
    email: guest ? guest.email : "",
    birthday: guest?.dob || "",
    phone: guest && guest.phone ? guest.phone : "",
    passportNo: guest ? guest.passport_number : null,
    country: guest ? guest.country : null,
    expiryDate: guest && guest?.passport_exp_month ? `${(Number(guest.passport_exp_month)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}/${(guest.passport_exp_year).slice(2)}` : "",
  };

  const handleCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value);
  };

  const scrollToView = (location) => {

    if (location === "basicInfo") {
      scrollToElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    } else if (location === "passport") {
      scrollToPassortRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }

  const handleAddUserSubmit = (value, resetForm) => {
    const localUserData = JSON.parse(localStorage.getItem("user"))
    if (customExpiryDate) {
      return;
    }
    if (guest) {
      const payload = {
        title: value.title,
        given_name: value.firstName,
        lname: value.lastName,
        gender: value.gender,
        passport_number: value.passportNo ? value.passportNo : null,
        passport_exp_year: value.expiryDate.split("/")[1] ? Number("20" + value.expiryDate.split("/")[1]) :null,
        passport_exp_month: value.expiryDate ?  Number(value.expiryDate.slice(0, 2)) : null,
        dob: moment(value.birthday).format("YYYY-MM-DD"),
        passport_issuing_country:value?.country ?  countries.find((ele) => ele?.sortname === value?.country).sortname : null,
        country:value?.country ?  countries.find((ele) => ele?.sortname === value?.country).sortname : null,
        phone: value.phone,
        email: value.email ? value.email : null,
      }
      dispatch(
        authActions.updateGuestAction(
          payload,
          localUserData?.user_id,
          guest.id,
          setShowAddUserFinalModal,
        )
      )
    } else {
      const payload = {
        title: value.title,
        given_name: value.firstName,
        lname: value.lastName,
        passport_number: value.passportNo ? value.passportNo : null,
        // passport_exp_year: Number("20" + value.expiryDate.split("/")[1]),
        // passport_exp_month: Number(value.expiryDate.slice(0, 2)),
        passport_exp_year: value.expiryDate ? Number("20" + value.expiryDate.split("/")[1]) :null,
        passport_exp_month: value.expiryDate ?  Number(value.expiryDate.slice(0, 2)) : null,
        gender: value.gender,
        email: value.email ? value.email : null,
        phone: value.phone,
        dob: moment(value.birthday).format("YYYY-MM-DD"),
        // country: value?.country ? countries.find((ele) => ele?.sortname === value?.country).sortname : "",
        passport_issuing_country:value?.country ?  countries.find((ele) => ele?.sortname === value?.country).sortname : null,
        country:value?.country ?  countries.find((ele) => ele?.sortname === value?.country).sortname : null,
      
        type: 1,
      };
      dispatch(
        authActions.addUserAction(
          payload,
          localUserData?.user_id,
          setShowAddUserFinalModal,
          resetForm
        )
      )
    }
  };


  return (
    <Modal
      isOpen={showAddUserFinalModal}
      ariaHideApp={false}
      className={`popup save-traveller-popup add-save-traveller-popup ${showAddUserFinalModal ? "d-block" : ""
        }`}
    >
      <div className="popup-content">
        <div className="popup-title">
          <h3>Add User Information </h3>
          {addGuessError && (
            <p style={{ color: "red", margin: "10px" }}>{addGuessError}</p>
          )}
          <button
            className="btn close save-traveller-popup-close-btn"
            onClick={() => setShowAddUserFinalModal(false)}
          >
            <img src={CloseBtn} alt="icon" />
          </button>
        </div>
        <Formik
          onSubmit={(values, { resetForm }) => {
            handleAddUserSubmit(values, resetForm);
          }}
          validationSchema={addUserValidation}
          initialValues={addUserInitialValue}
          enableReinitialize={true}
        >
          {(formikProps) => {
            const { values, handleBlur, touched, errors } = formikProps;
            return (
              <Form>
                <div className="popup-content-box">
                  <div className="toggle-links-wrap">
                    <div className="toggle-links">
                      <ul>
                        <li>
                          <Link
                            className={
                              !window.location.href.includes("#passport")
                                ? "active"
                                : ""
                            }
                            onClick={() => scrollToView("basicInfo")}
                            to="#basic-info"
                          >
                            Basic Info
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#passport"
                            onClick={() => scrollToView("passport")}
                            className={
                              window.location.href.includes("#passport")
                                ? "active"
                                : ""
                            }
                          >
                            Passport
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {guest && disabled &&
                      <button
                        type="button"
                        className="btn delete-btn"
                        onClick={() => setDisabled(false)}
                      >
                        <img src={EditBtn} alt="icon" color=" red" />
                        Change
                      </button>
                    }
                    {guest && !disabled &&
                      <button
                        type="button"
                        className="btn delete-btn"
                        onClick={() => setDisabled(true)}
                      >
                        Cancel
                      </button>
                    }
                  </div>
                  <div className="information-content-wrap">
                    <div ref={scrollToElementRef} className="information-content-wrap-inner">
                      <div id="basic-info" className="info-card">
                        <div className="info-heading">
                          <h3>Basic Information</h3>
                          <p>
                            Basic info, like your email and number that you use
                            on Personal Profile{" "}
                          </p>
                        </div>
                        <div className="row first-row-content">
                          <div className="col col-content">
                            <div className="formGroup dropdown title-field">
                              <SelectFieldOption
                                name={"title"}
                                label={"Title"}
                                disabled={guest}
                                value={values.title}
                                options={optionsTitle}
                                placeholder={"Select Title"}
                                onChange={handleCustomChange}
                                onBlur={handleBlur}
                                error={errors.title}
                                touched={touched.title}
                                isSearchable={false}
                                formikProps={formikProps}
                                className="inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />
                            </div>
                            <div className="formGroup">
                              <h5>First Name</h5>
                              <input
                                disabled={guest}
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                className="formControl"
                                value={values.firstName}
                                touched={touched.firstName}
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  handleCustomChange(
                                    formikProps,
                                    "firstName",
                                    e.target.value
                                  )
                                }
                              />
                              {errors?.firstName && touched?.firstName && (
                                <div className="inputStyle error-messages">
                                  {errors?.firstName}
                                </div>
                              )}
                            </div>
                            <div className="formGroup">
                              <h5>Last Name</h5>
                              <input
                                disabled={guest}
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                className="formControl"
                                value={values.lastName}
                                touched={touched.lastName}
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  handleCustomChange(
                                    formikProps,
                                    "lastName",
                                    e.target.value
                                  )
                                }
                              />
                              {errors?.lastName && touched?.lastName && (
                                <div className="inputStyle error-messages">
                                  {errors?.lastName}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-content">
                            <div className="formGroup dropdown">
                              <SelectFieldOption
                                disabled={guest}
                                name={"gender"}
                                label={"Gender"}
                                value={values.gender}
                                options={optionsGender}
                                placeholder={"Select Gender"}
                                onChange={handleCustomChange}
                                onBlur={handleBlur}
                                error={errors.gender}
                                touched={touched.gender}
                                formikProps={formikProps}
                                className="inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />
                            </div>
                            <div className="formGroup">
                              <h5>Email</h5>
                              <input
                                // disabled={guest}
                                type="email"
                                name="email"
                                placeholder="Email"
                                className="formControl"
                                onBlur={handleBlur}
                                touched={touched.email}
                                value={values.email}
                                onChange={(e) =>
                                  handleCustomChange(
                                    formikProps,
                                    "email",
                                    e.target.value
                                  )
                                }
                              />
                              {errors?.email && touched?.email && (
                                <div className="inputStyle error-messages">
                                  {errors?.email}
                                </div>
                              )}
                            </div>
                            <div className="formGroup">
                              <h5>Birthday</h5>
                              <DateInput
                                disabled={guest}
                                {...formikProps}
                                numberOfMonths={1}
                                placeholder="Birthday"
                                value={values.birthday}
                                icon="icon-invitation"
                                name="birthday"
                                errors={errors.birthday}
                                touched={touched.birthday}
                                onDateChange={(e) =>
                                  handleCustomChange(formikProps, "birthday", e)
                                }
                                focused={isFocused} // PropTypes.bool
                                onFocusChange={({ focused }) =>
                                  setIsFocused(!isFocused)
                                }
                                isRequired

                                // isDayBlocked={(day) => day.isAfter(moment().subtract("18","years"))} 

                                isOutsideRange={(day) => day.isAfter(moment().subtract("18", "years"))}

                                renderMonthYearSelector={true}

                              />
                              {errors?.birthday && touched?.birthday && (
                                <div className="inputStyle error-messages">
                                  {errors?.birthday}
                                </div>
                              )}
                            </div>
                            <div className="formGroup">

                              <CustomPhoneInput
                                inputProps={{
                                  name: "phone",
                                }}
                                value={values.phone}
                                onChange={(phone) => {
                                  handleCustomChange(formikProps, "phone", phone)
                                }}
                                error={errors.phone}
                                touched={touched.phone}
                                onBlur={handleBlur}
                                label={"Phone Number"}
                                disabled={guest && disabled}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <span ref={scrollToPassortRef}></span>
                      <div id="passport" className="info-card">
                        <div className="info-heading">
                          <h3>Passport Details</h3>
                          <p>
                            Add your Passport details for a faster flight
                            booking experience
                          </p>
                        </div>
                        <div className="row">
                          <div className="col left-content">
                            <div className="formGroup">
                              <h5>Passport Number</h5>
                              <input
                                disabled={guest && disabled}
                                type="text"
                                name="passportNo"
                                placeholder="Passport Number"
                                className="formControl"
                                value={values.passportNo}
                                touched={touched.passportNo}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  if (e.target.value?.length > 10) return
                                  handleCustomChange(
                                    formikProps,
                                    "passportNo",
                                    e.target.value
                                  )
                                }
                                }
                              />
                              {errors?.passportNo && touched?.passportNo && (
                                <div className="inputStyle error-messages">
                                  {errors?.passportNo}
                                </div>
                              )}
                            </div>
                            <div className="formGroup">
                              <h5>Expiry Date</h5>
                              <input
                                disabled={guest && disabled}
                                type="text"
                                className="formControl"
                                placeholder="MM/YY"
                                name="expiryDate"
                                value={values.expiryDate}
                                touched={touched.expiryDate}
                                onChange={(e) => {
                                  const maskedValue = handleMaskedInput(e.target.value);
                                  if (e.target.value.length > 5 || !maskedValue) return
                                  if (maskedValue?.error) {
                                  } else {
                                    formikProps.setFieldValue("expiryDate", maskedValue?.value);
                                  }
                                }}
                              />
                              {errors?.expiryDate && touched?.expiryDate && (
                                <div className="inputStyle error-messages">
                                  {errors?.expiryDate}
                                </div>
                              )}
                              {customExpiryDate && (
                                <div className="inputStyle error-messages">
                                  {customExpiryDate}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col right-content">
                            <div className="formGroup dropdown">
                              <SelectFieldOption
                                disabled={guest && disabled}
                                name={"country"}
                                label={"Select Country"}
                                value={values.country}
                                options={countryOptions}
                                placeholder={"Select Country"}
                                onChange={handleCustomChange}
                                onBlur={handleBlur}
                                error={errors.country}
                                touched={touched.country}
                                formikProps={formikProps}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="popup-footer">
                  <div className="button-wrap">
                    {(!disabled || !guest) &&
                      <button
                        type="submit"
                        className="btn blue-btn save-traveller-popup-close-btn"
                      >
                        Save
                      </button>
                    }
                    <button
                      type="button"
                      className="btn orange-btn
												save-traveller-popup-close-btn"
                      onClick={() => setShowAddUserFinalModal(false)}
                    >
                      {
                        (!guest)
                          ? "Cancel"
                          : "Close"
                      }
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}

export default AddGuestModal;
import dollarImage from "../Assets/img/doller-box-grey.png"


const CommissionsTabMenuData = [
    {
      id:0,
      menuTitle: "Freemium Member Travel",
      image : dollarImage,
      price: "$405.00",
      href:"#member-travel"
    },
    {
      id:1,
      menuTitle: "Member to Member Referrals",
      image : dollarImage,
      price: "$51.00",
      href:"#member-referral"
    },
    {
      id:2,
      menuTitle: "Premium Member Sales",
      image : dollarImage,
      price: "$2950.00",
      href:"#member-sales"
    },
    {
      id:3,
      menuTitle: "Premium Plus Member Sales",
      image : dollarImage,
      price: "$410.00",
      href:"#premium-travel"
    },
    {
      id:4,
      menuTitle: "Affiliate Member Sales",
      image : dollarImage,
      price: "$236.00",
      href:"#affiliate-sales"
    },
  ];
  
  const DashboardData = [
    {
      title: "Total Commission",
      amount: "$8257.15",
      className:"col border-bottom"
    },
    {
      title: "Total Commission",
      subtitle: "Paid Out",
      amount: "$2,324.44",
      className:"col border-bottom border-left"
    },
    {
      title: "Total Commission",
      subtitle: "Pending",
      amount: "$5,932.71",
      className:"col border-bottom border-left"
    },
    {
      title: "Total Freemium Membership",
      subtitle: "Sign-Up",
      amount: "200",
      className:"col border-bottom border-left"
    },
    {
      title: "Total Premium Membership",
      subtitle: "Sign-Up",
      amount: "25",
      className:"col border-bottom"
    },
    {
      title: "Total Premium Plus Membership",
      subtitle: "Sign-Up",
      amount: "25",
      className:"col border-bottom border-left"
    },
    {
      title: "Freemium to Premium",
      subtitle: "Conversion %",
      amount: "12.5 %",
      className:"col border-bottom border-left"
    },
    {
      title: "Total Affiliate Membership",
      subtitle: "Sign-Up",
      amount: "2",
      className:"col border-bottom border-left"
    },
  ];
  

const tottalCommission = {title:"Total Commission",amount:"$8,257.15"}

const totalCommissionPaidOut = {title:"Total Commission",subtitle:"Paid Out",amount:"$2324.44"}

const totalCommissionPending= {title:"Total Commission",subtitle:"Pending",amount:"$5932.71"}

const freemiumMemberShip = {title:"Total Freemium Membership",subtitle:"Sign-Up",amount:"200"}

const premiumMembership = {title:"Total Premium Membership",subtitle:"Sign-Up",amount:"25"}

const plusMembership = {title:"Total Premium Plus Membership",subtitle:"Sign-Up",amount:"25"}

const premiumConversion = {title:"Freemium to Premium",subtitle:"Conversion %",amount:"12.5 %"}

const affiliateMember = {title:"Total Affiliate Membership",subtitle:"Sign-Up",amount:"2"}




  const CommissionTabHeadingData = [
    {
    id: 0,
    idName: "member-travel",
    heading: "Freemium Member Travel",
    total:"589",
    tableHeading:["FREEMIUM MEMBER NO","BOOKING DATE","TRAVEL DATE","TRAVEL SAVINGS","COMMISSION","STATUS"
    ],
    data:[  
      [
       {title:"FREEMIUM MEMBER NO",value:"637322829"},
     {title:"BOOKING DATE",value:"03/12/2019"},
      {title:"TRAVEL DATE",value:"03/12/2019"},
     {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
      {title:"COMMISSION",value:"$185.00"},
      {title:"STATUS",value:"PAID"}
    ]
    ,
    [
      {title:"FREEMIUM MEMBER NO",value:"637322829"},
     {title:"BOOKING DATE",value:"03/12/2019"},
      {title:"TRAVEL DATE",value:"03/12/2019"},
     {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
      {title:"COMMISSION",value:"$185.00"},
      {title:"STATUS",value:"PAID"}
    ],
    [
      {title:"FREEMIUM MEMBER NO",value:"637322829"},
     {title:"BOOKING DATE",value:"03/12/2019"},
      {title:"TRAVEL DATE",value:"03/12/2019"},
     {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
      {title:"COMMISSION",value:"$185.00"},
      {title:"STATUS",value:"PAID"}
    ]
      ,
      [
        {title:"FREEMIUM MEMBER NO",value:"637322829"},
       {title:"BOOKING DATE",value:"03/12/2019"},
        {title:"TRAVEL DATE",value:"03/12/2019"},
       {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
        {title:"COMMISSION",value:"$185.00"},
        {title:"STATUS",value:"PAID"}
      ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"jndsnkjcsdnjd"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"++++++++++++"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"********"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"PAID"}
        ]
        ,
        [
          {title:"FREEMIUM MEMBER NO",value:"637322829"},
         {title:"BOOKING DATE",value:"03/12/2019"},
          {title:"TRAVEL DATE",value:"03/12/2019"},
         {titile:"TRAVEL SAVINGS",value:"$1,118.00"},
          {title:"COMMISSION",value:"$185.00"},
          {title:"STATUS",value:"#####"}
        ]
          
   
  ]
  },
  {
    id: 1,
    idName: "member-referral",
    heading: "Member to Member Referrals",
    total:"3",
    tableHeading:["REFERRING MEMBER NO","NEW MEMBER NO","ACTIVATION DATE","COMMISSION","STATUS"
    ],
    data:[
      [
       {title:"REFERRING MEMBER NO",value:"637322829"},
       {title:"NEW MEMBER NO",value:"637322829"},
       {title:"ACTIVATION",value:"03/12/2019"},
       {title:"COMMISSION",value:"$120.00"},
       {title:"STATUS",value:"PAID"},
      ],
      [
        {title:"REFERRING MEMBER NO",value:"637322829"},
       {title:"NEW MEMBER NO",value:"637322829"},
       {title:"ACTIVATION",value:"03/12/2019"},
       {title:"COMMISSION",value:"$120.00"},
       {title:"STATUS",value:"PAID"},
      ]
     ,
     [
      {title:"REFERRING MEMBER NO",value:"637322829"},
       {title:"NEW MEMBER NO",value:"637322829"},
       {title:"ACTIVATION",value:"03/12/2019"},
       {title:"COMMISSION",value:"$120.00"},
       {title:"STATUS",value:"PAID"},
    ],
    ]
  },
  {
    id: 2,
    idName: "member-sales",
    heading: "Premium Member Sales",
    total:"3",
    tableHeading:["PREMIUM MEMBER NO","ACTIVATION DATE","COMMISSION","STATUS"
    ],
    data:[
      [
        {title:"PREMIUM MEMBER NO",value:"637322829"},
       {title:"ACTIVATION",value:"03/12/2019"},
       {title:"COMMISSION",value:"$100.00"},
        {title:"STATUS",value:"PAID"},
      ],
      [
        {title:"PREMIUM MEMBER NO",value:"637322829"},
        {title:"ACTIVATION",value:"03/12/2019"},
        {title:"COMMISSION",value:"$100.00"},
         {title:"STATUS",value:"PAID"},,
      ],
      [
        {title:"PREMIUM MEMBER NO",value:"637322829"},
       {title:"ACTIVATION",value:"03/12/2019"},
       {title:"COMMISSION",value:"$100.00"},
        {title:"STATUS",value:"PAID"},,
      ],
      [
        {title:"PREMIUM MEMBER NO",value:"637322829"},
       {title:"ACTIVATION",value:"03/12/2019"},
       {title:"COMMISSION",value:"$100.00"},
        {title:"STATUS",value:"PAID"},,
      ],
      [
        {title:"PREMIUM MEMBER NO",value:"637322829"},
       {title:"ACTIVATION",value:"03/12/2019"},
       {title:"COMMISSION",value:"$100.00"},
        {title:"STATUS",value:"PAID"},,
      ],
    ]
  },
  {
    id: 3,
    idName: "premium-travel",
    heading: "Premium Plus Member Sales",
    total:"3",
    tableHeading:["PREMIUM PLUS MEMBER NO","ACTIVATION DATE","COMMISSION","STATUS"
    ],
    data:[
      [
      {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
      ],
      [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
       ],
       [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
       ],
       [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
       ]
    ]
  },
  {
    id: 4,
    idName: "affiliate-sales",
    heading: "Affiliate Sales",
    total:"3",
    tableHeading:["AFFILIATE NO","ACTIVATION DATE","COMMISSION","STATUS"
    ],
    data:[
      [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
        {title:"ACTIVATION",value:"03/12/2019"},
         {title:"COMMISSION",value:"$510.00"},
        {title:"STATUS",value:"PAID"},
      ],
      [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
      ],
      [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
      ],
      [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
      ],
      [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
      ],
      [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
      ],
      [
        {title:"PREMIUM PLUS MEMBER NO",value:"637322829"},
     {title:"ACTIVATION",value:"03/12/2019"},
      {title:"COMMISSION",value:"$510.00"},
     {title:"STATUS",value:"PAID"},
      ],
    ]
  }
]

const FreemiumMemberTravelTableData=[
  {
  memberNo:"637322829",
  bookingDate:"03/12/2019",
  date:"03/12/2019",
  travelSaving:"$1,118.00",
  commission:"$185.00",
  status:"PAID"
},
{
  memberNo:"723472384",
  bookingDate:"03/12/2021",
  date:"03/12/2021",
  travelSaving:"$2,210.00",
  commission:"$110.00",
  status:"PENDING"
},
{
  memberNo:"247823483",
  bookingDate:"03/12/2021",
  date:"03/12/2021",
  travelSaving:"$1,104.00",
  commission:"$105.00",
  status:"PAID"
},
{
  memberNo:"637322829",
  bookingDate:"03/12/2019",
  date:"03/12/2019",
  travelSaving:"$1,118.00",
  commission:"$185.00",
  status:"PAID"
},
{
  memberNo:"723472384",
  bookingDate:"03/12/2021",
  date:"03/12/2021",
  travelSaving:"$2,210.00",
  commission:"$110.00",
  status:"PENDING"
},
{
  memberNo:"247823483",
  bookingDate:"03/12/2021",
  date:"03/12/2021",
  travelSaving:"$1,104.00",
  commission:"$105.00",
  status:"PAID"
},
{
  memberNo:"637322829",
  bookingDate:"03/12/2019",
  date:"03/12/2019",
  travelSaving:"$1,118.00",
  commission:"$185.00",
  status:"PAID"
},
{
  memberNo:"723472384",
  bookingDate:"03/12/2021",
  date:"03/12/2021",
  travelSaving:"$2,210.00",
  commission:"$110.00",
  status:"PENDING"
},
{
  memberNo:"247823483",
  bookingDate:"03/12/2021",
  date:"03/12/2021",
  travelSaving:"$1,104.00",
  commission:"$105.00",
  status:"PAID"
},
{
  memberNo:"247823483",
  bookingDate:"03/12/2021",
  date:"03/12/2021",
  travelSaving:"$1,104.00",
  commission:"$105.00",
  status:"PAID"
}
]

const memberReferrals = [
  {
    memberNo: "637322829",
    newMemberNo: "637322829",
    date: "03/12/2019",
    commission: "$185.00",
    status: "PAID",
  },
  {
    memberNo: "723472384",
    newMemberNo: "723472384",
    date: "03/12/2021",
    commission: "$110.00",
    status: "PENDING",
  },
  {
    memberNo: "247823483",
    newMemberNo: "247823483",
    date: "03/12/2021",
    commission: "$105.00",
    status: "PAID",
  },
];

const PremiumMemberSales = [
  {
    memberNo: "637322829",
    date: "03/12/2019",
    commission: "$185.00",
    status: "PAID",
  },
  {
    memberNo: "723472384",
    date: "03/12/2021",
    commission: "$110.00",
    status: "PENDING",
  },
  {
    memberNo: "247823483",
    date: "03/12/2021",
    commission: "$105.00",
    status: "PAID",
  },
];

const PremiumPlusMemberSales = [
  {
    memberNo: "637322829",
    date: "03/12/2019",
    commission: "$185.00",
    status: "PAID",
  },
  {
    memberNo: "723472384",
    date: "03/12/2021",
    commission: "$110.00",
    status: "PENDING",
  },
  {
    memberNo: "247823483",
    date: "03/12/2021",
    commission: "$105.00",
    status: "PAID",
  },
];

const AffiliateMemberSales = [
  {
    memberNo: "637322829",
    date: "03/12/2019",
    commission: "$185.00",
    status: "PAID",
  },
  {
    memberNo: "723472384",
    date: "03/12/2021",
    commission: "$110.00",
    status: "PENDING",
  },
  {
    memberNo: "247823483",
    date: "03/12/2021",
    commission: "$105.00",
    status: "PAID",
  },
];


const paging = {
  page_number: 1, per_page: 15, records_on_page: 15, total_pages: 15, total_records: 222,
  }

  // paging: {
  //   page_number: 1; per_page: 15; records_on_page: 15; total_pages: 15;    total_records: 222;
  //   }
  
export {CommissionsTabMenuData,DashboardData,CommissionTabHeadingData,FreemiumMemberTravelTableData,tottalCommission,totalCommissionPaidOut,totalCommissionPending,freemiumMemberShip,premiumMembership,plusMembership,premiumConversion,affiliateMember,paging}  
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hotelActions } from "../../Stores/Actions";
import FllightDetails from "./FllightDetails";
import HotelDetails from "./HotelDetails";

function Failed() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(hotelActions.getFailedBookings("failed"));
  // }, []);
  const failedBookingsData = useSelector(
    (state) => state.hotelReducer.failedBookings
  );
  return failedBookingsData?.length > 0 ? (
    <div className="mainWrap booking">
      {failedBookingsData &&
        failedBookingsData.map((item, index) => {
          if (item.booking_type === "hotel" || item.booking_type === "weeks") {
            return <HotelDetails {...item} status={"FAILED"} key={`${index}hotel`} />;
          } else if (item.booking_type === "flight") {
            return (
              <FllightDetails
                {...item}
                status={"FAILED"}
                key={`${index}flight`}
              />
            );
          }else if (item.booking_type === "car") {
            return <CarDetails {...item} status={"FAILED"} key={`${index}car`} />;
          }
        })}
    </div>
  ) : (
    <div className="not-found-booking">
      <h2>No Bookings</h2>
      <p>You have no pending bookings.</p>
      <p>Start your amazing journey with a new booking.</p>
      <Link to={"/landing"} className="btn">Back To Home</Link>
    </div>
  );
}

export default Failed;

import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import TermsTab from "../Tabs/TermsTab";

function TermAndCondition() {
  return (
    <div className="siteBg FaqPageWrap Terms-wrapper">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar noHeader={!localStorage.getItem("accessToken") ? true : false} />
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <TermsTab />
              <div className="col rightContent terms-condition-content content-wrapper">
                <div className="card2">
                  <div className="heading-address-section">
                    <div className="row no-gutters">
                      <div className="col col-8 col-heading">
                        <h1>TERMS AND CONDITIONS</h1>
                        <address>
                          Trippelo Inc. <br />
                          4730 S. Fort Apache Rd <br />
                          Ste 300<br />
                          Las Vegas NV 89147 <br />
                          <a href="mailto:membersupport@tripello.com">membersupport@tripello.com.</a>
                        </address>
                        <h2>1. Acceptance of the Terms of Use</h2>
                        <h4>Welcome to the website of Tripello, Inc.</h4>
                      </div>
                      <div className="col col-4 col-address">
                        <address>
                          Trippelo Inc. <br />
                          4730 S. Fort Apache Rd <br />
                          Ste 300<br />
                          Las Vegas NV 89147 <br />
                          <a href="mailto:membersupport@tripello.com">membersupport@tripello.com.</a>
                        </address>
                      </div>
                    </div>
                  </div>

                  <p>Tripello, Inc. provides an online subscription service that allows members to access, and
                    book discounted travel products available through a custom designed portal. These
                    terms of use are entered into by and between You and Tripello, Inc. (“Company”, “we”
                    or “us”). The following terms and conditions, together with any documents they
                    expressly incorporate by reference (collectively, these “Terms of Use”), govern your
                    access to and use of the <a target="_blank" href="https://tripello.com"> www.Tripello.com</a>, <a target="_blank" href="https://tripello.com/blog/">www.Tripellohub.com</a>, and
                    <a target="_blank" href="https://tripellonetwork.com"> www.tripellonetwork</a> websites (the “Website”) as a guest or a registered member,
                    your decision to receive text or email messages from us (Electronic Messaging), and, if a
                    membership is purchased, the terms of membership, promotions, billing, and
                    cancellation applicable to your chosen subscription. The Website and Electronic
                    Messaging, along with membership, promotional, billing, and cancellation terms if a
                    membership is purchased, are collectively referred to in this policy as the “Services” as
                    described further within section 2 below.</p>
                  <p>Please read the Terms of Use carefully before you start to use the Services. By using the
                    Services, you accept and agree to be bound and abide by these Terms of Use and
                    Company’s Privacy Policy, found at <a target="_blank" href="https://tripello.com/terms-conditions">https://tripello.com/terms-conditions</a> Privacy
                    Policy, you must not access or use the Services.
                    By using the Services, you represent and warrant that you are of legal age to e incorporated
                    herein by reference. If you do not want to agree to these Terms of Use or the nter into
                    a binding contract.</p>
                  <p>The Services are not intended for distribution or use in any jurisdiction or country where
                    such distribution or use would be contrary to law or regulation or which would subject
                    us to any registration requirement within such jurisdiction or country. We operate the
                    Services in the United States. Anyone using or accessing the Services from other
                    locations does so on their own initiative and is responsible for compliance with United
                    States and local laws regarding online conduct and acceptable content, if and to the
                    extent such laws are applicable.</p>
                  <p>If you do not meet all of these requirements, you must not access or use the Services.</p>
                  <h2>2. Scope of Services</h2>
                  <h3>2.1 Use of Website and Electronic Messaging</h3>
                  <p>The subscription services offered by Tripello, Inc. are described on the Tripello, Inc.
                    website and communication regarding same may be exchanged between you and
                    Company via Electronic Messaging. You agree to all terms and conditions related to
                    Website usage and Electronic Messaging as set forth within these terms of use.</p>
                  <h3>2.2 Membership and Promotions</h3>
                  <p>Tripello, Inc. offers various subscription services for discounted travel to members. Free,
                    Paid Annual and Paid Semi-Annual subscriptions are available to members. Company
                    may change, alter, or cancel the available subscription offerings at its sole discretion as
                    described further below. Once a membership is purchased, such membership will automatically renew per the same annual or semi-annual term unless canceled as described below.</p>
                  <p>Tripello, Inc. may, from time to time, offer free trials or additional promotional
                    opportunities to existing members or potential new members. Members or website
                    guests who have shared personal information with company may receive information
                    relative to such promotions.</p>

                  <h3>2.3 Billing, Payment and Cancellation</h3>
                  <h3>2.3.1 Billing Cycle</h3>
                  <p>Your membership fee and any other charges you may incur in connection with the purchase of your membership, such as taxes and possible SERVICE FEES, will be charged to your Payment Method on the payment date indicated on your “Profile” page. The length of your billing cycle will depend on the type of subscription that you choose when you purchased your membership. Membership fee obligations are satisfied upon payment. In some cases, your payment date may change, for example, if your Payment Method has not successfully settled if and when you changed your subscription plan. You may visit our website or contact us with questions via email (<a href="mailto:membersupport@tripello.com">membersupport@tripello.com</a>) or directly (24/7) through the Customer Support Portal after you logged in.</p>
                  <h3>2.3.2 Payment Methods</h3>
                  <p>To purchase a membership, you must provide one or more Payment Methods. You authorize us to charge any Payment Method associated with your account in case your primary Payment Method is declined or no longer available to us for processing your subscription fee. You remain responsible for any uncollected amounts. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not cancel your account, we may downgrade your membership until we have successfully charged a valid Payment Method. For some Payment Methods, the issuer may charge you certain fees relating to the processing of your Payment Method.</p>
                  <h3>2.3.3 Updating your Payment Methods</h3>
                  <p>Contact <a href="mailto:membersupport@tripello.com">membersupport@tripello.com.</a> You will be provided with a link to update your
                    payment information.</p>
                  <h3>2.3.4 Account Responsibilities</h3>
                  <p>You are responsible for keeping your account information up to date in all respects and
                    are fully responsible for all activities that occur under your account. You are responsible

                    for maintaining the confidentiality of your login information and are fully responsible
                    for all activities that occur under your email account. You agree to IMMEDIATELY notify
                    Tripello, Inc. at <a href="mailto:membersupport@tripello.com">membersupport@tripello.com</a>  of any unauthorized use, suspected
                    unauthorized use of your email account, or any other breach of security. Tripello, Inc.
                    cannot and will not be liable for any loss or damage arising from your failure to comply
                    with the above requirements.</p>
                  <h3>2.3.5 Cancellation</h3>
                  <p>You can cancel your membership at any time, and you will continue to have access to your membership benefits through the end of your billing period. To cancel, go to your Profile page on our website and follow the instructions. If you cancel your membership, your account will automatically be downgraded to a “Freemium” membership at the end of your current billing period.  Contact us with questions via email (<a href="mailto:membersupport@tripello.com">membersupport@tripello.com</a>) or directly (24/7) through the Customer Support Portal after you logged in.</p>
                  <h3>2.3.6 Changes to Membership Subscription Plans and Prices</h3>
                  <p>We reserve the right to change our subscription plans or adjust pricing for our services
                    or any components thereof in any manner and at any time as we may determine in our
                    sole and absolute discretion. Except as otherwise expressly provided for in these Terms
                    of Use, any price changes or changes to your subscription plan will take effect following
                    notice provided to you.</p>
                  <h3>2.3.7 No Refunds</h3>
                  <p>Membership payments are nonrefundable and there are no refunds or credits for
                    partially used membership periods. Following any cancellation, however, you will
                    continue to have access to the service through the end of your current billing period.</p>
                  <h3>2.3.8 Authorized Users</h3>
                  <p>Once you have paid for your Tripello Premium or Premium Plus membership, you may
                    designate up to three additional “Authorized Users” for your account at no additional
                    cost. All Authorized Users agree to be bound by the terms and conditions contained in
                    this agreement, and you agree to remain responsible for all actions of your Authorized
                    User(s) with respect to your Tripello membership.</p>
                  <h3>2.3.9 Hotel Booking Payment</h3>
                  <p>Tripello is a travel booking platform. We do not make reservations; we are a third party
                    booking platform. Tripello, Inc. pays the supplier directly, and collects the amount
                    charged by the supplier from the member.</p>
                  <p>Your credit card (or other payment method) is charged in full at the time you book a
                    hotel room and the booking is confirmed by the vendor. The amount you pay is the
                    amount charged to us by the lodging vendor and the credit card fee charged by the
                    card provider. You authorize us to charge you for the total booking price, which
                    includes the amount shown for the booking including the tax charges.</p>
                  <p>Any obvious errors (including errata, typographic errors, mistakes in currency
                    conversions, and fee errors in general) are not binding. In the event of an error, Tripello,
                    Inc. reserves the right to cancel any booking made and refund the money paid by the
                    member, without any resulting responsibility for Tripello, Inc.</p>
                  <p>Your credit card will be charged in the equivalent United States Dollar Amount,
                    regardless of the currency displayed.</p>
                  <h3>2.3.10 Flight Booking Payment</h3>
                  <p>Tripello is a travel booking platform. We do not make reservations; we are a third party
                    booking platform. Tripello, Inc. pays the supplier directly, and collects the amount
                    charged by the supplier from the member.</p>
                  <p>Your credit card (or other payment method) is charged in full at the time you book a
                    flight and the booking is confirmed by the vendor. The amount you pay is the amount
                    charged to us by the vendor and the credit card fee charged by the card provider. You
                    authorize us to charge you for the total booking price, which includes the amount
                    shown for the booking including the tax charges.</p>
                  <p>Any obvious errors (including errata, typographic errors, mistakes in currency
                    conversions, and fee errors in general) are not binding. In the event of an error, Tripello,
                    Inc. reserves the right to cancel any booking made and refund the money paid by the
                    member, without any resulting responsibility for Tripello, Inc..</p>
                  <p>Your credit card will be charged in the equivalent United States Dollar Amount,
                    regardless of the currency displayed.</p>
                  <h3>2.3.11 Vacation Homes Payment</h3>
                  <p>Tripello is a travel booking platform. We do not make reservations; we are a third-party
                    booking platform. Tripello, Inc. pays the supplier directly, and collects the amount
                    charged by the supplier from the member.</p>
                  <p>Your credit card (or other payment method) is charged in full at the time you book a
                    vacation home and the booking is confirmed by the vendor. The amount you pay is the
                    amount charged to us by the lodging vendor and the credit card fee charged by the
                    card provider. You authorize us to charge you for the total booking price, which
                    includes the amount shown for the booking including the tax charges.</p>
                  <p>Any obvious errors (including errata, typographic errors, mistakes in currency
                    conversions, and fee errors in general) are not binding. In the event of an error, Tripello,
                    Inc. reserves the right to cancel any booking made and refund the money paid by the
                    member, without any resulting responsibility for Tripello, Inc.</p>
                  <p>Your credit card will be charged in the equivalent United States Dollar Amount,
                    regardless of the currency displayed.</p>
                  <h3>2.3.12 Rental Car Reservations</h3>
                  <p>Tripello offers Rental Car Reservations. Tripello does not collect any payment from the
                    member for Rental Car services. You authorize Tripello to make the reservation with
                    the rental car service on your behalf. All amounts due for the rental car will be due at
                    the rental car company counter and are not collected by Tripello.</p>
                  <h3>2.3.13 Resort Fees</h3>
                  <p>Tripello does not collect Resort Fees from our members. Resort Fees vary by lodging
                    provider and are paid on-site to the lodging provider directly. Tripello makes every
                    effort to inform members of possible Resort Fees at the time of booking; however,
                    Tripello does not and cannot guarantee the accuracy of such information.</p>
                  <h3>2.3.14 Tax Recovery</h3>
                  <p>Tripello, Inc. does not collect taxes for remittance to applicable taxing authorities. The
                    tax recovery charges on prepaid hotel transactions are a recovery of the estimated
                    taxes (e.g. sales and use, occupancy, room tax, excise tax, value-added tax, etc.) that
                    Tripello pays to the hotel supplier for taxes due on the hotels’ rental rate for the room.
                    The hotel suppliers invoice Tripello for certain charges, including tax amounts. The hotel
                    suppliers are responsible for remitting applicable taxes to the applicable tax
                    jurisdictions. Tripello does not act as co-vendor with the supplier with whom we book or
                    reserve our members’ travel arrangements. Taxability and the appropriate tax rate vary
                    greatly by location. The actual tax amounts paid by Tripello to the hotel suppliers may
                    vary from the tax recovery charge amounts, depending upon the rates, taxability, etc. in
                    effect at the time of the actual use of the hotel by our members.</p>
                  <h3>2.3.15 Conditions</h3>
                  <p>Tripello reserves the right to accept or refuse membership at our sole discretion.</p>
                  <h3>2.3.16 Prices and Public Pricing</h3>
                  <p>Tripello does not guarantee to always have the lowest prices possible. We show the lowest prices that we have access to at the moment of your search.</p>
                  <p>Tripello offers a price comparison tool on the Hotel Search level which depends on third-party information and is continuously changing. Tripello DOES NOT guarantee the accuracy of these prices as they are out of our control.</p>
                  <p>On the Hotel Room Level, we show Public Prices which are Estimated Public Prices combined from multiple third parties. Tripello cannot guarantee absolute accuracy at any given time.</p>
                  <p>Public Prices shown on Tripello are to be seen as a guide/tool for Travel Booking Research and do not guarantee specific savings.</p>

                  <h2>3.Terms of Use</h2>
                  <p>We may revise and update these Terms of Use from time to time in our sole discretion.
                    All changes are effective immediately when we post them. Your continued use of the
                    Services following the posting of revised Terms of Use means that you accept and agree
                    to the changes. You are expected to check this page from time to time so you are aware
                    of any changes, as they are binding on you.</p>
                  <h2>4. Accessing the Services and Account Security</h2><p>We reserve the right to withdraw or amend the Services in our sole discretion without
                    notice. We will not be liable if for any reason all, or any part, of the Services is
                    unavailable at any time or for any period. From time to time, we may restrict access to
                    some parts of the Services, or all of the Services.<br />To access certain information through the Services, you may be asked to provide certain
                    details or other information. It is a condition of your use of the Services that all the

                    information you provide is correct, current and complete. You agree that all information
                    you provide is governed by our Privacy Policy and you consent to all actions we take
                    with respect to your information consistent with our Privacy Policy.</p>
                  <p>You acknowledge that, although Tripello’s objective is to make the service accessible 24
                    hours per day, 7 days a week, the service may be unavailable from time to time for any
                    reason including, without limitation, routine maintenance. You understand and
                    acknowledge that due to circumstances both within and outside of the control of
                    Tripello, Inc., access to the service may be interrupted, suspended, or terminated from
                    time to time.</p>
                  <h2>5. Intellectual Property Rights</h2>
                  <p>The Services and their entire contents, features, and functionality (including but not
                    limited to all information, software, text, displays, images, video and audio, and the
                    design, selection and arrangement thereof), are owned by the Company, its licensors or
                    other providers of such material and are protected by copyright, trademark, patent,
                    trade secret and other intellectual property or proprietary rights laws.<br />You must not reproduce, distribute, modify, create derivative works of, publicly display,
                    publicly perform, republish, download, store or transmit any of the material on the
                    Services without our permission.</p>
                  <p>No right, title or interest in or to the Services or any content within the Services is
                    transferred to you, and all rights not expressly granted are reserved by the Company.
                    Any use of the Services not expressly permitted by these Terms of Use is a breach of
                    these Terms of Use and may violate copyright, trademark and other laws.
                    The Company name, the terms Tripello, Tripello Hub, Tripello Network, and “We are
                    Here to get your There” and the company logo and all related names, logos, product
                    and service names, designs and slogans are trademarks of the Company or its affiliates
                    or licensors. You must not use such marks without the prior written permission of the
                    Company.</p>
                  <h2>6. Prohibited Uses</h2>
                  <p>You may use the Services only for lawful purposes and in accordance with these Terms
                    of Use. You agree not to use the Services:</p>
                  <ul>
                    <li>In any way that violates any applicable law.</li>
                    <li>For the purpose of exploiting, harming or attempting to exploit or harm minors
                      in any way by exposing them to inappropriate content, asking for personally
                      identifiable information or otherwise.</li>
                    <li>To send, knowingly receive, upload, download, use or re-use any material which
                      does not comply with these Terms of Use.</li>
                    <li>To transmit, or procure the sending of, any advertising or promotional material
                      including any “junk mail”, “chain letter” or “spam” or any other similar
                      solicitation.</li>
                    <li>To impersonate or attempt to impersonate the Company, a Company employee,
                      another user or any other person or entity (including, without limitation, by
                      using e-mail addresses or screen names associated with any of the foregoing).</li>
                    <li>To engage in any other conduct that restricts or inhibits anyone’s use or
                      enjoyment of the Services, or which, as determined by us, may harm the
                      Company or users of the Services, or expose them to liability.</li>
                  </ul>
                  <p>Additionally, you agree not to:</p>
                  <ul>
                    <li>Use the Services in any manner that could disable, overburden, damage, or
                      impair the Services or interfere with any other party’s use of the Services.</li>
                    <li>Use any robot, spider or other automatic device, process or means to access the
                      Services for any purpose, including monitoring or copying any of the material
                      on the Services.</li>
                    <li>Use any manual process to monitor or copy any of the material on the Services or
                      for any other unauthorized purpose without our prior written consent.</li>
                    <li>Use any device, software or routine that interferes with the proper working of
                      the Services.</li>
                    <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which
                      is malicious or technologically harmful.</li>
                    <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any
                      parts of the Services, the server on which the Services are stored, or any server,
                      computer or database connected to the Services.</li>
                    <li>Attack the Services via a denial-of-service attack or a distributed denial-of-service
                      attack.</li>
                    <li>Otherwise attempt to interfere with the proper working of the Services.</li>
                  </ul>
                  <h2>7. User Contributions</h2>
                  <p>The Website may contain message boards, chat rooms, personal web pages or profiles,
                    forums, bulletin boards, blogs and other interactive features (collectively, "Interactive
                    Services") that allow users to post, submit, publish, display or transmit to other users or
                    other persons (hereinafter, "post") content or materials (collectively, "User
                    Contributions") on or through the Website.</p>
                  <p>All User Contributions must comply with the Content Standards set out in these Terms
                    of Use.</p>
                  <p>Any User Contribution you post to the site will be considered non-confidential and non-
                    proprietary. By providing any User Contribution on the Website, you grant us and our
                    affiliates and service providers, and each of their and our respective licensees,
                    successors and assign the right to use, reproduce, modify, perform, display, distribute
                    and otherwise disclose to third parties any such material for any purpose/according to
                    your account settings.</p>
                  <p>You represent and warrant that:</p>
                  <ul>
                    <li>You own or control all rights in and to the User Contributions and have the right
                      to grant the license granted above to us and our affiliates and service providers,
                      and each of their and our respective licensees, successors and assigns.</li>
                    <li>All of your User Contributions do and will comply with these Terms of Use.
                      You understand and acknowledge that you are responsible for any User Contributions
                      you submit or contribute, and you, not the Company, have full responsibility for such
                      content, including its legality, reliability, accuracy and appropriateness.</li>
                  </ul>
                  <p>We are not responsible, or liable to any third party, for the content or accuracy of any
                    User Contributions posted by you or any other user of the Website.</p>
                  <h2>8. Monitoring and Enforcement; Termination</h2>
                  <p>We have the right to:</p>
                  <ul>
                    <li>Remove or refuse to post any User Contributions for any or no reason in our sole
                      discretion.</li>
                    <li>Take any action with respect to any User Contribution that we deem necessary or
                      appropriate in our sole discretion, including if we believe that such User
                      Contribution violates the Terms of Use, including the Content Standards,
                      infringes any intellectual property right or other right of any person or entity,
                      threatens the personal safety of users of the Website or the public or could
                      create liability for the Company.</li>
                    <li>Take appropriate legal action, including without limitation, referral to law
                      enforcement, for any illegal or unauthorized use of the Website.Terminate or suspend your access to all or part of the Website for legitimate
                      reasons, including without limitation, any violation of these Terms of Use.

                      Without limiting the foregoing, we have the right to fully cooperate with any law
                      enforcement authorities or court order requesting or directing us to disclose the
                      identity or other information of anyone posting any materials on or through the
                      Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES,
                      LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY
                      ACTION TAKEN BY THE COMPANY/ANY OF THE FOREGOING PARTIES DURING OR AS A
                      RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE
                      OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.<br />
                      However, we cannot/do not undertake to review all material before it is posted on the
                      Website and cannot ensure prompt removal of objectionable material after it has been
                      posted. Accordingly, we assume no liability for any action or inaction regarding
                      transmissions, communications or content provided by any user or third party. We have
                      no liability or responsibility to anyone for performance or nonperformance of the
                      activities described in this section.</li>
                  </ul>
                  <h2>9. Content Standards</h2>
                  <p>These content standards apply to any and all User Contributions and use of Interactive
                    Services. User Contributions must in their entirety comply with all applicable national,
                    federal, state, local and international laws and regulations. Without limiting the
                    foregoing, User Contributions must not:</p>
                  <ul>
                    <li>Contain any material which is defamatory, obscene, indecent, abusive, offensive,
                      harassing, violent, hateful, inflammatory or otherwise objectionable.</li>
                    <li>Promote sexually explicit or pornographic material, violence, or discrimination
                      based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
                    <li>Infringe any patent, trademark, trade secret, copyright or other intellectual
                      property or other rights of any other person.</li>
                    <li>Violate the legal rights (including the rights of publicity and privacy) of others or
                      contain any material that could give rise to any civil or criminal liability under
                      applicable laws or regulations or that otherwise may be in conflict with these
                      Terms of Use and our Privacy Policy.</li>
                    <li>Be likely to deceive any person.</li>
                    <li>Promote any illegal activity, or advocate, promote or assist any unlawful act.</li>
                    <li>Cause annoyance, inconvenience or needless anxiety or be likely to upset,
                      embarrass, alarm or annoy any other person.</li>
                    <li>Impersonate any person, or misrepresent your identity or affiliation with any
                      person or organization.</li>
                    <li>Involve commercial activities or sales, such as contests, sweepstakes and other
                      sales promotions, barter or advertising.</li>
                    <li>Give the impression that they emanate from or are endorsed by us or any other
                      person or entity, if this is not the case.</li>
                  </ul>
                  <h2>10. Copyright Infringement</h2>
                  <p>If you believe that any User Contributions violate your copyright, please email us at
                    <a href="https://compliance@tripello.com"> compliance@tripello.com</a>. It is the policy of the Company to terminate the user
                    accounts of repeat infringers.</p>
                  <h2>11. Reliance on Information Posted</h2>
                  <p>The information presented on or through the Services is made available solely for
                    general information purposes. We do not warrant the accuracy, completeness, or
                    usefulness of this information. Any reliance you place on such information is strictly at
                    your own risk. We disclaim all liability and responsibility arising from any reliance placed
                    on such materials by you or any other visitor to the Services or by anyone who may be
                    informed of any of its contents.</p>
                  <p>The Services may include content provided by third parties, including materials provided
                    by other users, bloggers and third-party licensors, syndicators, aggregators and/or
                    reporting services. All statements and/or opinions expressed in these materials, and all
                    articles and responses to questions and other content, other than the content provided
                    by the Company, are solely the opinions and the responsibility of the person or entity
                    providing those materials. These materials do not necessarily reflect the opinion of the
                    Company. We are not responsible, or liable to you or any third party, for the content or
                    accuracy of any materials provided by any third parties.</p>
                  <h2>12. Site Provider</h2>
                  <p><strong>12.1</strong> Tripello, Inc. Connects you to Providers of Travel Services<br />
                    The site is intended to facilitate your booking of hotel rooms and other travel-related
                    products from vendors that provide that products and services. Tripello, Inc. is not
                    involved in providing the services. As a result, Tripello, Inc. has no control over the
                    quality, safety, or legality of the products and services offered, nor the truth or accuracy
                    of the listings or the availability of the vendors to offer services. In the event that you
                    have a dispute with one or more vendors, you release Tripello, Inc. (and its officers,
                    directors, agents, subsidiaries, joint ventures, and employees) from claims, demands,
                    and damages (actual and consequential) of every kind of nature, known and unknown,
                    suspected and unsuspected, disclosed and undisclosed, arising out, or, in any way,
                    connected with such disputes.</p>
                  <p><strong>12.2</strong> Ratings<br />
                    We do not guarantee the accuracy of lodging ratings on the site. While we provide what
                    we consider appropriate ratings, these are intended merely as guidelines and not as any
                    form of guarantee. Lodging providers are constantly making changes and renovating
                    their products. Although we attempt to keep the site updated, we do not guarantee
                    the accuracy of any ratings on the site nor do we make any guarantee that the lodging
                    establishment is not undergoing any renovations. Ratings provided are intended to

                    provide a single qualitative assessment of the entire property or service. Individual
                    rooms or components of a provider’s offering may be better or worse than implied in
                    the rating.</p>
                  <p><strong>12.3</strong> No Representation or Warranty<br />
                    Tripello, Inc. makes no representation or warranty of any kind, either express or implied,
                    as to whether (1) a vendor is licensed or authorized to provide the products or services
                    it is offering; (2) the quality, identity or reliability of any vendor; (3) the fairness of any
                    terms and conditions, including price, offered by a vendor; (4) any of the information
                    provided by a vendor is accurate; or (5) a vendor is in the good financial condition or has
                    a good reputation and business practices; and (6) and the vendor will be operating at
                    the reserved time.</p>
                  <p><strong>12.4</strong> Groups<br />
                    You may book up to 4 rooms and 4 flights per itinerary.</p>
                  <p><strong>12.5</strong> The Information on the Site is Proprietary to Tripello, Inc. and our Suppliers
                    Except for making copies of your itinerary and related documents, you agree not to in
                    any way copy, modify, publicly sell, or otherwise use any information or services
                    provided by the site. You agree not make any speculative or fraudulent bookings, use
                    the site for any commercial purpose, or link to or incorporate any part of this site
                    without our prior written permission.</p>
                  <p><strong>12.6</strong> Cancellation of Prepaid Hotel Bookings<br />
                    You agree to abide by the Terms of Use imposed with respect to your prepaid hotel
                    booking.<br />
                    You agree to pay any cancellation or charge fees that you incur.</p>
                  <ul>
                    <li>You may cancel your prepaid hotel reservation, but you will be charged the
                      cancellation fee indicated in the Rules and Restrictions for the hotel
                      reservation.</li>
                    <li>In limited cases, some hotels do not permit cancellations of bookings after they
                      are made, as indicated in the Rules and Restrictions for the hotel booking.</li>
                    <li>Once a booking is canceled, any refunds, minus any cancellation fees, will be
                      processed within 12 business days.</li>
                  </ul>
                  <p>If you do not cancel your booking before the cancellation policy period applicable to the
                    hotel you booked, which varies by hotel (usually 24 to 72 hours) prior to your date of
                    arrival, you will be subject to a charge equal to the applicable nightly rates, tax recovery
                    charges, and service fees. In the event you do not show up for the first night of the
                    booking and plan to check-in for subsequent nights in your booking, you must confirm
                    the booking changes with the hotel directly no later than the date of the first night of
                    the booking to prevent cancellation of your reservation.</p>
                  <p><strong>12.7</strong> International Travel<br />You are responsible for ensuring that you meet foreign entry requirements and that
                    your travel documents, such as passport and visas (transit, business, tourist, and
                    otherwise), are in order and any other foreign entry requirements are met. Tripello, Inc.

                    has no special knowledge regarding foreign entry requirements and travel documents.
                    We urge members to review travel prohibitions, warnings, announcements, and
                    advisories issued by the relevant governments prior to booking travel to international
                    destinations.</p>
                  <p>Passport and Visa: You must consult the relevant Embassy or Consulate for this
                    information. Requirements may change and you should check for up-to-date
                    information before booking and departure. We accept no liability if you are refused
                    entry onto a flight or into any country due to your failure to carry the correct and
                    adequate passport, visa, or other travel documents required by any airline, authority, or
                    country, including countries you may just be transiting through. This includes all stops
                    made by the aircraft, even if you do not leave the aircraft or the airport.</p>
                  <p>Health:<br />
                    Recommended inoculations for travel may change and you should consult your doctor
                    for current recommendations before you depart. It is your responsibility to ensure that
                    you meet all health entry requirements, obtain the recommended inoculations, take all
                    recommended medication, and follow all medical advice in relation to your trip.
                    BY OFFERING BOOKINGS FOR TRAVEL PRODUCTS IN PARTICULAR INTERNATIONAL
                    DESTINATIONS, TRIPELLO, INC. DOES NOT REPRESENT OR WARRANT THAT TRAVEL TO
                    SUCH AREAS IS ADVISABLE OR WITHOUT RISK, AND IS NOT LIABLE FOR DAMAGES OR
                    LOSSES THAT MAY RESULT FROM TRAVEL TO SUCH DESTINATIONS.</p>

                  <p><strong>12.8</strong> Advisory:</p>
                  <p>Travel to certain destinations may involve more risk than travel to other destinations; therefore, we strongly recommend that you review current government-issued travel advisories, warnings, requirements, restrictions, and public health directives prior to making a reservation and prior to traveling. You acknowledge and agree that you assume all risks imposed by travel and that any information provided by us, Service Providers, or these Terms shall not replace your own judicious risk assessment, based on your personal circumstances, and that of prudent health, safety, and security considerations. You acknowledge and agree that you are responsible for determining and obtaining proper documents for travel, for both domestic and international destinations, complying with all entry/exit and other governmental travel requirements or restrictions applicable to/from/within your destinations of travel, and comply with all Supplier requirements for travel, including, without limitation, completion of health forms and provision of any required health test results.</p>
                  <p>For more information, please visit: <a href="https://travel.state.gov/content/travel/en/international-travel.html" target="_blank"> https://travel.state.gov/content/travel/en/international-travel.html</a>,<a href="https://www.tsa.gov" target="_blank">www.tsa.gov</a> , <a href="https://www.dot.gov" target="_blank">www.dot.gov</a>, <a href="https://www.faa.gov" target="_blank">www.faa.gov</a>, <a href="https://www.cdc.gov" target="_blank">www.cdc.gov</a>, <a href="https://www.treas.gov/ofac" target="_blank">www.treas.gov/ofac</a>, and <a href="https://www.customs.gov" target="_blank">www.customs.gov</a>.</p>
                  <p>Please also refer to the Office of Foreign Assets Control (OFAC) at <a href="https://ofac.treasury.gov/sanctions-programs-and-country-information" target="_blank">https://ofac.treasury.gov/sanctions-programs-and-country-information</a> for country specific sanctions, requirements, civil penalties and enforcement information. Tripello is not responsible nor liable for your disregard of any important information or requirements. </p>
                  <p><strong>OFAC Compliance.</strong> Tripello is currently in compliance with and shall at all times during the term of this Agreement remain in compliance with the regulations of the OFAC (including those named on OFAC’s Specially Designated and Blocked Persons List) and any statute, executive order (including the September 24, 2001, Executive Order Blocking Property and Prohibiting Transactions with Persons Who Commit, Threaten to Commit, or Support Terrorism), or other governmental action relating thereto.</p>
                  <p>You acknowledge that some countries may require aircraft cabin insecticide treatment for in-bound foreign flights. A list of such countries is available at <a href="https://www.dot.gov/airconsumer/spray" target="_blank">https://www.dot.gov/airconsumer/spray</a>.</p>
                  <p>Due to frequent changes, we cannot guarantee the accuracy of any information provided on/through a Travel Channel, including, without limitation, provided by our travel consultants or any government or third-party websites we may reference, and we expressly disclaim any and all liability for inaccurate or incomplete information contained on such sites or provided by us. </p>
                  <p>BY OFFERING FOR SALE TRAVEL TO PARTICULAR DESTINATIONS, NEITHER WE NOR ANY SERVICE PROVIDER REPRESENTS OR WARRANTS THAT TRAVEL TO SUCH DESTINATIONS IS SAFE, ADVISABLE, OR WITHOUT RISK, AND NEITHER WE NOR ANY SERVICE PROVIDER IS LIABLE FOR ANY DAMAGES OR LOSSES THAT MAY RESULT FROM TRAVEL TO SUCH DESTINATIONS.</p>
                  <p>Cuba travel: By traveling to Cuba, you are attesting that you are a U.S. citizen or U.S. resident and your travel to Cuba is authorized under the Cuban Assets Control Regulations and falls within one of the approved categories. For more information on each of the categories, please see Part 515.560 of the Cuban Assets Control Regulations at <a href="http://www.ecfr.gov" target="_blank">http://www.ecfr.gov</a>. </p>
                  <p>Additional information can be found at <a href="https://www.treasury.gov/resource-center/sanctions/Programs/Pages/cuba.aspx" target="_blank">https://www.treasury.gov/resource-center/sanctions/Programs/Pages/cuba.aspx</a>. You acknowledge that travel for traditional tourist activities is prohibited, and you are expected to maintain a full schedule of activities related to your category of travel. If providing any insurance benefit would violate U.S. economic or trade sanctions, then the policy will be void.</p>
                  <h2>13. Changes to the Services</h2>
                  <p>We may update the content contained on the Services from time to time, but its
                    content is not necessarily complete or up-to-date. Any of the material contained on the
                    Services may be out of date at any given time, and we are under no obligation to update
                    such material.</p>
                  <h2>14. Links from the Services</h2>
                  <p>You may link to our homepage, provided you do so in a way that is fair and legal and
                    does not damage our reputation or take advantage of it, but you must not establish a
                    link in such a way as to suggest any form of association, approval or endorsement on
                    our part without our express written consent.</p>
                  <p>This Website may provide certain social media features that enable you to:</p>
                  <ul>
                    <li>Link from your own or certain third-party websites to certain content on this
                      Website.</li>
                    <li>Send e-mails or other communications with certain content, or links to certain
                      content, on this Website.</li>
                    <li>Cause limited portions of content on this Website to be displayed or appear to
                      be displayed on your own or certain third-party websites.<br />You may use these features solely as they are provided by us and solely with respect to
                      the content they are displayed with and otherwise in accordance with any additional
                      terms and conditions we provide with respect to such features. Subject to the
                      foregoing, you must not:</li>
                    <li>Establish a link from any website that is not owned by you.</li>
                    <li>Cause the Website or portions of it to be displayed, or appear to be displayed by,
                      for example, framing, deep linking or in-line linking, on any other site.</li>
                    <li>Link to any part of the Website other than the homepage.</li>
                    <li>Otherwise take any action with respect to the materials on this Website that is
                      inconsistent with any other provision of these Terms of Use.<br />The website from which you are linking, or on which you make certain content
                      accessible, must comply in all respects with the Content Standards set out in these
                      Terms of Use.</li>
                  </ul>
                  <p>You agree to cooperate with us in causing any unauthorized framing or linking
                    immediately to cease. We reserve the right to withdraw linking permission without
                    notice.</p>
                  <p>We may disable all or any social media features and any links at any time without notice
                    in our discretion.</p>
                  <h2>15. Links from the Website</h2>
                  <p>If the Website contains links to other sites and resources provided by third parties,
                    these links are provided for your convenience only. This includes links contained in
                    advertisements, including banner advertisements and sponsored links. We have no
                    control over the contents of those sites or resources, and accept no responsibility for
                    them or for any loss or damage that may arise from your use of them. If you decide to
                    access any of the third-party websites linked to this Website, you do so entirely at your
                    own risk and subject to the terms and conditions of use for such websites.</p>
                  <h2>16. Geographic Restrictions</h2>
                  <p>The owner of the Website is based in the state of Nevada in the United States. We
                    provide this Website for use only by persons located in countries in which Tripello, Inc.
                    does business. We make no claims that the Website or any of its content is accessible or
                    appropriate outside of those countries. Access to the Website may not be legal by
                    certain persons or in certain countries. If you access the Website from outside a market
                    in which Tripello, Inc. does business, you do so on your own initiative and are
                    responsible for compliance with local laws.</p>
                  <h2>17. Disclaimer of Warranties</h2>
                  <p>WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
                    DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL

                    MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
                    DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICESOR TO
                    YOUR DOWNLOADING OF ANY MATERIAL OBTAINED OR POSTED THROUGH THE
                    SERVICES, OR ON ANY WEBSITE LINKED TO THE SERVICES.<br />YOUR USE OF THE SERVICES, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
                    THROUGH THE SERVICESIS AT YOUR OWN RISK. THE SERVICES, ITS CONTENT AND ANY
                    SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES ARE PROVIDED ON AN “AS IS”
                    AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
                    EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH
                    THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                    COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF
                    THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
                    ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
                    SERVICES, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                    SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT
                    DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT
                    AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
                    SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                    OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.<br />THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                    EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                    ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
                    PARTICULAR PURPOSE.<br />THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
                    OR LIMITED UNDER APPLICABLE LAW.</p>
                  <h2>18. Limitation on Liability</h2>
                  <p>IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
                    PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES
                    OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH
                    YOUR USE, OR INABILITY TO USE, THE SERVICES, ANY WEBSITES LINKED TO THE
                    SERVICES, ANY CONTENT CONTAINED IN THE SERVICES OR SUCH OTHER WEBSITES OR
                    ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR SUCH OTHER
                    WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL
                    OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
                    AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
                    OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                    DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                    CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
                    THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
                    LIMITED UNDER APPLICABLE LAW.</p>
                  <h2>19. Indemnification</h2>
                  <p>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors
                    and service providers, and its and their respective officers, directors, employees,
                    contractors, agents, licensors, suppliers, successors and assigns from and against any
                    claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including
                    reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of
                    Use or your use of the Services, including, but not limited to any use of the Service’s
                    content, services and products other than as expressly authorized in these Terms of Use
                    or your use of any information obtained from the Services.</p>
                  <h2>20. Governing Law and Jurisdiction</h2>
                  <p>All matters relating to the Services and these Terms of Use and any dispute or claim
                    arising therefrom or related thereto (in each case, including non-contractual disputes or
                    claims), shall be governed by and construed in accordance with the laws of the State of
                    Nevada without regard to conflict of law principles. Subject to Section 21, below, the
                    exclusive jurisdiction and venue of any action with respect to the subject matter of this
                    Agreement will be the Courts of Clark County, Nevada, and each of the parties hereto
                    irrevocably waives any objection to jurisdiction and venue in such courts.</p>
                  <h2>21. Dispute Resolution, Arbitration and Class Action Waiver</h2>
                  <p>PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING
                    YOUR RIGHT TO FILE A LAWSUIT IN COURT.</p>
                  <p>a. Initial Dispute Resolution. Most disputes can be resolved without resort to
                    arbitration. If you have any dispute with us arising from or related to the Services or
                    these Terms, you agree that before taking any formal action, you will contact us at
                    <a href="https://compliance@Tripello.com"> compliance@Tripello.com</a>, provide a brief, written description of the dispute and your
                    contact information. Except for intellectual property, you and Company agree to use
                    reasonable efforts to settle any dispute, claim, question, or disagreement directly with
                    each other, and good faith negotiations shall be a condition to either party initiating a
                    lawsuit or arbitration.</p>
                  <p>b. Binding Arbitration. If the parties do not reach an agreed-upon solution
                    within a period of thirty (30) days from the time informal dispute resolution is initiated
                    under the Initial Dispute Resolution provision above, then either party may initiate
                    binding arbitration as the sole means to resolve claims, (except as provided below)
                    subject to these Terms set forth below. Specifically, all claims arising out of or relating
                    to these Terms and/or your use of the Services shall be finally settled by binding
                    arbitration administered by JAMS in accordance with the JAMS Streamlined Arbitration
                    Procedure Rules for claims that do not exceed $250,000 and the JAMS Comprehensive
                    Arbitration Rules and Procedures for claims exceeding $250,000 in effect at the time
                    the arbitration is initiated, excluding any rules or procedures governing or permitting
                    class actions. Arbitration shall be initiated and take place in Clark County, Nevada,
                    United States, and you and Company agree to submit to the personal jurisdiction of any
                    federal court in Clark County, Nevada in order to compel arbitration, stay proceedings
                    pending arbitration, or to confirm, modify, vacate, or enter judgment on the award
                    entered by the arbitrator.</p>
                  <p>c. No Jury Trial. The parties understand that, absent this mandatory arbitration
                    section, they would have the right to sue in court and have a jury trial, but by agreeing
                    to this mandatory arbitration section, they give up those rights. They further
                    understand that, in some instances, the costs of arbitration could exceed the costs of
                    litigation and the right to discovery may be more limited in arbitration than in court.</p>
                  <p>d. Class Action Waiver. The parties further agree that the arbitration shall be
                    conducted in the party’s respective individual capacities only and not as a class action or
                    other representative action, and the parties expressly waive their right to file a class
                    action or seek relief on a class basis. YOU AND COMPANY AGREE THAT EACH MAY
                    BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND
                    NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                    REPRESENTATIVE PROCEEDING. If any court or arbitrator determines that the class
                    action waiver set forth in this paragraph is void or unenforceable for any reason or that
                    an arbitration can proceed on a class basis, then the arbitration provisions set forth
                    above shall be deemed null and void in their entirety and the parties shall be deemed to
                    have not agreed to arbitrate disputes.</p>
                  <p>e. Exception: Litigation of Intellectual Property. Notwithstanding the parties’
                    decision to resolve all disputes through arbitration, either party may bring enforcement
                    actions, validity determinations or claims arising from or relating to theft, piracy or
                    unauthorized use of intellectual property in state or federal court with jurisdiction or in
                    the U.S. Patent and Trademark Office to protect its intellectual property rights
                    (“intellectual property rights” means patents, copyrights, moral rights, trademarks, and
                    trade secrets, but not privacy or publicity rights).<br />e. Exception: Litigation of Intellectual Property. Notwithstanding the parties’
                    decision to resolve all disputes through arbitration, either party may bring enforcement
                    actions, validity determinations or claims arising from or relating to theft, piracy or
                    unauthorized use of intellectual property in state or federal court with jurisdiction or in
                    the U.S. Patent and Trademark Office to protect its intellectual property rights
                    (“intellectual property rights” means patents, copyrights, moral rights, trademarks, and
                    trade secrets, but not privacy or publicity rights).</p>
                  <h2>22. Waiver and Severability</h2>
                  <p>No waiver by either party of any term or condition set forth in these Terms of Use shall
                    be deemed a further or continuing waiver of such term or condition or a waiver of any
                    other term or condition, and any failure of a party to assert a right or provision under
                    these Terms of Use shall not constitute a waiver of such right or provision.
                    If any provision of these Terms of Use is held by a court or other tribunal of competent
                    jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the remaining provisions of the
                    Terms of Use will continue in full force and effect.</p>
                  <h2>23. Entire Agreement</h2>
                  <p>The Terms of Use and our privacy policy constitute the sole and entire agreement
                    between you and us with respect to your use of the Services and supersede all prior and
                    contemporaneous understandings, agreements, representations and warranties, both
                    written and oral, with respect to your use of the Services.</p>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default TermAndCondition;

import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Button from "../../Component/FormComponent/Button";
import InputField from "../../Component/FormComponent/InputField";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { hotelActions } from "../../Stores/Actions";
import { useParams } from "react-router-dom";
import { notification, Space } from "antd";

function EmailModal({ openModal, closeModal, setIsOpen }) {
  const dispatch = useDispatch();
  const params = useParams();

  let errorMsg = useSelector((state) => state.hotelReducer.error);
  const [error, setError] = useState("");

  // error handling and reset the state of error in reducer
  useEffect(() => {
    setError(errorMsg?.message);
    return () => {
      setTimeout(() => {
        setError("");
        dispatch(hotelActions.resetEmailBooking());
      }, 5000);
    };
  }, [errorMsg]);

  const initialValues = {
    email: "",
  };

  const validation = () =>
    Yup.object().shape({
      email: Yup.string("")
        .email("Enter a valid email address.")
        .required("Required"),
    });

  const handleSubmit = (value, formikProps) => {
    formikProps.resetForm();
    let data = {
      id: params.bookingID, 
      email: value.email.trim().toLowerCase(),
    };
    dispatch(hotelActions.emailBooking(data)).then((res) => {
      if (res?.status == 200) {
        closeModal()
      }
    });
  };
  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;

    return (
      <div className="popup popup-open hotel-confirmation-email-popup hotel-confirmation-popup">
        <div className="popup-content">
          <form onSubmit={handleSubmit}>
            <div className="popup-title">
              <h3>Send Reservation On Email</h3>
            </div>
            <div className="popup-content-title">
              <InputField
                name="email"
                type="email"
                label="Enter Email"
                placeholder="Email"
                value={values.email}
                error={errors.email || errorMsg}
                touched={touched.email || errorMsg}
                onChange={handleChange}
                onBlur={handleBlur}
                inputClass="formGroup"
              />
            </div>
            <div className="popup-footer">
              <div className="button-wrap">
                <Button type="submit" text="Send Email" className="btn blue-btn close-btn" />
                <button className="btn orange-btn cancel-btn" onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
            <div>{errorMsg}</div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={openModal}
      onRequestClose={closeModal}
      contentLabel="My dialog"
      className="mymodal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validation}
      >
        {renderForm}
      </Formik>
    </Modal>
  );
}

export default EmailModal;

import * as Yup from "yup";

export const supportHotelTabValidation = () => {
    return Yup.object().shape({
        booking_id: Yup.string("")
        // .when("type", (type, schema) => {
        //     if(type !== "membership")
        //       return schema.required("Required")
        //     return schema
        //   })
        ,
        issue: Yup.string("")
            .required("Required"),
        description: Yup.string("")
            .required("Required").min(20)
    });
};

import { serialize } from "../Util/util";
import http from "./http";

export const authService = {
  loginService,
  forgotPasswordService,
  resetPasswordService,
  freemiumSignupService,
  premiumSignupService,
  validatePromocodeService,
  premiumPlusSignupService,
  getUserByAffiliateId,
  isEmailExistService,
  logoutService,
  getUserService,
  upgradeMembershipService,
  updateUserProfileService,
  updatePassowrdService,
  getGuestList,
  updateGuestService,
  addUserService,
  cancelMembership,
  confirmEmailAddressService,
  getPaypalStatus,
  getPaypalVarificationStatus,
  validateCouponCodeService,
  getUserPasses
};
 

function getPaypalStatus(payload) { 
  return http
  .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users/paypal-info
  `,payload )
  .then((res) => res);
} 

function getPaypalVarificationStatus(payload) { 
  return http
  .get(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users/paypal-info/${payload}
  `)
  .then((res) => res);
}


function isEmailExistService(payload) { 
  return http
    .get(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/user/get/email/${process.env.REACT_APP_TENANT_ID}/${payload}`)
    .then((res) => res);
}

function freemiumSignupService(payload) { 
  return http
  .post(`${process.env.REACT_APP_BASE_URL_CARDS}/api/card/auto-assign`,
  {
    ...payload,
    params : {
    use_cards_api_key : true
    }
  }
  )
  .then((res) => res);
}

function premiumSignupService(payload) { 
  return http
    .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users`, payload )
  .then((res) => res);
}
function validatePromocodeService(payload) { 
  return http
    .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/validate-promocode`, payload )
  .then((res) => res);
}
function validateCouponCodeService(payload) { 
  return http
    .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/validate-coupon-code-tp`, payload )
  .then((res) => res);
}

function premiumPlusSignupService(payload) { 
  return http
    .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users`, payload )
  .then((res) => res);
}

function upgradeMembershipService(payload , id) { 
  return http
    .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users/upgrade/${id}`,{
      ...payload,
      params : {
      }
    } )
  .then((res) => res);
}

function getUserByAffiliateId(id) {
  return http.get(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/user/get/affiliate-id/${id}?tenant_id=${process.env.REACT_APP_TENANT_ID}`, {
    params: { use_base_user_url: true}
  });
} 

function loginService(payload) { 
  return http
    .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/login`, payload)
    .then((res) => res);
}

function logoutService(payload) {
  return http
    .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/logout`, payload) 
    .then((res) => res);
}

function forgotPasswordService(payload) {
  const qs = serialize(payload);
  return http.get(
    `${process.env.REACT_APP_BASE_URL_USER}/api/v1/user/forgot-password?${qs}`,
    {
      params: { use_users_base_url: true },      
    }
  );
}

function resetPasswordService(payload) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL_USER}/api/v1/user/password-reset`,
    payload
  );
}

function getUserService() {
  return http.get(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/authenticate`);
}

function getUserPasses() {
  return http.get(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/user-wallet-passes`);
}

function updateUserProfileService(payload, userId) {
  return http.put(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users/${userId}`, payload).then(res => res);
}

function updatePassowrdService(payload, userId) {
  return http.post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/user/update-password`, payload).then(res => res);
}

function addUserService(payload, user_id) {
  return http.post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users/${user_id}/guest`, payload).then(res => res);
}

// get guest list 
function getGuestList(user_id) {
  return http.get(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users/${user_id}/guest`);
}

// update guest details
function updateGuestService(payload, user_id, guest_id) {
  return http.put(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users/${user_id}/guest/${guest_id}`, payload)
}

// cancel premuim membership request
function cancelMembership() {
  return http.get(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/cancel-subscription`);
}

function confirmEmailAddressService(payload) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_USER}/api/v1/users/confirm-email`,
      payload
    )
    .then((res) => res);
}



import React, {useRef, useState} from 'react';
import useOutsideHandler from '../../Stores/Utils/useOutsideHandler';
import Button from '../FormComponent/Button';

function SortBy({ label, sortOptions, setSortingType, carsSortingFunction }) {
  const sortRef = useRef(null);

  const [showOption, setShowOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(sortOptions[0]);

  const toggleCloseSort = () => {
    setShowOption(false);
  };

  const clickHandler = (item) => {
    setSelectedOption({
      value: item.value,
      label: item.label,
    });
    setShowOption(false);

    setSortingType(item.value);
    carsSortingFunction(item.value);
  };

  useOutsideHandler(sortRef, showOption, toggleCloseSort);
  return (
    <div className="filterDropdownWrap sortByOption" ref={sortRef}>
      <div className="flterOption" onClick={() => setShowOption(!showOption)}>
        <div>
          <span className="whiteLabel">{label}</span>

          <span className="desktopViewDisplayBlock">
            {selectedOption.label}
          </span>

          <span className="tabletViewDisplayBlock">{selectedOption.label}</span>
        </div>

        <span className={`fa fa-angle-${showOption ? "up" : "down"}`}></span>
      </div>

      <div className={`filterDropdown ${showOption ? "d-block" : ""}`}>
        <ul>
          {sortOptions &&
            sortOptions.map((item, index) => (
              <li key={index}>
                <Button onClick={() => clickHandler(item)}>{item.label}</Button>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default SortBy;

import { flightConstants } from "../Constants";

const initialState = {
  flightSearchData: null,
  flightErrors: null,
  loading: false,
  flightCheckOutData: null,
  flightCheckOutError: null,
  userBookingDetails: null,
  stripeIntentData: null,
  availabilitySuccess: null,
  bookingDataError: null,
  sessionExpireError: null,
  stripeIntentError: null,
  stripeIntentSuccess: null,
  flightPreBookingData: null,
  flightBookingError: null,
  isPrebook: null,
  flightFilters : {
    // emissions : ["Any"],
    // stops : [null]
  }
};

const flightReducer = (state = initialState, action) => {
  switch (action.type) {

    case flightConstants.UPDATE_FLIGHT_FILTERS:
      return {
        ...state, 
        flightFilters : action.payload
      };

    case flightConstants.SEARCH_FLIGHT_REQUEST:
      return {
        ...state,
        flightSearchData: null,
        flightErrors: null,
        loading: true,
      };

    case flightConstants.SEARCH_FLIGHT_REQUEST:
      return {
        ...state,
        flightSearchData: null,
        flightErrors: null,
        loading: true,
      };

    case flightConstants.SEARCH_FLIGHT_SUCCESS:
      return {
        ...state,
        flightSearchData: action?.response?.data,
        flightErrors: null,
        loading: false,
        isPolling: false,
      };
    case flightConstants.SEARCH_FLIGHT_FAILURE: 
      return {
        ...state,
        flightSearchData: action?.error?.response?.data,
        flightErrors: action.error,
        loading: false,
      };

    case flightConstants.SEARCH_POLL_FLIGHT_REQUEST:
      return { ...state, flightPollingData: null, flightErrors: null };
    case flightConstants.SEARCH_POLL_FLIGHT_SUCCESS:
      return {
        ...state,
        flightPollingData: action?.response?.data,
        flightPollingErrors: null,
        isPolling: false,
      };

    case flightConstants.SEARCH_POLL_FLIGHT_FAILURE:
      return {
        ...state,
        flightPollingData: null,
        flightPollingErrors: action?.error?.response?.data,
      };

    case flightConstants.REVALIDATE_FLIGHT_REQUEST:
      return {
        ...state,
        revalidatedFlightData: null,
        revalidateError: null,
        loading: true,
      };
    case flightConstants.REVALIDATE_FLIGHT_SUCCESS:
      return {
        ...state,
        revalidatedFlightData: action?.response?.data,
        revalidateError: null,
        loading: false,
      };
    case flightConstants.REVALIDATE_FLIGHT_FAILURE:
      return {
        ...state,
        revalidatedFlightData: null,
        revalidateError: action.error,
        sessionExpireError: action?.error?.response?.data?.message || null,
        loading: false,
      };

    case flightConstants.CHECKOUT_FLIGHT_REQUEST:
      return {
        ...state,
        flightCheckOutData: null,
      };
    case flightConstants.CHECKOUT_FLIGHT_SUCCESS:
      return {
        ...state,
        flightCheckOutData: action?.response?.data,
      };
    case flightConstants.CHECKOUT_FLIGHT_FAILURE:
      return {
        ...state,
        flightCheckOutError: action?.response?.error,
      };

    case flightConstants.GET_FLIGHT_BOOKING_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        flightErrors: null,
        userBookingDetails: null,
      };
    case flightConstants.GET_FLIGHT_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        userBookingDetails: { ...action.response },
      };
    case flightConstants.GET_FLIGHT_BOOKING_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        flightErrors: action.error,
      };

    case flightConstants.GET_STRIPE_INTENT_REQUEST: {
      return {
        ...state,
        stripeIntentData: null,
        availabilitySuccess: false,
        bookingDataError: null,
        stripeIntentError: null,
      };
    }

    case flightConstants.GET_STRIPE_INTENT_SUCCESS: {
      return {
        ...state,
        stripeIntentData: { ...action?.response?.data },
        availabilitySuccess: false,
        stripeIntentSuccess: true,
        error: null,
      };
    }
    case flightConstants.GET_STRIPE_INTENT_FAILURE: {
      return {
        ...state,
        availabilitySuccess: false,
        stripeIntentData: null,
        stripeIntentError: action?.error?.response?.data?.message || null,
      };
    }

    case flightConstants.FLIGHT_FEATURES_REQUEST: 
    return { ...state, singleFlightFeatures: null, singleFlightFeaturesError: null, loading: true}
case flightConstants.FLIGHT_FEATURES_SUCCESS: 
    return { ...state, singleFlightFeatures: action.response.data?.data, singleFlightFeaturesError: null, loading: false}
case flightConstants.FLIGHT_FEATURES_FAILURE: 
    return { ...state, singleFlightFeatures: null, singleFlightFeaturesError: action.error?.response?.data?.message, loading: false}

    // pre book flight 
    case flightConstants.PREBOOK_FLIGHT_BOOKING_REQUEST:
      return{
        ...state,
        loading: true,
        flightPreBookingData: null,
        flightBookingError: null,
        isPrebook: null
      }
    case flightConstants.PREBOOK_FLIGHT_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        flightPreBookingData: action.response.data
        
      }
    case flightConstants.PREBOOK_FLIGHT_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        flightBookingError: action.error?.response?.data,
        flightPreBookingData: null
      }
    
    // Get Available Balance
    case flightConstants.GET_AVAILABLE_BALANCE:
      return {
          ...state,
          availableBalance: action.response.data.available_balance,
      };
    default:
      return state;
  }
};

export default flightReducer;

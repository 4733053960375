import React, { Component } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import TermsTab from "../Tabs/TermsTab";

function CookiesPolicy() {
  return (
    <div className="siteBg FaqPageWrap Terms-wrapper">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
        <HeaderNavbar noHeader={!localStorage.getItem("accessToken") ? true : false}/>
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <TermsTab />
              <div className="col rightContent content-wrapper cookies-content">
                <div className="card2">
                  <h1>Cookie Policy</h1>
                  <h2>Types of cookies and similar technologies</h2>
                  <h3>Cookies</h3>
                  <p>Cookies are small pieces of text sent as files to your computer or mobile device
                    when you visit most websites. Cookies may be delivered by us (first party
                    cookies) or delivered by a third-party partner or supplier (third-party cookies).
                    Cookies are either session cookies or persistent cookies. Session cookies enable
                    sites to recognize and link the actions of a user during a browsing session and
                    expire at the end of each session. Persistent cookies help us recognize you as an
                    existing user and these cookies are stored on your system or device until they
                    expire, although you can delete them before the expiration date.</p>
                  <h3>Other similar technologies</h3>
                  <ul>
                    <li><strong>Web beacons, gifs,</strong> and clear gifs are tiny graphics, each with a unique identifier
                      that are embedded invisibly on sites and in emails. Web beacons allow us to
                      know if a certain page was visited or if ad banners on our sites and other sites are
                      effective. We also use web beacons in our HTML-based emails to let us know
                      whether our emails have been opened by recipients, which helps us to gauge the
                      effectiveness of certain communications, promotions, and marketing campaigns.</li>
                    <li><strong>Proximity based beacons</strong> send one-way signals over very short distances, to
                      communicate with associated mobile apps installed on your phone. They can
                      notify you, for example, about experiences related to your trip and can alert you
                      to related deals or promotions. Beacons communicate with your device only
                      when you are in close proximity and only if you have given consent within the
                      relevant mobile application.</li>
                    <li>
                      <strong>Pixels</strong> are small objects embedded into a web page that are not visible to the
                      user. We use pixels to deliver cookies to your computer, facilitate the log-in
                      process, monitor the activity on our sites, and deliver online advertising.
                    </li>
                    <li><strong>Tags</strong> are small pieces of HTML code that tell your browser to request certain
                      content generated by an ad server. We use tags to show you relevant advertising
                      and promotions.</li>
                    <li>
                      <strong>Scripts</strong> are pieces of JavaScript code that launch automatically when certain
                      webpages load, to determine whether users view associated advertisements.
                    </li>
                    <li><strong>Local Storage Objects,</strong> such as HTML 5, are used to store content and
                      preferences. Third-parties with whom we partner to provide certain features on
                      our site to display advertising based upon your web browsing activity use Local
                      Storage Objects to collect and store information.<br />All of the technologies described above will be collectively referred to in this
                      Cookie Statement as “cookies”.</li>
                  </ul>
                  <p>All of the technologies described above will be collectively referred to in this
                    Cookie Statement as “cookies”.</p>
                  <h3>Tripello’s use of cookies</h3>
                  <p>At Tripello, we use cookies for a number of reasons, including to:</p>
                  <ul>
                    <li>Help us improve your experience when visiting our sites</li>
                    <li>Fulfill transactions and ensure our sites perform as intended</li>
                    <li>Remember your preferences, such as language, region, or currency</li>
                    <li>Provide you with relevant advertising and analyze performance of the ads</li>
                    <li>Enable you to return to previous travel searches</li>
                    <li>Identify errors on our sites</li>
                    <li>Help with data protection and potentially detect and investigate malicious or
                      fraudulent activity</li>
                    <li>Help us understand traffic to our site, including time and date of the visit, time
                      and date of the last visit, and other information</li>
                    <li>Analyze how well our sites are performing</li>
                  </ul>
                  <h2>Types of information collected by cookies</h2>
                  <p>The types of information that we collect through cookies include:</p>
                  <ul>
                    <li> IP address</li>
                    <li> Device ID</li>
                    <li> Viewed pages</li>
                    <li> Browser type</li>
                    <li> Browsing information</li>
                    <li> Operating system</li>
                    <li> Internet Service Provider</li>
                    <li> Whether you have responded to, or interacted with, an advertisement</li>
                    <li> Referring or referred links or URLs</li>
                    <li> Features used and activities engaged in on our sites and in our apps</li>
                  </ul>
                  <p>See the  Categories of Personal Information We Collect section above for more
                    information on what types of information are collected automatically.</p>
                  <h3>Types and functions of cookies</h3>
                  <h4>Essential Cookies</h4>
                  <p>Certain cookies are required or “essential” for our sites to function as intended.
                    Essential cookies are necessary for you to navigate our site and use certain
                    features like logging in to your account and managing your bookings. These

                    cookies are also used to remember security settings that allow access to
                    particular content. Lastly, we use essential cookies to collect information on
                    which web pages’ visitors go to most, so we can improve our online services. You
                    are not able to opt out of essential cookies.</p>
                  <h3>Other Types of Cookies</h3>
                  <p>We also use other types of cookies to make our site engaging and useful to you:</p>
                  <ul>
                    <li>
                      <strong>Functional Cookies.</strong> We want to make sure when you visit our site, your
                      preferences (such as your preferred language), settings, and previous searches
                      are remembered. Functional cookies enhance your experience on any Tripello
                      site.
                    </li>
                    <li><strong>Analytics Cookies.</strong> We use analytics cookies to:<br />Understand our site’s performance and how it is used, including the number of
                      visitors, how long you stay on the site, and which parts of the site you visit. We
                      can see details about how visitors interacted with the site, like the number of
                      clicks visitors made on a given page, their mouse movements and scrolling
                      activity, the search words visitors used, and the text visitors enter into various
                      fields</li>
                    <li>Test different designs and features for our site</li>
                    <li>Monitor how our visitors reach our sites</li>
                    <li>Determine effectiveness of our advertising</li>
                    <li>Improve our services, including your experience on our site</li>
                  </ul>
                  <p>For analytics, our third-party service providers may use cookies to perform their
                    services and may combine the information that they collect about you on our
                    sites with other information that they have collected. For example, we use
                    Google analytics cookies to monitor the performance of our sites. Our ability to
                    use and share information collected by Google Analytics about your visit to our
                    sites is restricted by the Google Analytics Terms of Use and the Google Privacy
                    Policy. This Cookie Statement does not cover such third-parties’ use of your
                    information.</p>
                  <p>Some analytics cookies can perform tasks essential and/or functional to online
                    services like enabling site improvements and testing changes on a site.</p>
                  <h3>How can you manage your cookies?</h3>
                  <p>You can set or amend your web browser controls to accept or refuse cookies
                    whenever you like, but please remember if you do choose to reject cookies, your
                    access to some of the functionality and areas of our site may be restricted.</p>
                  <h3>Do-Not-Track Signals and Similar Mechanisms.</h3>
                  <p>Some web browsers may transmit &quot;do-not-track&quot; signals to sites with which the
                    browser communicates. Because of differences in how web browsers
                    incorporate and activate this feature, it is not always clear whether users intend
                    for these signals to be transmitted, or whether users are even aware of them.
                    Participants in the leading Internet standards-setting organization that is
                    addressing this issue are in the process of determining what, if anything, sites
                    should do when they receive such signals. We currently do not take action in
                    response to these signals. If and when a final standard is established and
                    accepted, we will reassess our sites’ responses to these signals and make
                    appropriate updates to this Cookie Policy.</p>
                  <h3>Contact Us</h3>
                  <p>If you would like to update your information, modify your communication
                    preferences, or if you do not want to receive marketing communications from
                    Tripello, Inc. in the future, you can contact us at <a href="https://membersupport@tripello.com"> membersupport@tripello.com</a>
                    or by mail at:</p>
                  <p>
                    Tripello, Inc.<br />
                    Attn: Privacy Notice Manager<br />
                    PO Box 27740<br />
                    Las Vegas, NV 89126-7740<br />
                    United States
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default CookiesPolicy;

import { serialize } from "../Util/util";
import http from "./http";

export const supportPageService = {
    getIssueListByCategory,
    sendIssueToSupportTeamService,
    getAutoCompletedData

}
async function sendIssueToSupportTeamService(payload) {
  // console.log("::::",payload);
    const qs = serialize(payload);
    const res = await http.post(`${process.env.REACT_APP_BASE_URL_USER}/api/v2/support/create-ticket`,payload,
        { 
            params: { use_multi_form_data: true },
        }
    )
    return res;
}

async function getIssueListByCategory(payload) { 
    const res = await http
      .get(`${process.env.REACT_APP_BASE_URL_USER}/api/v2/support/get-list?type=${payload.type}`);
    return res;
  }
  
  async function getAutoCompletedData(payload) { 
    const res = await http
      .get(`${process.env.REACT_APP_BASE_URL_USER}/api/v2/support/autocomplete?type=${payload.type}&term=${payload.term}`);
    return res;
  }
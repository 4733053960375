import React, { useRef, useState, useEffect } from "react";
import Header from "../../Component/Header";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";

import ourstory from "../../Assets/img/our-story/Our-Story.png";
import RenSvenWedding from "../../Assets/img/our-story/Ren-Sven-Wedding.png";
import Kitchen from "../../Assets/img/our-story/Kitchen.png";
import RenandSven from "../../Assets/img/our-story/Ren-and-Sven.png";
import RenandSven2 from "../../Assets/img/our-story/Ren-and-Sven-2.png";
import Signature from "../../Assets/img/our-story/Signature.png";

import { ReactComponent as OnboardingProcess } from "../../Assets/img/home/upgrade.svg";
function OurStory() {
  const scrollPage = useRef(null);
  const [isTouch, isTouchedDevice] = useState(false);
  // const backgroundVideo = useRef(null);
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [portraitViewModal, setportraitViewModal] = useState(
    window.outerWidth > 767 && window.outerWidth < 1025 ? true : false
  );
  //setting length after which sticky header and scrollToTop button show
  const handleScroll = () => {
    if (
      (scrollPage.current.scrollTop >= 4 && !showHeader) ||
      scrollPage.current.scrollTop >= 100
    ) {
      setShowHeader(true);
      setShowScrollTopBtn(true);
    }
    if (
      scrollPage.current.scrollTop < 4 ||
      scrollPage.current.scrollTop < 100
    ) {
      setShowHeader(false);
      setShowScrollTopBtn(false);
    }
  };

  //jumping to top of the page on button click
  const scrollElementToTop = () => {
    scrollPage.current.scrollTop = 0;
  };
  useEffect(() => {
    isTouchEnabled();
    // scrollPage.current.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", function (event) {
      if (window.outerWidth > 767 && window.outerWidth < 1025) {
        setportraitViewModal(true);
      } else {
        setportraitViewModal(false);
      }
    });

    document.getElementById("scrollableDiv").addEventListener("scroll", isSticky);
    return () => {
      document.getElementById("scrollableDiv")?.removeEventListener("scroll", isSticky);
      scrollPage?.current?.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", function (event) {
        if (window.outerWidth > 767 && window.outerWidth < 1025) {
          setportraitViewModal(true);
        } else {
          setportraitViewModal(false);
        }
      });
    };
  }, []);

  const isSticky = (e) => {
    const header = document.querySelector("#root");
    const scrollTop = e.target.scrollTop;
    scrollTop >= 25
      ? header.classList.add("header-sticky")
      : header.classList.remove("header-sticky");
  };
  const isTouchEnabled = (e) => {
    if(( 'ontouchstart' in window ) ||
           ( navigator.maxTouchPoints > 0 ) ||
           ( navigator.msMaxTouchPoints > 0 )){
            isTouchedDevice(true)
           }else {
            isTouchedDevice(false)
           }
  }


  return (
    <>
      <div
        className={
          !showHeader
            ? ` our-story-page`
            : " our-story-page header-sticky"
        }
      >
        <div className="our-story-page-wrap" ref={scrollPage}>
          <Header />
          <div className="container our-story-main" id="scrollableDiv">
            <div className="our-story-wrap">
              <div className="our-story-name text-center">
                <img src={ourstory} aboutlt="" />
              </div>
              <section className="card2">
                <h3>Tripello is first and foremost a love story.  It’s our love story.</h3>
                <p>We met in 2016.  The way that we met was one of those miraculous, magical, mystical events that defies logic but provides evidence of a larger, organizing universal structure.  The story of how we met could fill a book all on its own.</p>
                <p>It wasn’t a ‘sweep you off your feet’ romance; rather it was the beginning of the deepest, most authentic friendship that either of us had ever known, and neither believed existed.  The romance grew organically out of this deep connection with each other and has never stopped. </p>
                <p>We got married in October of 2020.  Our day-to-day life together is one of co-creative, effortless functioning and flow. We ‘get’ each other.  It’s the kind of relationship that encourages each of us to blossom in ways that we never imagined possible.   We talk more about this ‘co-creative flow’ and other fun stuff in the Zen of Tripello blog posts. </p>
              </section>
              <section className="section-full-image">
                <div className="container">
                  <img src={RenSvenWedding} alt="" />
                </div>
              </section>
              <section className="card2">
                <h3>Tripello wasn’t built in a boardroom, it was created in our kitchen.</h3>
                <p>In early 2021, the universe brought us an opportunity.  We always knew we would love to work on a business together and set our intention to that end.  Through connections and friendships, and the strange magic of life, the idea of creating a new, unique, travel booking experience was born.  Having travelled extensively in our lives and understanding how life-changing it can be, we fell in love with the idea that we could create an exceptional and affordable experience that would allow people to explore more of the world through travel.</p>
                <p>We each had about 30 years of experience in business; Sven in Information Technology and Marketing, and Ren in Accounting and Finance.  Leveraging our expertise in these areas was paramount in the creation of Tripello.  It was as if all the knowledge and experience that we had gained over our careers was needed, necessary and utilized in this project, as well as some skills we didn’t know we had.  As we like to say, when you authentically desire something, the universe will make you the tool that can achieve it.</p>
              </section>
              <section className="section-full-image">
                <div className="container">
                  <img src={Kitchen} alt="" />
                </div>
              </section>
              <section className="card2">
                <h3>A New Take on an Old Idea</h3>
                <p>We both had an extreme distaste for the standard online booking experience.  No one has really reimagined the experience of online travel booking in about 30 years.  The sites all look the same, they are full of advertising and pressure to book and most have those unwanted surprises in the form of ‘taxes and fees’ at checkout.  We set out to reimagine the experience.  We wanted the Tripello experience to be as relaxing as a vacation should be.   In short, we wanted Tripello to reflect the effortless functioning that we live every day. </p>
              </section>
              <section className="section-full-image">
                <div className="container">
                  <img src={RenandSven} alt="" />
                </div>
              </section>
              <section className="card2">
                <h3>Wholesale Prices for Everyone</h3>
                <p>We also understood that travel can be expensive and that is often the reason that people don’t do more of it.  The crazy idea was born to offer true wholesale/net travel prices with zero mark-up.  To offer people the best deals that we could find, so they pay what we pay.  The subscription model allows us to do this, as the savings that we offer can recoup the fee easily.   We think the world could use some no-
                  nonsense, honest, transparent, mutually beneficial business practices, and that’s what we’ve set out to create.</p>
              </section>
              <section className="card2">
                <h3>Free and Easy Access</h3>
                <p>We also wanted folks to be able to look around for free and see for themselves the incredible savings we can offer.  It is super important to us to be completely transparent with our pricing, what we offer and how it works.  All of this information is on the homepage.  No need to watch a long video or go through 57 clicks to get access.  The Tripello platform offers Wholesale Travel Prices for Everyone and it is free to join and look around.  Come see for yourself is our motto. </p>
              </section>
              <section className="section-full-image">
                <div className="container">
                  <img src={RenandSven2} alt="" />
                </div>
              </section>
              <section className="card2">
                <h3>The Adventures of Ren and Sven</h3>
                <p>The experience of creating Tripello together, has been our biggest adventure yet.  All our love of life, and of each other is embedded in every facet of Tripello.   It is our dream come true to offer the Tripello platform to the world and our fondest hope that people will seize the opportunity to explore more of the world, create amazing experiences for themselves and fall in love with the magic of life all over again.</p>
              </section>
              <section className="section-signature">
                <div className="text-center">
                  <h6>With Love,</h6>
                  <img src={Signature} alt="" />
                  <h4>“We are <strong>Here</strong> to get you <strong>There</strong>.”</h4>
                </div>
              </section>
            </div>
          </div>
          <FooterWithMediaIcon />
        {showScrollTopBtn ? (
          <div
            id="back-to-top"
            className="back-to-top"
            onClick={scrollElementToTop}
          >
            <button className="btn btn-primary" title="Back to Top">
              <svg
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 31.479 31.479"
                style={{ enableBackground: "new 0 0 31.479 31.479" }}
                width="18px"
                height="18px"
                className="svg lazyloading replaced-svg"
              >
                <path
                  style={{ fill: "#004185" }}
                  d="M26.477,10.274c0.444,0.444,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-8.047-8.047  v26.555c0,0.619-0.492,1.111-1.111,1.111c-0.619,0-1.127-0.492-1.127-1.111V3.813l-8.031,8.047c-0.444,0.429-1.159,0.429-1.587,0  c-0.444-0.444-0.444-1.143,0-1.587l9.952-9.952c0.429-0.429,1.143-0.429,1.571,0L26.477,10.274z"
                ></path>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>
          </div>
        ) : null}
        </div>
      </div>
    </>
  );
}

export default OurStory;

import React from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/img/logo.png";
// import Icomoon from "./FormComponent/Icomoon";
import IcomoonReact from "icomoon-react";
import iconSet from "../Assets/fonts/selection.json";

function PlanInfoTwo(props) {
  const {
    arrayList,
    btnName,
    planNote,
    planName,
    heading,
    para,
    months,
    link,
    imgPlus,
    freePlan,
    btnArrow,
  } = props;

  return (
    <div className="col">
      <div className={freePlan ? "cardStyle free-plan" : "cardStyle paidPlan"}>
        <div className="planImgBox">
          <div className="planImgInner">
            <div className="logo-image">
              <img src={logo} alt="" />
            </div>
            <div className="planName">{planName}</div>
            {months && <p>{months}</p>}
            {imgPlus && <div className="plus-img">{imgPlus}</div>}
            {}
          </div>
        </div>

        {/* planInfo list getting iterated */}

        <ul className="planDetails">
          {arrayList.map((item, index) => {
            return (
              <li key={index}>
                <IcomoonReact
                  className="icomoon-icon"
                  iconSet={iconSet}
                  color="#00b7a6"
                  size={14}
                  icon="tick"
                />
                {item}
              </li>
            );
          })}
        </ul>

        {/* planInfo list getting iterated */}

        <div className="planBtmBox">
          <p className="planNote">{planNote}</p>
          <div className="planRateBox">
            <div>
              <h4>{heading}</h4>
              <p>{para}</p>
            </div>
            <button to={link} className="btn">
              {btnName} {btnArrow}
            </button>
            {/* <Link to={link} className="btn">
              {btnName} {btnArrow}
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanInfoTwo;

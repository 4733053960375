import React, { useEffect, useRef, useState } from "react";
import CounterInfo from "./CounterInfo";
import Button from "./Button";
import { HiOutlineChevronDown } from "react-icons/hi";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import { cabinClassOptions } from "../../Util/util";
export default function Dropdown({
  setTravelingInfo,
  travelingInfo,
  isFromLisitng,
  onClickHandler
}) {
  const [isTablet, setIsTablet] = useState(false);
  const [toggleDropdown, setDropdown] = useState(false);
  const passangerRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setIsTablet(true);
    }
  }, []);

  function openDropdown() {
    setDropdown(!toggleDropdown);
  }
  useOutsideHandler(passangerRef, toggleDropdown, openDropdown);
  const handleClassChange = (type, cabinClass) => {
    setTravelingInfo({ ...travelingInfo, classType: type, cabinClass: cabinClass });
  };
  const { label: cabinClass } = cabinClassOptions.find(
    (ele) => ele.value === travelingInfo.classType
  );

  return (
    <>
      <div className="dropdown-wrap" ref={passangerRef}>
        <Button
          type="button"
          className="toggle-btn"
          onClick={() => {
            openDropdown();
          }}
        >
          {travelingInfo.adultCount +
            travelingInfo.childCount +
            travelingInfo.infantCount}{" "}
          {travelingInfo.adultCount + travelingInfo.childCount > 1
            ? "Travelers"
            : "Traveler"}
          , {cabinClass} <HiOutlineChevronDown className="dropwDownSVGarrow" />
        </Button>

        {toggleDropdown && (
          <div className="custom-dropdown travler-popup">
            <div className="travler-popup-wrap">
              <div className="dropdown-title">
                <div className="content">
                  <h3>
                    {!isTablet &&
                      travelingInfo.adultCount + travelingInfo.childCount + travelingInfo.infantCount}{" "}
                    {travelingInfo.adultCount + travelingInfo.childCount > 1
                      ? "Travelers"
                      : "Traveler"}
                    {!isTablet && `, ${cabinClass}`}
                  </h3>

                  {isTablet && isFromLisitng && (
                    <button className="popupCloseBtn" onClick={openDropdown}>
                      <span className="icon-close"></span>
                    </button>
                  )}
                </div>
              </div>
              <div className="dropdown-content">
                <CounterInfo
                  setTravelingInfo={setTravelingInfo}
                  travelingInfo={travelingInfo}
                />
                <div className="travel-class-wrap">
                  <div className="radio-title">
                    <div className="content">
                      <h3>Choose Travel Class</h3>
                    </div>
                  </div>
                  <div className="content">
                    <div className="radio-btn-group">
                      <label className="radio-btns" htmlFor="radio1">
                        <input
                          type="radio"
                          value={0}
                          name="travel-class"
                          checked={travelingInfo.classType === 0}
                          id="radio1"
                          onChange={() => handleClassChange(0, "Y")}
                        />
                        <span className="radio-circle"></span>Economy
                      </label>
                      <label className="radio-btns" htmlFor="radio3">
                        <input
                          type="radio"
                          value={1}
                          name="travel-class"
                          id="radio3"
                          checked={travelingInfo.classType === 1}
                          onChange={() => handleClassChange(1, "S")}
                        />
                        <span className="radio-circle"></span>Premium Economy
                      </label>
                      <label className="radio-btns" htmlFor="radio2">
                        <input
                          type="radio"
                          value={2}
                          name="travel-class"
                          id="radio2"
                          checked={travelingInfo.classType === 2}
                          onChange={() => handleClassChange(2, "C")}
                        />
                        <span className="radio-circle"></span>Business Class
                      </label>
                      <label className="radio-btns" htmlFor="radio4">
                        <input
                          type="radio"
                          value={4}
                          name="travel-class"
                          id="radio4"
                          checked={travelingInfo.classType === 4}
                          onChange={() => handleClassChange(4, "F")}
                        />
                        <span className="radio-circle"></span>First Class
                      </label>
                    </div>
                  </div>
                </div>
                <button type="button" className="btn" onClick={() => { openDropdown(); onClickHandler() }}>Apply</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

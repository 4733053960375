import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import AcademyTab from "../Tabs/AcademyTab";
import PdfIcon from "../../Assets/img/icons/pdf-icon.png";
import PPSXIcon from "../../Assets/img/icons/PowerPoint-Icon.png";

import checklistPdf1 from "../../Assets/pdf/Tripello-Network-Affiliate-Progam-V1-2-23.pdf";
import checklistPdf2 from "../../Assets/pdf/Tripello-Network-Affiliate-Presentation-2023-V1.pdf";
import checklistPdf3 from "../../Assets/pdf/Tripello-Network-Affiliate-Presentation-2023-V1.ppsx";
import checklistPdf4 from "../../Assets/pdf/Tripello-Network-Affiliate-Program-Summary.pdf";

function Education() {
  return (
    <div className="siteBg academy-wrapper FaqPageWrap Terms-wrapper">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true}/>
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <AcademyTab name={"education"} />
              <div className="col rightContent">

                <div className="faqWrapper card2">
                  <div className="pdf-content-wrapper">
                    <div className="row no-gutters">
                      <div className="col content-col">
                        <h3>Tripello Network Affiliate Program (PDF) </h3>
                        <p>Overview of the Tripello Network Affiliate Program in PDF format to study and share with other Affiliate Prospects.</p>
                      </div>
                      <div className="col image-col">
                        <div className="image-wrap">
                          <a href={checklistPdf1} target="_blank">
                            <span >DOWNLOAD</span>
                            <img src={PdfIcon} alt="icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="faqWrapper card2">
                  <div className="pdf-content-wrapper">
                    <div className="row no-gutters">
                      <div className="col content-col">
                        <h3>Tripello Network Affiliate Presentation (PDF) </h3>
                        <p>Overview of the Tripello Network Affiliate Program in detailed Powerpoint Presentation as PDF format. Perfect for One to One and large Group Presentations.</p>
                      </div>
                      <div className="col image-col">
                        <div className="image-wrap">
                          <a href={checklistPdf2} target="_blank">
                            <span >DOWNLOAD</span>
                            <img src={PdfIcon} alt="icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="faqWrapper card2">
                  <div className="pdf-content-wrapper">
                    <div className="row no-gutters">
                      <div className="col content-col">
                        <h3>Tripello Network Affiliate Presentation (PPSX) </h3>
                        <p>Overview of the Tripello Network Affiliate Program in detailed Powerpoint Presentation Slideshow format. Perfect for One to One and large Group Presentations.</p>
                      </div>
                      <div className="col image-col">
                        <div className="image-wrap">
                          <a  href={checklistPdf3} download>
                            <span >DOWNLOAD</span>
                            <img src={PPSXIcon} alt="icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="faqWrapper card2">
                  <div className="pdf-content-wrapper">
                    <div className="row no-gutters">
                      <div className="col content-col">
                        <h3>Tripello Network Affiliate Program Summary Sheet (PDF) </h3>
                        <p>Overview of the Tripello Network Affiliate Program in a short Summary Style, with the most important Points. Good as a Cheat Sheet</p>
                      </div>
                      <div className="col image-col">
                        <div className="image-wrap">
                          <a href={checklistPdf4} target="_blank">
                            <span >DOWNLOAD</span>
                            <img src={PdfIcon} alt="icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default Education;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import HeaderNavbar from "../../Component/HeaderNavbar";
import PrimaryContactInformation from "../../Component/PrimaryContactInformation";
import GuestInfo from "../../Component/GuestInfo";
import Loader from "../../Component/Loader/Loader";
import TermsCheckbox from "../../Component/TermsCheckbox";
import { HotelCheckoutValidation } from "../../Component/Validation/HotelCheckoutValidation";
import HomeCheckoutModal from "./HomeCheckoutModal";
import rightArrowImg from "../../Assets/img/right-arrow.png";
import reviewImg from "../../Assets/img/summary-icon.png";
import lockImage from "../../Assets/img/lock.svg";
import bgImg from "../../Assets/img/checkout-img.jpg";
import locationGray from "../../Assets/img/location-gray.png";
import datePicker from "../../Assets/img/date-picker.png";
import timerImg from "../../Assets/img/timer.png";
import infoGray from "../../Assets/img/info-gray.png";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import { ErrorModel } from "../../Assets/scss/components/ErrorModal/ErrorModal";
import Button from "../../Component/FormComponent/Button";
import { isEmpty } from "lodash";
import Scrollbars from "react-custom-scrollbars";
import InputField from "../../Component/FormComponent/InputField";
import { commafy } from "../../Util/util";
import {
  ageOptionChild,
  countryCode,
  optionsAge,
} from "../../Data/static.service";

import WalletDetailInfo from "../../Component/WalletDetailInfo";
import paypal from "../../Assets/img/checkout/paypal.png";
import applePay from "../../Assets/img/checkout/apple-pay.png";
import googlePay from "../../Assets/img/checkout/google-pay.png";
import { useDispatch, useSelector } from "react-redux";
import { homeActions } from "../../Stores/Actions";
import HotelDetailCard from "./HomeDetailCard";
import { StripeForm } from "./StripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PriceCheckModel from "../../Component/Modals/PriceCheckModal";
import infoICon from "../../Assets/img/info.png";
import IncreaseIcon from "../../Assets/img/increase-icon.svg";

function HomeCheckout() {
  const params = useParams();
  const formikRef = useRef();
  const childRef = useRef();
  const stripeFormRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [usedWalletAmmout, setUsedWalletAmmout] = useState(0);
  const [adultSelectError, setAdultSelectError] = useState(null);

  //settting state for wallet input
  const checkedBox = false;
  const [style, setStyle] = useState("none");
  const [activeTab, setActiveTab] = useState(1);
  const [userFromDrop, setUserFromDrop] = useState(null);
  const [bookHotelPayloadWithGuest, setBookHotelPayloadWithGuest] = useState(
    []
  );
  const dispatch = useDispatch();

  const currencyAndLanguage =
    (window.localStorage.getItem("currencyAndLanguage") &&
      JSON.parse(window.localStorage.getItem("currencyAndLanguage"))) ||
    {};

  const currencySymbol =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency?.symbol || "$";

  const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const hotelDetails = JSON.parse(localStorage.getItem("homeDataForCheckout"));

  const stripeIntentData = useSelector(
    (state) => state.hotelReducer.stripeIntentData
  );
  const stripeIntentSuccess = useSelector(
    (state) => state.hotelReducer.stripeIntentSuccess
  );

  const bookHotelData = useSelector(
    (state) => state.hotelReducer.bookHotelData
  );
  const bookingDataError = useSelector(
    (state) => state.hotelReducer.bookingDataError
  );
  const sessionExpireError = useSelector(
    (state) => state.hotelReducer.sessionExpireError
  );
  const confirmPaymentError = useSelector(
    (state) => state.hotelReducer.confirmPaymentError
  );
  const stripeIntentError = useSelector(
    (state) => state.hotelReducer.stripeIntentError
  );
  const loading = useSelector((state) => state.commonReducer.loading);
  const getGuestData = useSelector((state) => state.commonReducer.getGuestData);
  const guestListOptions = useSelector(
    (state) => state.commonReducer.guestListOptions
  );
  const availableBalance = useSelector(
    (state) => state.hotelReducer.availableBalance
  );
  const navigate = useNavigate();

  const currencyCode = hotelDetails?.data?.currency?.code
    ? hotelDetails?.data?.currency?.code
    : "USD";

  useEffect(() => {
    if (!userFromDrop) return;
    const result = getGuestData?.filter((curr) => {
      return curr.email == userFromDrop;
    });
    formikRef?.current?.setFieldValue("title", result[0]?.title);
    formikRef?.current?.setFieldValue("firstName", result[0]?.fname);
    formikRef?.current?.setFieldValue("lastName", result[0]?.lname);
    formikRef?.current?.setFieldValue("email", result[0]?.email);
    formikRef?.current?.setFieldValue("nationality", result[0]?.country);
    formikRef?.current?.setFieldValue("phoneNumber", result[0]?.phone);
  }, [userFromDrop]);

  const hotels = !isEmpty(hotelDetails) ? hotelDetails?.data?.hotels[0] : [];
  const input = !isEmpty(hotelDetails) ? hotelDetails?.data?.input : [];

  const filterRoomId = hotelDetails?.data?.rates[0] || [];
  //changing state onClick
  const handleCheckbox = (formikProps, field, value) => {
    setStyle(!style);
    formikProps.setFieldValue(field, value);
  };

  //open and close modal on Click
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleErrorModal = (status) => setOpenErrorModal(status);

  var imageBackground = {
    backgroundImage: `url(${bgImg})`,
  };
  const user = JSON.parse(window.localStorage.getItem("user"));
  // form intialValues
  const initialValues = {
    user: { id: user.email, label: `${user?.first_name} ${user?.last_name}` },
    title: user?.title || "",
    title2: "",
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
    countryCode: "",
    phoneNumber: user?.phone || "",
    nationality: "",
    addressOne: user?.user_address?.address_line_1 || "",
    addressTwo: user?.user_address?.address_line_2 || "",
    city: user?.user_address?.city || "",
    state: "",
    zipCode: user?.user_address?.zip || "",
    promoCode: "",
    age: "",
    use_wallet: false,
    walletAmount: "",
    amountAdded: "",
    remainingAmount: "",
    terms: false,
    newLeadGuests: [],
    //card
    cardHolderName: "",
    security_code: "",
    exp_month: "",
    exp_year: "",
    billing_zip: "",
    credit_number: "",
    billing_country: "",
    mmyy: "",
  };

  const optionsTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Neutral", label: "Neutral." },
  ];

  const countryCodeOption = countryCode.map((ele, index) => {
    return { value: ele.id, label: ele.label };
  });

  const numberCustomChange = (formikProps, field, value) => {
    if (
      field === "phoneNumber" ||
      field === "credit_number" ||
      field === "security_code"
    ) {
      const temp = Number(value);
      if (temp || value === "") {
        formikProps.setFieldValue(field, value);
      }
    }
  };

  useEffect(() => {
    // for handling errors
    if (sessionExpireError || stripeIntentError || bookingDataError) {
      handleErrorModal(true); // open error modal
    }
    if (confirmPaymentError) {
      formikRef.current.setTouched({
        ...formikRef.current.touched,
        cardNumber: true,
      });
      return formikRef.current.setErrors({
        cardNumber: "Invalid Card Number",
      });
    }
  }, [
    sessionExpireError,
    stripeIntentError,
    bookingDataError,
    confirmPaymentError,
  ]);

  useEffect(() => {
    if (stripeIntentData?.stripe_payment_intent_id && stripeIntentSuccess) {
      // if stripe intent api successfully called
      const stripeData = stripeFormRef.current
        .confirmStripePayment()
        .then((res) => {
          // if(res?.error) return
          dispatch(homeActions.bookHotelAction(bookHotelPayloadWithGuest)); // call book hotel api
        });
    }
  }, [stripeIntentData]);

  useEffect(() => {
    if (bookHotelData?.status === 200) {
      navigate(`/home-confirmation/${bookHotelData.data.id}?isConfirm=true`); // if booking successful then redirect to hotel comfirmation page.
    }
  }, [bookHotelData]);

  //  check available blance 
  useEffect(()=>{
    dispatch(homeActions.getAvailableBalance());
  },[])



  //handleCustomChange function for selectfieldoption input
  const handleCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value);
    if (field == "user") {
      setUserFromDrop(value.id);
    }
  };

  const [stripeError, setStripeError] = useState("");

  const handleSubmit = () => {
    //handleSubmit furnciton
    const cardElementContainer = document.querySelector(".cardWrapper");
    let isCardComplete = cardElementContainer?.classList?.contains(
      "StripeElement--complete"
    );
    if (isCardComplete) {
      setStripeError("");
    } else {
      setStripeError("Required");
      return;
    }

    let adultformError = null;
    let childformError = null;

    const bookHotelPayload = {
      contact_person: {
        salutation: formikRef?.current?.values?.firstName,
        first_name: formikRef?.current?.values?.firstName,
        last_name: formikRef?.current?.values?.lastName,
        email: formikRef?.current?.values?.email,
        state: formikRef?.current?.values?.state,
        city: formikRef?.current?.values?.city,
        contact_no: formikRef?.current?.values?.phoneNumber,
        country: formikRef?.current?.values?.nationality,
        nationality: formikRef?.current?.values?.nationality,
        postal_code: formikRef?.current?.values?.zipCode,
        street: formikRef?.current?.values?.addressOne,
        street2: formikRef?.current?.values?.addressTwo,
      },
      wallets: [
        {
          type_id: JSON.parse(window.localStorage.getItem("user"))?.user_wallet
            .wallet_type_id,
          value: Number(usedWalletAmmout) || 0,
        },
      ],
      use_wallets: formikRef?.current?.values?.use_wallet == false ? 0 : 1,
      hotel_id: params?.hotelID,
      currency: currencyAndLanguage?.currency?.isoCode || "USD",
    };

    const roomsData = childRef.current.getRoomsGuests();
    const FinalFilteredGuests = JSON.parse(JSON.stringify(roomsData)).filter(
      (curr, idx) => {
        //remove touched and errors property from the child array
        curr.room_id = hotelDetails?.data?.selected_room_ids[idx];

        const ress = curr.adults.filter((ele) => {
          if (ele.is_lead_guest) {
            ele.age = Number(ele.age);
            delete ele.touched;
            if (
              ele.age < 18 ||
              ele.age == null ||
              !ele.first_name.length ||
              !ele.last_name.length ||
              !ele.salutation.length
            ) {
              adultformError = "Please fill all the required fields";
            }
            return ele;
          }
        });

        curr.adults = ress;

        // check if any guest is empty, if emty then disebled the submit button
        const getLead = curr.adults.filter((val) => val.is_lead_guest === true);
        if (!getLead.length) {
          adultformError = "Please select atleast one adult" + idx;
          setAdultSelectError({
            msg: "Please select atleast one adult",
            index: idx,
          });
        }

        //remove touched  and errors property from the child array

        curr.child.map((ele) => {
          delete ele.touched;
          delete ele.errors;

          if (
            !String(ele.age).length ||
            ele.age < 0 ||
            !ele.first_name.length ||
            !ele.last_name.length ||
            !ele.salutation.length
          ) {
            childformError = "Please fill all the required fields";
          }
          ele.age = Number(ele.age);
        });

        return curr;
      }
    );
    bookHotelPayload.guests = FinalFilteredGuests; // merge guest data with book hotel payload

    setBookHotelPayloadWithGuest(bookHotelPayload);

    const checkHotelAvabilityPayload = {
      room_ids: [params.roomId],
      currency: currencyAndLanguage?.currency?.isoCode || "USD",
      locale: currencyAndLanguage?.language?.langCode || "en-US",
    };

    if (!childformError && !adultformError) {
      dispatch(homeActions.homePrebook(bookHotelPayload)).then((res) => {
        if (res?.status === 200) {
          dispatch(
            homeActions.recheckHotelPriceAction(
              checkHotelAvabilityPayload,
              params.hotelID,
              formikRef?.current?.values?.amountAdded &&
                formikRef?.current?.values?.use_wallet
                ? formikRef?.current?.values?.amountAdded
                : false
            )
          ); // check hotel avability  //recheck price (payload,hotelID,added wallet amount)
        }
      });
    }
  };

  const localUser = JSON.parse(window.localStorage.getItem("user"));

  const RewardPlaceHolder = () => {
    const availBalance =
      (hotelDetails?.data?.wallets?.available_balance || 0) *
      (hotelDetails?.data?.currency?.exchange_rate || 1);
    if (filterRoomId?.price?.final_price < availBalance) {
      return filterRoomId?.price?.final_price
        ? commafy(filterRoomId?.price?.final_price)
        : 0;
    } else {
      return availBalance ? commafy(availBalance) : 0;
    }
  };

  const renderForm = (formikProps) => {
    const { values, handleSubmit, handleBlur, touched, handleChange, errors } =
      formikProps;
    return (
      <form onSubmit={handleSubmit}>
        {((localUser?.type_id ==
          process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID &&
          localUser?.user_wallet?.available_balance > 0) ||
          (localUser?.type_id ==
            process.env.REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID &&
            localUser?.user_wallet?.available_balance > 0)) && (
          <WalletDetailInfo
            formikProps={formikProps}
            handleCustomChange={handleCustomChange}
            //amountAddeds
            setUsedWalletAmmout={setUsedWalletAmmout}
            available_balance={
              (hotelDetails?.data?.wallets?.available_balance || 0) *
              (hotelDetails?.data?.currency?.exchange_rate || 1)
            }
            roomPrice={Number(filterRoomId?.price?.final_price)}
            amountAdded="amountAdded"
            amountAddedLabel="Wallet Amount Added To Checkout"
            amountAddedPlaceholder={`Only upto ${currencySymbol} ${RewardPlaceHolder()}`}
            remainingAmount="remainingAmount"
            remainingAmountLabel="Remaining Amount Due"
            checkedBoxValue={checkedBox}
            handleCheckbox={handleCheckbox}
            styleValue={style}
            currencyCode={currencyCode}
            currencySymbol={currencySymbol}
          />
        )}

        <PrimaryContactInformation
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          numberCustomChange={numberCustomChange}
          //User
          user="user"
          userLabel="User"
          userOptions={guestListOptions}
          userPlaceholder="User"
          hideUserSelect={
            process.env.REACT_APP_FREMIUM_USER_TYPE_ID == localUser.type_id
          }
          //Title
          title="title"
          titleLabel="Title"
          titleOptions={optionsTitle}
          titlePlaceholder="Title"
          // First Name
          firstName="firstName"
          firstNameLabel="First Name"
          firstNamePlaceholder="Walter"
          //Last Name
          lastName="lastName"
          lastNameLabel="Last Name"
          lastNamePlaceholder="White"
          //Email
          email="email"
          emailLabel="Email"
          emailPlaceholder="Email"
          //Phone Number
          countryCode="countryCode"
          countryCodeLabel="Code"
          countryCodePlaceholder="Code"
          phoneNumber="phoneNumber"
          phoneNumberLabel="Phone Number"
          countryCodeOption={countryCodeOption}
          //Nationality
          nationality="nationality"
          nationalityLabel="Nationality"
          nationalityPlaceholder="Select Country"
          //Addressone
          addressOne="addressOne"
          addressOneLabel="Address Line 1"
          addressOnePlaceholder="Address Line 1"
          //AddressTwo
          addressTwo="addressTwo"
          addressTwoLabel="Address Line 2"
          addressTwoPlaceholder="Address Line 2"
          //city
          city="city"
          cityLabel="City"
          cityPlaceholder="City"
          //state
          state="state"
          stateLabel="State/Province/Region"
          statePlaceholder="Select State"
          //zipcode
          zipCode="zipCode"
          zipCodeLabel="ZIP/Postal Code"
          zipCodePlaceholder="ZIP/Postal"
          // promo
        />
        <GuestInfo
          ref={childRef}
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          handleSubmit={handleSubmit}
          setAdultSelectError={setAdultSelectError}
          adultSelectError={adultSelectError}
          //title2
          title2="title2"
          title2Label="Title"
          title2Options={optionsTitle}
          //firstName2
          firstName2="firstName2"
          firstName2Placeholder="Walter"
          firstName2Label="First Name"
          //newLeadGuest
          guestFirstNamePlaceholder="First Name"
          guestFirstNameLabel="First Name"
          guestMiddleNamePlaceholder="Middle Name"
          guestMiddleNameLabel="Middle Name (if applicable)"
          guestLastNamePlaceholder="Last Name"
          guestLastNameLabel="Last Name"
          //lastName2
          lastName2="lastName2"
          lastName2Label="Last Name"
          lastName2Placeholder="White"
          //Age
          age="age"
          ageOptions={optionsAge}
          ageLabel="Age"
          newLeadGuests={"newLeadGuests"}
          input={input}
          ageOptionChild={ageOptionChild}
          // cbfuction={cbfuction}
          room_id={params.roomId}
          room_no={params.roomNo}
        />
        <div className="card2 billingInfo GlobalPaymentIntegrationWrap">
          <div className="billingDetailsToggle">
            <ul>
              <li onClick={() => setActiveTab(1)}>
                <a
                  className={activeTab == 1 ? "active tablink" : "tablink"}
                  style={{ cursor: "pointer" }}
                >
                  Pay Using Debit / Credit Cards{" "}
                </a>
              </li>
              <li onClick={() => setActiveTab(2)}>
                <a
                  className={activeTab == 2 ? "active tablink" : "tablink"}
                  style={{ cursor: "pointer" }}
                >
                  Pay Using Other Options
                </a>
              </li>
            </ul>
          </div>

          <div
            id="cardPaymentOption"
            className={`cardPaymentOption tabContent ${
              activeTab === 1 ? "activeTab" : ""
            }`}
          >
            <div className="wallet-options-same-height">
              <div className="sectionTitle walletwrapper">
                <h2>Billing Details</h2>
              </div>
              <div className="row">
                <InputField
                  name={"cardHolderName"}
                  type="text"
                  label={"Card Holder Name"}
                  placeholder={"Card Holder Name"}
                  value={values.cardHolderName}
                  error={errors.cardHolderName}
                  cardError={errors.cardHolderName}
                  touched={touched.cardHolderName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="cardHolder"
                />

                <Elements stripe={stripe}>
                  <StripeForm
                    ref={stripeFormRef}
                    clientSecret={stripeIntentData?.stripe_client_secret}
                    setStripeError={setStripeError}
                    stripeError={stripeError}
                  />
                </Elements>
              </div>
            </div>

            <div className="d-flex checkBoxWrap">
              <TermsCheckbox
                name="terms"
                id="terms"
                value={values.terms}
                onChange={(e) => {
                  handleCustomChange(formikProps, "terms", e.target.checked);
                }}
                error={errors.terms}
                touched={touched.terms}
                onBlur={handleBlur}
              />
              <div className="buttonWrap">
                <div
                  className="desktopViewDisplayNone"
                  style={{ marginTop: "10px" }}
                >
                  <h4>
                    Amount Due Today:{" "}
                    <strong>
                      {" "}
                      &nbsp;$
                      {commafy(
                        filterRoomId?.price?.final_price - usedWalletAmmout || 0
                      )}
                    </strong>
                  </h4>
                </div>
                <Button
                  onClick={() => {
                    childRef.current.getRoomsGuests();
                  }}
                  className="btn"
                  type="submit"
                  id="sbumit-button"
                  disabled={availableBalance < parseFloat(filterRoomId?.price?.public_price)}
                >
                  <img src={lockImage} /> Pay Now
                </Button>
              </div>
            </div>
          </div>

          <div
            id="walletPaymentOption"
            className={`tabContent WalletPaymentOption  ${
              activeTab === 2 ? "activeTab" : ""
            }`}
          >
            <div className="walletOptionWrap">
              <div className="walletWrapper wallet-options-same-height">
                <div className="walletWrapperIn">
                  <ul>
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="paypal-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="paypal-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={paypal} alt="wallet icon" />
                            </div>
                            <h6>PayPal</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="apple-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="apple-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={applePay} alt="wallet icon" />
                            </div>
                            <h6>Apple Pay</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="Google-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="Google-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={googlePay} alt="wallet icon" />
                            </div>
                            <h6>Google Pay</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex checkBoxWrap">
                <TermsCheckbox
                  name="terms"
                  id="terms"
                  value={values.terms}
                  onChange={(e) => {
                    handleCustomChange(formikProps, "terms", e.target.checked);
                  }}
                  error={errors.terms}
                  touched={touched.terms}
                  onBlur={handleBlur}
                />

                <div className="buttonWrap">
                  <div
                    className="desktopViewDisplayNone"
                    style={{ marginTop: "10px" }}
                  >
                    <h4>
                      Amount Due Today:{" "}
                      <strong>
                        {" "}
                        &nbsp;$
                        {commafy(
                          filterRoomId?.price?.final_price - usedWalletAmmout ||
                            0
                        )}
                      </strong>
                    </h4>
                  </div>
                  <Button
                    onClick={() => childRef.current.getRoomsGuests()}
                    className="btn"
                    type="submit"
                    id="sbumit-button"
                    disabled={availableBalance < parseFloat(filterRoomId?.price?.public_price)}
                  >
                    <img src={lockImage} /> Pay Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };
  const [openPriceUpdateModal, setOpenPriceUpdateModaL] = useState(
    hotelDetails?.data?.price_update_type === "up" ||
      hotelDetails?.data?.price_update_type === "down"
  );
  return (
    <>
      {hotelDetails?.data?.price_update_type && (
        <PriceCheckModel
          openModal={openPriceUpdateModal}
          closeModal={() => {
            setOpenPriceUpdateModaL(false);
          }}
          text={
            hotelDetails?.data?.price_update_type === "up" ? (
              <>
                <h2>
                  Price For This Home Has Increased By $
                  {hotelDetails?.data?.price_difference}
                </h2>
                <h3>
                  Your selection may have changed. Please review cancellation
                  policies & other inclusions before booking at the revised
                  price.
                </h3>{" "}
              </>
            ) : (
              <>
                <h2>
                  Price For This Hotel Has Decreased By $
                  {hotelDetails?.data?.price_difference}{" "}
                </h2>
                <h3>
                  Your selection may have changed. Please review cancellation
                  policies & other inclusions before booking at the revised
                  price.{" "}
                </h3>{" "}
              </>
            )
          }
          icon={IncreaseIcon}
          type={hotelDetails?.data?.price_update_type}
          routeFlag="home"
        />
      )}

      {loading && <Loader />}
      <ErrorModel
        openErrorModal={openErrorModal}
        handleErrorModal={handleErrorModal}
        message={sessionExpireError || stripeIntentError || bookingDataError}
        sessionExpireError={sessionExpireError}
        stripeIntentError={stripeIntentError}
        type="hotel"
      />
      <div
        className={
          !openModal
            ? "siteBg listingPage checkoutPageWrap hotel-checkout"
            : "siteBg listingPage checkoutPageWrap hotel-checkout popupOpned"
        }
      >
        <div className="pageWrap pageWrapInner">
          <div className="fixedHeader">
            <HeaderNavbar useBlueIcon={true} />
          </div>
          <section className="mainContent">
            <Scrollbars
              autoHide
              autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="desktop-hide mobile-popup-bar">
                <div
                  className="booking-details-title"
                  onClick={handleOpenModal}
                >
                  <h4>Review Booking Details</h4>
                  <div className="info-icon">
                    <img src={reviewImg} alt="icon" />
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="checkoutWrapper">
                  <div className="row">
                    <div className="col leftContent">
                      <Formik
                        onSubmit={handleSubmit}
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={HotelCheckoutValidation}
                        validateOnBlur={false}
                        enableReinitialize={true}
                      >
                        {renderForm}
                      </Formik>
                    </div>
                    {currencyCode && (
                      <HotelDetailCard
                        hotels={hotels}
                        rates={filterRoomId}
                        input={input}
                        usedWalletAmmout={usedWalletAmmout}
                        currencyCode={currencyCode}
                        isRefundable={
                          hotelDetails?.data?.rates?.[0]?.package?.is_refundable
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </Scrollbars>
          </section>
          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />
        </div>

        {openModal && (
          <HomeCheckoutModal
            modalOpen={openModal}
            modalClose={handleCloseModal}
            image={imageBackground}
            locationGray={locationGray}
            datePicker={datePicker}
            rightArrowImg={rightArrowImg}
            timerImg={timerImg}
            infoGray={infoGray}
            hotels={hotels}
            rates={filterRoomId}
            input={input}
            usedWalletAmmout={usedWalletAmmout}
            currencyCode={currencyCode}
            isRefundable={
              hotelDetails?.data?.rates?.[0]?.package?.is_refundable
            }
          />
        )}
      </div>
    </>
  );
}

export default HomeCheckout;

export const commonConstants = {
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SET_CURRENCY_AND_LANGUAGE: "SET_CURRENCY_AND_LANGUAGE",
  GET_CURRENCY_AND_LANGUAGE: "GET_CURRENCY_AND_LANGUAGE",
  SHOW_LOADER_TEXT: "SHOW_LOADER_TEXT",

  GET_GUEST_LIST_REQUEST: "GET_GUEST_LIST_REQUEST",
  GET_GUEST_LIST_SUCCESS: "GET_GUEST_LIST_SUCCESS",
  GET_GUEST_LIST_FAILURE: "GET_GUEST_LIST_FAILURE",

  IS_MOBILE_DEVICE : "IS_MOBILE_DEVICE",
};


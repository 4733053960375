import React from "react";
import Counter from "./Counter";
import Button from "./Button";

const CounterInfo = ({ setTravelingInfo, travelingInfo }) => {
  const CounterNumber = [
    {
      title: "Adults",
      subTitle: `Ages 13 and above`,
      type: "adultCount",
      initialValue: travelingInfo.adultCount,
    },
    {
      title: "Children",
      subTitle: `Ages 2-12 `,
      type: "childCount",
      initialValue: travelingInfo.childCount,
    },
    {
      title: "Infants",
      subTitle: `Ages 0-2 `,
      type: "infantCount",
      initialValue: travelingInfo.infantCount,
    },
  ];

  const handlePassenger = (count, type) => {
    setTravelingInfo({ ...travelingInfo, [type]: count });
  };

  return (
    <div className="buttons-group-content">
      {CounterNumber.map((item, index) => {
        const { title, subTitle } = item; 
        return (
          <div className="content" key={index}>
            <div className="dropdown-row-wrap">
              <div className="row">
                <div className="col">
                  <h5>{title}</h5>
                  <h6>{subTitle}</h6>
                </div>
                <div className="col">
                  <Counter
                    updateRoom={handlePassenger}
                    type={item.type}
                    initialCount={item.initialValue}
                    CounterNumber={CounterNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CounterInfo;

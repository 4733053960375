import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Component/FormComponent/Button";
import SearchAutocomplete from "../../Component/Autocomplete/SearchAutocomplete";
import DateRangePickerComponent from "../../Component/DatePicker/DateRangePickerComponent";
import AddRoomForm from "../../Component/Forms/AddRoomForm";
import { commonActions } from "../../Stores/Actions/commonActions";
import Loader from "../../Component/Loader/Loader";
import { HotelSearchValidation } from "../../Component/Validation/HotelSearchValidation";

export default function HotelTab({ isSearchView }) {
  const nagivate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef(null)
  const hotelSearchAutoCompleteRes = useSelector(
    (state) => state.commonReducer.autoCompleteData
  );


  // console.log("hotelSearchAutoCompleteRes",hotelSearchAutoCompleteRes);
  const loading = useSelector((state) => state.commonReducer.loader);

  const isMobile = useSelector((state) => state.commonReducer.isMobile);


  const searchLoading = useSelector(
    (state) => state.commonReducer.searchLoading
  );
  const [showDropdown, setshowDropdown] = useState(false);
  const [showAutocomplete, setshowAutocomplete] = useState(false);
  const [isResponsive, setResponsive] = useState(false);
  const [focused2, setFocused2] = useState(null);
  const [selectedValue, setselectedValue] = useState(null);
  const [isDateResponsive, setDateResponsive] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const roomObject = { adults: 2, child: 0, children: [] };
  const [isLoading, setIsLoading] = useState(false);

  const currencyAndLanguage =
    window.localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(window.localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  var hotelWidget = JSON.parse(window.localStorage.getItem("hotelWidget"));
  const initialValues = {
    check_in: hotelWidget
      ? moment(hotelWidget.check_in)
      : moment().add(1, "days"),
    check_out: hotelWidget
      ? moment(hotelWidget.check_out)
      : moment().add(4, "days"),
    rooms: [{ ...roomObject }],
    goingTo: "",
  };

  useEffect(() => {
    window.localStorage.removeItem("hotelFilter");
    window.localStorage.removeItem("hotelPayload");
    window.localStorage.removeItem("mapPayload");
    window.localStorage.removeItem("flightFilter");
    window.localStorage.removeItem("hotel_search_key");
  }, []);

  const handleOnSearch = (query) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    dispatch(commonActions.hotelSearchAutoComplete(query));
  };

  const handleOnSelect = (value, field, formikProps) => {
    // the item selected
    formikProps.setFieldValue(field, value);
    setselectedValue(value[0]?.secondary_label);
    setSelectedId(value[0]?.id);
    setIsLoading(false);

    if (value[0]) {
      window.localStorage.setItem("autoCompleteData", JSON.stringify(value[0]))
      handleAutocompleteClick(false);
    } else {
      // formikProps.setFieldValue(field, [JSON.parse(window.localStorage.getItem("autoCompleteData"))]); 
      setselectedValue(JSON.parse(window.localStorage.getItem("autoCompleteData"))?.secondary_label);
    }
  };
  const handleAutocompleteClick = () => {
    setshowAutocomplete(!showAutocomplete);
  };
  const handleDateClick = () => {
    setDateResponsive(!isDateResponsive);
  };
  const setDropdown = () => {
    setshowDropdown(!showDropdown);
  };
  const handleDateChange = (value, formikProps, field) => {
    formikProps.setFieldValue(field, value);
    if (field === "check_out" && value) setDateResponsive(false);
  };
  const showResponsive = () => {
    setResponsive(true);
    handleAutocompleteClick(false);
  };
  const showDateResponsive = (clickedInput) => {
    setDateResponsive(clickedInput);
  };
  const handleSubmit = (data) => {


    const payload = {
      view_type: "list",
      rooms: data.rooms.map((ele) => {
        return {
          adults: ele.adults,
          child: ele.child,
          children: ele.children.map((ele) => ele.value),
        };
      }),
      check_in_date: moment(data.check_in).format("YYYY-MM-DD"),
      check_out_date: moment(data.check_out).format("YYYY-MM-DD"),
      city_name: selectedValue,
      currency: currency.isoCode,
      home: false,
      locale: "en-US",
      native_currency_symbol: currency.symbol,
      onlyLocale: "en-US",
      optional: "amenities",
      region_id: selectedId,
      search_type: "hotel",
      source_market: "US",
      token: window.localStorage.getItem("accessToken"),
      use_wallets: 1,
      filters: {},
      paging: {
        page_number: 1,
        per_page: 15,
      },
      nationality: "US",
      sort: {
        field: "savings_percentage",
        order: "desc",
      },
    };

    // Remove neighbourhoodsSelection
    localStorage.removeItem("neighbourhoodsSelection");
    window.localStorage.setItem("hotelPayload", JSON.stringify(payload));
    navigateTo();
  };

  function navigateTo() {
    return nagivate("/hotel-search-result");
  }

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;

    const adultChildrenCount = values.rooms
      .map((ele) => ele.adults)
      .reduce((a, b) => a + b);

    const childCount = values.rooms
      .map((ele) => ele.child)
      .reduce((a, b) => a + b);

    return (
      <form onSubmit={handleSubmit}>
        <div className="tabContent activeTab" id="apartmentsTab">
          {!isSearchView &&
            <div className="landing-info-data-wrap">
              <div className="landing-info-data">
                <h3>
                  <span>Directly book from over 1.5 million Hotels and Resorts Worldwide, and steadily growing.</span>
                  <span>These are the classic ones with Rooms and Suites that you love and like.</span>
                  <span>From well-renowned Brands to your cozy Boutique Hotels.</span>
                </h3>
              </div>
            </div>
          }
          {isSearchView &&
            <>
              <div className="dflex flexStart mobileFullWidth">
                <div className="btnGroup">
                  <div
                    className={
                      !showDropdown
                        ? "dropdownWrap dropDownMobilePopup toggleClass"
                        : "dropdownWrap dropDownMobilePopup toggleClass openDropdown"
                    }
                  >
                    {/* selecting room  for desktop view */}
                    {!isMobile && (
                      <Button
                        className="btnSml toggleBtn"
                        type="button"
                        onClick={setDropdown}
                        text={`${values?.rooms?.length} Room${values?.rooms?.length > 1 ? "s" : ""
                          }, ${adultChildrenCount + childCount}  ${childCount ? "Guest" : "Adult"
                          }${adultChildrenCount > 1 ? "s" : ""}`}
                        icon="fa fa-angle-down"
                      />
                    )}

                    {/* selecting room for mobile view */}
                    {isMobile && (
                      <div>
                        <Button
                          className="btnSml toggleBtn"
                          type="button"
                          onClick={setDropdown}
                          text={`${values?.rooms?.length} Room${values?.rooms?.length > 1 ? "s" : ""
                            }, ${adultChildrenCount + childCount}  ${childCount ? "Guest" : "Adult"
                            }${adultChildrenCount > 1 ? "s" : ""}`}
                          icon="fa fa-angle-down"
                        />
                      </div>
                    )}
                    {/* Mobile view of adding room */}
                    <div className="dropdownInWrap mobileFullWidthPopup">
                      <div className="mobileFullWidthPopupContent">
                        <div className="mobileShow popupHeader">
                          <h4>Travelers and Rooms</h4>
                          <Link className="popupCloseBtn toggleBtn" to="#">
                            <span className="icon-close" onClick={setDropdown} />
                          </Link>
                        </div>
                        {/* Add room component */}
                        {showDropdown && (
                          <AddRoomForm
                            isOpen={showDropdown}
                            inputFields={values.rooms}
                            roomObject={roomObject}
                            formikProps={formikProps}
                            setOpen={setshowDropdown}
                          />
                        )}
                      </div>
                      <div className="dropdownBtnWrap mobileShow">
                        <Button
                          className="btn"
                          text="Apply"
                          type="button"
                          onClick={setDropdown}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* autocomplete component integration */}
              <div className="filterSecondRow input-for-mobile">
                {!isMobile && (
                  <div
                    className={
                      !showAutocomplete
                        ? "inputStyle showDropdown"
                        : "inputStyle showDropdown"
                    }
                  >
                    <div className="dropdown-content">
                      <span />
                      <SearchAutocomplete
                        {...formikProps}
                        label=""
                        options={hotelSearchAutoCompleteRes}
                        name="goingTo"
                        onSearch={(query) => handleOnSearch(query)}
                        onChange={(selected) => {
                          handleOnSelect(selected, "goingTo", formikProps);
                        }}
                        value={values.goingTo}
                        onBlur={handleBlur}
                        isLoading={searchLoading}
                        onClick={handleAutocompleteClick}
                        placeholder="Going to"
                        icon="icon-location-on"
                        errors={errors}
                        drop={false}
                      />
                    </div>
                  </div>
                )}
                {isMobile && (
                  <div className="responsive-input">
                    <button
                      className="inputStyle grow1"
                      onClick={showResponsive}
                      type="button"
                    >
                      {" "}
                      <i className="icon-location-on" />{" "}
                      {selectedValue ? selectedValue : "Going to"}
                    </button>
                    {errors?.goingTo && <p className="error-messages">{errors?.goingTo}</p>}
                  </div>
                )}

                {isResponsive && showAutocomplete && (        // show autocomplete popup on mobile view
                  <div className="inputStyle showDropdown">
                    <div className="mobilePopup">
                      <Link to="#" className="closePopup">
                        <span
                          className="icon-close"
                          onClick={() => handleAutocompleteClick(true)}
                        />
                      </Link>
                      <h4 className="inputPopTitle">Going to</h4>
                      <div className="dropdown-content">
                        <span />
                        <SearchAutocomplete
                          {...formikProps}
                          label=""
                          options={hotelSearchAutoCompleteRes}
                          name="goingTo"
                          onSearch={(query) => handleOnSearch(query)}
                          onChange={(selected) => {
                            handleOnSelect(selected, "goingTo", formikProps);
                          }}
                          onBlur={handleBlur}
                          isLoading={searchLoading}
                          value={values.goingTo}
                          // onClick={handleAutocompleteClick}
                          placeholder="Going to"
                          icon="icon-location-on"
                          autoFocus={true}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Date range picker component(desktop)  // main */}
                {!isMobile && (
                  <div className="Date-picker-range">
                    <div className="custom-date-range">
                      <p>
                        {`${moment(values.check_in).format("ddd, MMM DD")} `}
                        <i className='icon-arrow'></i>
                        {values.check_out ?
                          ` ${moment(values.check_out).format("ddd, MMM DD")} [ ${moment(values.check_out).diff(moment(values.check_in), 'days')} night stay ]` : "Invalid Date"} </p>
                    </div>
                    <DateRangePickerComponent
                      name="dates"
                      startDate={values.check_in}
                      endDate={values.check_out}
                      startDateId="check_in"
                      endDateId="check_out"
                      focused2={focused2}
                      setFocused2={setFocused2}
                      isRequired
                      icon="icon-invitation"
                      orientation="horizontal"
                      openDirection="up"
                      startDatePlaceholderText="Check-in"
                      endDatePlaceholderText="Check-out"
                      onDatesChange={({ startDate, endDate }) => {
                        handleDateChange(startDate, formikProps, "check_in");
                        if (moment(endDate).diff(moment(startDate), 'days') > 20) {
                          handleDateChange(null, formikProps, "check_out");
                        } else {
                          handleDateChange(endDate, formikProps, "check_out");
                        }
                      }}

                      isOutsideRange={(day) => {
                        if (focused2 === "startDate") return day.isBefore(moment().subtract(0, "days"));
                        if (focused2 === "endDate") {
                          return (
                            day.isBefore(moment(values?.check_in).subtract(0, "days")) || day.isAfter(moment(values?.check_in).add(20, "days"))
                          );
                        }
                      }}
                    />
                  </div>
                )}

                {/* button for responsice check in-out fields */}
                {isMobile && (
                  <div className="date-picker-type">
                    <div className="check-in-date date-picker-style">
                      <i className="icon-invitation" />
                      <Button
                        className="inputStyle grow1"
                        onClick={() => {
                          setFocused2("startDate")
                        }}
                        type="button"
                        text={
                          values.check_in
                            ? moment(values.check_in).format("DD/MM/YYYY")
                            : "Check-in"
                        }
                      />
                    </div>
                    <div className="check-out-date date-picker-style">
                      <i className="icon-invitation" />
                      <Button
                        className="inputStyle grow1"
                        onClick={() => {
                          if (focused2 === null) {
                            setFocused2("startDate")
                          } else {
                            setFocused2("endDate")
                          }
                        }}
                        type="button"
                        text={
                          values.check_out
                            ? moment(values.check_out).format("DD/MM/YYYY")
                            : "Check-Out"
                        }
                      />
                    </div>
                    <div className="custom-date-range">
                      <p>
                        {`${moment(values.check_in).format("ddd, MMM DD")} `}
                        <i className='icon-arrow'></i>
                        {values.check_out ?
                          ` ${moment(values.check_out).format("ddd, MMM DD")} [ ${moment(values.check_out).diff(moment(values.check_in), 'days')} night stay ]` : "Invalid Date"} </p>
                    </div>
                  </div>
                )}

                {isMobile && focused2 && (
                  <div className="inputStyle showDropdown">

                    <div className="date-picker-popup ">
                      <Link to="#" className="closePopup">
                        <span
                          className="icon-close"
                          onClick={() => handleDateClick(false)}
                        />
                      </Link>
                      <h4 className="inputPopTitle">Going to</h4>
                      <div className="Date-picker-range">
                        <DateRangePickerComponent
                          name="dates"
                          startDate={values.check_in}
                          endDate={values.check_out}
                          startDateId="check_in"
                          endDateId="check_out"
                          focused2={focused2}
                          setFocused2={setFocused2}
                          isRequired
                          icon="icon-invitation"
                          orientation="verticalScrollable"
                          numberOfMonths={12}
                          openDirection="down"
                          startDatePlaceholderText="Check-in"
                          endDatePlaceholderText="Check-out"
                          onDatesChange={({ startDate, endDate }) => {
                            handleDateChange(startDate, formikProps, "check_in");
                            if (moment(endDate).diff(moment(startDate), 'days') > 20) {
                              handleDateChange(null, formikProps, "check_out");
                            } else {
                              handleDateChange(endDate, formikProps, "check_out");
                            }
                          }}
                          isOutsideRange={(day) => {
                            if (focused2 === "startDate") return day.isBefore(moment().subtract(0, "days"));
                            if (focused2 === "endDate") {
                              return (
                                day.isBefore(moment(values?.check_in).subtract(0, "days")) || day.isAfter(moment(values?.check_in).add(20, "days"))
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* {isMobile && isDateResponsive && (
              <div className="date-picker-popup ">
                <Link to="#" className="closePopup">
                  <span
                    className="icon-close"
                    onClick={() => handleDateClick(false)}
                  ></span>
                </Link>
              </div>
            )} */}
                <div className=" searchBtnWrap">
                  <Button type="submit" className="btn d-block" text="Search" />
                </div>
              </div>
            </>
          }
        </div>
        {/* </div> */}
      </form>
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={HotelSearchValidation()}
      >
        {renderForm}
      </Formik>
      {loading && <Loader />}
    </>
  );
}

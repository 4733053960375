import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import { Scrollbars } from "react-custom-scrollbars";
import HeaderNavbar from "../../Component/HeaderNavbar";
import moment from "moment";
import Header from "../../Component/Header";
import PrimaryContactInformation from "../../Component/PrimaryContactInformation";
import LoginInput from "../../Component/LoginInput";
import EmailInput from "../../Component/EmailInput";
import BillingDetails from "../../Component/BillingDetails";
import creaditCardImage from "../../Assets/img/credit-card.png";
import TermsCheckbox from "../../Component/TermsCheckbox";
import AffiliateTermsCheckbox from "../../Component/AffiliateTermsCheckbox";
import lockImage from "../../Assets/img/lock.svg";
import logo from "../../Assets/img/logo.png";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import { AffiliateMembershipValidation } from "../../Component/Validation/AffiliateMembershipValidation";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../Stores/Actions/authActions";
import { affiliateActions } from "../../Stores/Actions/affiliateActions";
import paypal from "../../Assets/img/checkout/paypal.png";
import applePay from "../../Assets/img/checkout/apple-pay.png";
import googlePay from "../../Assets/img/checkout/google-pay.png";
import wechatPay from "../../Assets/img/checkout/wechat-pay.png";
import bitcoin from "../../Assets/img/checkout/bitcoin.png";
import Icon from "../../Component/FormComponent/Icon";
import { getLocationParams } from "../../Util/util";
import SSNNumberModal from "./SSNNumberModal/SSNNumberModal";

function AffiliatePremiumMembership() {
  //features open and hide in planInfo
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationSearch = getLocationParams();

  const [featureDropdown, setFeatureDropdown] = useState(false);
  const [activeTab, setActiveTab] = useState(1); // billing details active tab

  const [isPromoAppliedMsg, setPromoAppliedMsg] = useState(null);
  const [isCouponAppliedMsg, setCouponAppliedMsg] = useState(null);
  const [finalCheckoutPrice, setFinalCheckoutPrice] = useState("499.00");


  const isUserExist = useSelector((state) => state?.authReducer?.isUserExist);
  const user_id1 = useSelector((state) => state.authReducer.user_id);

  const affiliatePremiumSignupData = useSelector(
    (state) => state.affiliateReducer?.affiliatePremiumSignupData
  );

  const getAffiliateUserData = useSelector(
    (state) => state.affiliateReducer?.getAffiliateUserData
  );

  const base_currency = useSelector(
    (state) => state.currencyLanguageReducer?.data?.currency?.isoCode
  );

  const [user_id, setUser_id] = useState(null);

  //function for handling dropdown feature
  const handleDropdown = () => {
    setFeatureDropdown(!featureDropdown);
  };

  //Initial Vlaues for input fields
  const intialValues = {
    title: "",
    firstName: locationSearch?.first_name || "",
    lastName: locationSearch?.last_name || "",
    email: locationSearch?.email || "",
    phoneNumber: "",
    nationality: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zipCode: "",
    password: "",
    promoCode: "",
    hasPromo: false,
    confirmPassword: "",
    paypalEmail: "",
    confirmPaypalEmail: "",
    memberId: "",
    terms: false,
    affiliateTerms: false,
    //cards
    cardHolderName: "",
    credit_number: "",
    security_code: "",
    mmyy: "",
    // ssnModal
    ssnNumber: "",
    confirmSsnNumber: "",
    einNumber: "",
    confirmEinNumber: "",
  };

  useEffect(() => {
    if (user_id1) {
      setUser_id(user_id1);
      dispatch(authActions.resetUserData());
    }
  }, [user_id1]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (locationSearch?.ref && !user_id) {
      dispatch(authActions.getUserByAffiliateId(locationSearch.ref));
    }
  }, []);

  //Title options list
  const optionsTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Neutral.", label: "Neutral." },
  ];

  //handle numberCustomChange function
  const numberCustomChange = (formikProps, field, value) => {
    if (
      field === "phoneNumber" ||
      field === "credit_number" ||
      field === "mmyy" ||
      field === "security_code"
    ) {
      if (Number(value) || value === "") {
        formikProps.setFieldValue(field, value);
      }
    }
  };

  useEffect(() => {
    if (getAffiliateUserData?.status == 200) {
      formikRef.current.setValues({
        ...formikRef.current.values,
        title: getAffiliateUserData?.data?.title || "",
        firstName: getAffiliateUserData?.data?.first_name || "",
        lastName: getAffiliateUserData?.data?.last_name || "",
        email: getAffiliateUserData?.data?.email || "",
        phoneNumber: getAffiliateUserData?.data?.phone || "",
        nationality: getAffiliateUserData?.data?.country || "",
        ssnNumber: getAffiliateUserData?.data?.ssn || "",
        confirmSsnNumber: getAffiliateUserData?.data?.ssn || "",
        einNumber: getAffiliateUserData?.data?.ein || "",
        confirmEinNumber: getAffiliateUserData?.data?.ein || "",
        addressOne:
          getAffiliateUserData?.data?.user_address?.address_line_1 || "",
        addressTwo:
          getAffiliateUserData?.data?.user_address?.address_line_2 || "",
        city: getAffiliateUserData?.data?.user_address?.city || "",
        state: getAffiliateUserData?.data?.user_address?.state || "",
        zipCode: getAffiliateUserData?.data?.user_address?.zip || "",
      });
      const { data } = getAffiliateUserData;
      if (data) {
        if (data.country === "US" && !data.ssn && !data.ein) {
          setShowSSNModal(true);
        }
      }
    }
  }, [getAffiliateUserData]);

  const [showSSNModal, setShowSSNModal] = useState(false);

  //handleCustomChagne funciton
  const handleCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value);
    if (field === "nationality") {
      if (value === "US") {
        setShowSSNModal(true);
      }
    }
  };

  const closeHandler = (formikProps, cancel) => {
    if (cancel) {
      formikProps.setFieldValue("nationality", "");
      setShowSSNModal(false);
    } else {
      formikProps.setFieldTouched("ssnNumber", true);
      formikProps.setFieldTouched("confirmSsnNumber", true);
      formikProps.setFieldTouched("einNumber", true);
      formikProps.setFieldTouched("confirmEinNumber", true);
      const { errors } = formikProps;
      if (
        errors.hasOwnProperty("ssnNumber") ||
        errors.hasOwnProperty("confirmSsnNumber") ||
        errors.hasOwnProperty("einNumber") ||
        errors.hasOwnProperty("confirmEinNumber")
      ) {
        return;
      } else {
        setShowSSNModal(false);
      }
    }
  };

  const AutoFill = (memberId) => {
    dispatch(affiliateActions.getUserByMemberIdAction(memberId.trim()));
  };

  //handleSubmit furnciton
  const handleSubmit = (values, formikProps) => {

    if (values?.hasPromo && !isPromoAppliedMsg) {
      formikRef.current.setErrors({
        ...formikRef.current.errors,
        promoCode: "Invalid Promo Code"
      });
      formikRef.current.setTouched({ promoCode: true });
      return
    }


    const payload = {
      title: values.title,
      first_name: values.firstName,
      last_name: values.lastName,
      login_name: values.email,
      email: values.email,
      password: values.password,
      phone: values.phoneNumber,
      country: values.nationality,
      address: values.addressOne,
      showCouponCode: values.hasPromo,
      base_currency: base_currency || "USD",
      status: "1",
      accessible_modules: "WALLET,CARDS,USERS",
      user_type_id: process.env.REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID || "",
      tenant_id: process.env.REACT_APP_TENANT_ID,
      timezone: "Australia/Melbourne",
      //card
      billing_address: `${values.addressOne} ${values.addressTwo}`,
      billing_city: values.city,
      billing_country: values.nationality,
      billing_state: values.state,
      billing_zip: values.zipCode,
      sponsor_id: user_id || process.env.REACT_APP_USER_ID,
    };
    if (!values.hasPromo) {
      payload.security_code = values.security_code,
        payload.credit_number = values.credit_number || "",
        payload.exp_month = values.mmyy.split("/")[0],
        payload.exp_year = moment().format("YYYY").slice(0, 2) + values.mmyy.split("/")[1]
    }
    if (values.nationality === "US") {
      if (values.ssnNumber) {
        payload.ssn = values.ssnNumber;
      } else if (values.einNumber) {
        payload.ein = values.einNumber;
      }
    }

    if (values.hasCoupon) {
      payload.coupon_code = values.couponCode;
    }
    if (isPromoAppliedMsg && values.hasPromo) {
      payload.promotional_code = values.promoCode;
    }

    dispatch(authActions.isEmailExistRequest(values.email)).then((response) => {
      if (response.status == 200) {
        formikRef?.current?.setErrors({
          email:
            "You are already a member, please login or use a different email",
        });
        return;
      } else if (response?.response?.status == 422) {
        dispatch(affiliateActions.affiliateSignupRequest(payload)).then(
          (res) => {
            if (res.status == 200) navigate("/thankyou/paiduser");
          }
        );
      }
    });
  };

  //info List
  const infoList = [
    "Full Annual Tripello Premium Plus Membership",
    "Access to Tripello Affilate Program with Unique and Comprehensive Compensation Plan",
    "Access to Personal Management Dashboard",
    "Access to Personal Invitation Links and QR Codes for Member and Affiliate Invites",
    "Access to Marketing Center with Social Media optimized Collateral and Tips and Tricks for Campaigns",
    "Access to Personal Wallet for tracking of Earnings and Payouts",
  ];


  const ApplyPromo = (promoCode) => {
    const payload = {
      code: promoCode.trim(),
      user_type_id: process.env.REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID,
    };

    dispatch(authActions.validatePromocodeRequest(payload)).then((res) => {
      if (res?.data?.success) {
        setPromoAppliedMsg(
          res?.data?.message
        );
        setFinalCheckoutPrice("0.00");
      } else {
        setFinalCheckoutPrice("499.00");
        setPromoAppliedMsg(null);

        formikRef?.current?.setFieldError(
          "promoCode", `${promoCode ? "Invalid Promo Code" : "Promo code can not be empty"}`
        )

        formikRef.current.setFieldTouched("promoCode", true);
      }
    });
  };

  //form being rendered here
  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleChange, handleBlur } = formikProps;
    const ApplyCoupon = (CouponCode) => {
      const payload = {
        coupon_code: CouponCode.trim(),
        user_type_id: process.env.REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID,
        tenant_id: process.env.REACT_APP_TENANT_ID,
      };

      dispatch(authActions.validateCouponCodeRequest(payload)).then((res) => {
        if (res?.data?.success) {
          setCouponAppliedMsg(
            res?.data?.message
          );
          setFinalCheckoutPrice(res?.data?.package_price);
          formikRef.current.setErrors({
            ...formikRef.current.errors,
            couponCode: "",
          });
          formikRef.current.setTouched({ couponCode: true });

        } else {
          setFinalCheckoutPrice("499.00");
          setCouponAppliedMsg(null);
          formikRef.current.setErrors({
            ...formikRef.current.errors,
            couponCode: CouponCode
              ? "Invalid Coupon Code"
              : "Coupon code can not be empty",
          });
          formikRef.current.setTouched({ couponCode: true });
        }
      });
    };
    const handleCheckboxChange = (formikProps, field, value) => {

      if (field === "hasPromo") {
        formikProps.setFieldValue("hasCoupon", false);
        formikProps.setFieldValue(field, value);
      } else {
        formikProps.setFieldValue("hasPromo", false);
        formikProps.setFieldValue(field, value);
      }
      setFinalCheckoutPrice("499.00");
      formikProps.setFieldValue("couponCode", "");
      formikProps.setFieldValue("promoCode", "");
      formikProps.setErrors({
        ...formikProps.errors,
        couponCode: null,
        promoCode: null,
      });
      setPromoAppliedMsg(null);
      setCouponAppliedMsg(null);
      formikProps.setTouched({ couponCode: false });
      formikProps.setTouched({ promoCode: false });

    }
    return (
      <form onSubmit={handleSubmit}>
        {/* primary contact information */}

        {showSSNModal && (
          <SSNNumberModal
            closeHandler={closeHandler}
            formikProps={formikProps}
          />
        )}

        <PrimaryContactInformation
          AutoFill={AutoFill}
          memberIdPlaceholder={"Your Tripello Member Number"}
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          numberCustomChange={numberCustomChange}
          //Title
          title="title"
          titleLabel="Title"
          titleOptions={optionsTitle}
          titlePlaceholder="Title"
          // First Name
          firstName="firstName"
          firstNameLabel="First Name"
          firstNamePlaceholder="Walter"
          //Last Name
          lastName="lastName"
          lastNameLabel="Last Name"
          lastNamePlaceholder="White"
          //Email
          email="email"
          emailLabel="Email"
          emailPlaceholder="Email"
          countryCodeLabel="Country Code"
          //Phone Number
          phoneNumber="phoneNumber"
          phoneNumberLabel="Phone Number"
          //Nationality
          nationality="nationality"
          nationalityLabel="Nationality"
          nationalityPlaceholder="Select Country"
          //Addressone
          addressOne="addressOne"
          addressOneLabel="Address Line 1"
          addressOnePlaceholder="Address Line 1"
          //AddressTwo
          addressTwo="addressTwo"
          addressTwoLabel="Address Line 2"
          addressTwoPlaceholder="Address Line 2"
          //city
          city="city"
          cityLabel="City"
          cityPlaceholder="City"

          hasPromo={values?.hasPromo}
          showPromo={true}
          //state
          state="state"
          stateLabel="State/Province/Region"
          statePlaceholder="Select State"
          //zipcode
          zipCode="zipCode"
          zipCodeLabel="ZIP/Postal Code"
          zipCodePlaceholder="ZIP/Postal Code"
          // promoCode
          promoCode="promoCode"
          promoCodeLabel="Promo / Giftcard Code"
          promoCodePlaceholder="Enter Code"
          ApplyPromo={ApplyPromo}
          isPromoAppliedMsg={isPromoAppliedMsg}
          onChange={handleChange}
          // couponCode
          hasCoupon={values.hasCoupon}
          couponCode="couponCode"
          couponCodeLabel="Coupon Code"
          couponCodePlaceholder="Enter Code"
          ApplyCoupon={ApplyCoupon}
          handleCheckboxChange={handleCheckboxChange}
          isCouponAppliedMsg={isCouponAppliedMsg}
          showCoupon={true}
        />
        {/* login input field  */}
        <LoginInput
          formikProps={formikProps}
          //password
          password="password"
          passwordPlaceholder="Password"
          label="Password"
          //Confirm Password
          confirmPassword="confirmPassword"
          confirmPasswordPlaceholder="Confirm Password"
          eyeIcon={true}
        />
        {/* email input field */}

        <EmailInput
          formikProps={formikProps}
          //Email
          paypalEmail="paypalEmail"
          paypalEmailLabel="Email Address"
          paypalEmailPlaceholder="Email"
          //Confirm Email
          confirmPaypalEmail="confirmPaypalEmail"
          confirmPaypalEmailPlaceholder="Confirm Email"
          confirmPaypalEmaillLabel="Confirm Email Address"
        />

        {/* Billing Details */}

        <div className="card2 billingInfo GlobalPaymentIntegrationWrap">
          {!values.hasPromo && <div className="billingDetailsToggle">
            <ul>
              <li onClick={() => setActiveTab(1)}>
                <a className={activeTab == 1 && "active"}>
                  Pay Using Debit / Credit Cards
                </a>
              </li>
              <li onClick={() => setActiveTab(2)}>
                <a className={activeTab == 2 && "active"}>
                  Pay Using Other Options
                </a>
              </li>
            </ul>
          </div>}

          <div
            id="cardPaymentOption"
            className={`cardPaymentOption tabContent ${activeTab === 1 ? "activeTab" : ""
              }`}
          >
            {!values.hasPromo && <BillingDetails
              formikProps={formikProps}
              numberCustomChange={numberCustomChange}
              billingDetails={
                <div className="sectionTitle">
                  <h2>Billing Details</h2>
                </div>
              }
              // Card Holder Name
              cardHolderName="cardHolderName"
              cardHolderNameLabel="Card Holder Name"
              cardHolderNamePlaceholder="Card Holder Name"
              // Card Number
              cardHeadingLabel="Enter Card Details"
              credit_number="credit_number"
              cardImage={creaditCardImage}
              cardNumberPlaceholder="Card"
              // mmyy
              mmyy="mmyy"
              mmyyPlaceholder="MM/YY"
              // cvv
              security_code="security_code"
              cvvPlaceholder="CVV"
            />}

            <div className="d-flex checkBoxWrap">
              <div>
                <TermsCheckbox
                  name="terms"
                  id="terms"
                  value={values.terms}
                  onChange={(e) => {
                    handleCustomChange(formikProps, "terms", e.target.checked);
                  }}
                  error={errors.terms}
                  touched={touched.terms}
                  onBlur={handleBlur}
                />
                <AffiliateTermsCheckbox
                  name="affiliateTerms"
                  id="affiliateTerms"
                  value={values.affiliateTerms}
                  onChange={(e) => {
                    handleCustomChange(formikProps, "affiliateTerms", e.target.checked);
                  }}
                  error={errors.affiliateTerms}
                  touched={touched.affiliateTerms}
                  onBlur={handleBlur}
                />
              </div>
              <div className="buttonWrap">
                <div className="desktopViewDisplayNone">
                  <h4>
                    <strong>${finalCheckoutPrice}</strong>/ annually
                  </h4>
                  <h4>(Annual Auto Renewal)</h4>
                </div>
                <button className="btn" type="submit" id="sbumit-button">
                  <img src={lockImage} /> Pay Now
                </button>
              </div>
            </div>
          </div>

          <div
            id="walletPaymentOption"
            className={`tabContent WalletPaymentOption  ${activeTab === 2 ? "activeTab" : ""
              }`}
          >
            <div className="walletOptionWrap">
              {!values.hasPromo && <div className="walletWrapper wallet-options-same-height">
                <div>
                  <ul>
                    {/* <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="paypal-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="paypal-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={paypal} alt="wallet icon" />
                            </div>
                            <h6>PayPal</h6>
                          </div>
                        </label>
                      </div>
                    </li> */}
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="apple-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="apple-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={applePay} alt="wallet icon" />
                            </div>
                            <h6>Apple Pay</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="checkBoxContent">
                        <label
                          htmlFor="Google-id"
                          className="customCheckbox checkbox-label"
                        >
                          <input
                            type="radio"
                            id="Google-id"
                            name="WalletPayment"
                          />
                          <span></span>
                          <div className="wallet-icon-wrap">
                            <div className="wallet-icon">
                              <img src={googlePay} alt="wallet icon" />
                            </div>
                            <h6>Google Pay</h6>
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>}
              <div className="dflex checkBoxWrap">
                <div>
                  <TermsCheckbox
                    name="terms"
                    id="terms"
                    value={values.terms}
                    onChange={(e) => {
                      handleCustomChange(formikProps, "terms", e.target.checked);
                    }}
                    onBlur={handleBlur}
                    error={errors.terms}
                    touched={touched.terms}
                  />
                  <AffiliateTermsCheckbox
                    name="affiliateTerms"
                    id="affiliateTerms"
                    value={values.affiliateTerms}
                    onChange={(e) => {
                      handleCustomChange(formikProps, "affiliateTerms", e.target.checked);
                    }}
                    onBlur={handleBlur}
                    error={errors.affiliateTerms}
                    touched={touched.affiliateTerms}

                  />
                </div>
                <div className="buttonWrap">
                  <div className="desktopViewDisplayNone">
                    <h4>
                      Amount Due Today: <strong>&nbsp;$195.00</strong>
                    </h4>
                  </div>

                  <button type="submit" className="walletPayNow btn d-block">
                    <img src={lockImage} />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  return (
    <div className="siteBg checkoutPageWrap affiliateUpgradeCheckoutPageWrap networkPageWrap MembershipCheckoutPageWrap affiliateCheckoutPageWrap">
      <div className="pageWrap pageWrapInner">
        <div className="fixedHeader">
          {/* <HeaderNavbar /> */}
          <Header headerName={true} />
        </div>
        <section className="mainContent">
          <Scrollbars
            // autoHeight={`calc(100vh - 110px)`}
            autoHeight={true}
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container pageInternalScrollTopPadding">
              <div className="checkoutWrapper">
                <div className="row">
                  <div className="col leftContent">
                    <Formik
                      innerRef={formikRef}
                      onSubmit={handleSubmit}
                      initialValues={intialValues}
                      validationSchema={AffiliateMembershipValidation}
                      validateOnBlur={false}
                    >
                      {renderForm}
                    </Formik>
                  </div>
                  <div className="col rightContent">
                    <div className="infoBox">
                      <div className="membership-content">
                        <div className="logo-image">
                          <img src={logo} alt="" />
                        </div>
                        <div className="planName">Affiliate Membership</div>
                        <p>(12 Month)</p>
                      </div>
                    </div>
                    <div
                      className={
                        !featureDropdown ? "features" : "features open"
                      }
                    >
                      {/* Plan Info list */}
                      <ul>
                        {infoList.map((list) => {
                          return <li key={list}>{list}</li>;
                        })}
                      </ul>
                      <div className="priceInfo">
                        <h6>
                          <strong>${finalCheckoutPrice}</strong>/ annually
                        </h6>
                        <h6>(Annual Auto Renewal)</h6>
                      </div>
                    </div>
                    <button
                      onClick={handleDropdown}
                      className={
                        !featureDropdown
                          ? "desktopViewDisplayNone toggleButtons"
                          : "desktopViewDisplayNone toggleButtons open"
                      }
                    >
                      <span className="show-features">Show Features</span>
                      <span className="hide-features">Hide Features</span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

export default AffiliatePremiumMembership;
